import type { AuthServiceInterface } from '@freelancer/auth/interface';
import type {
  AccountProgressCollection,
  AdminEnterprisesCollection,
  AdminIrisOffsitingCollection,
  AdminPoolsCollection,
  AdminProjectDescriptionUpdateCollection,
  AdminUserDocumentSubmissionsCollection,
  AdminUserEnterprisesCollection,
  AdminUserVerificationProcessesCollection,
  AgentSessionsCollection,
  AgentsCollection,
  AiPromptsCollection,
  AnalyticsCollection,
  ArticlesCollection,
  BannersCollection,
  BidBuyerProjectFeesCollection,
  BidPitchCollection,
  BidRestrictionsCollection,
  BidScoresCollection,
  BidSummariesCollection,
  BidUpgradeFeesCollection,
  BidsCollection,
  BillingAgreementsCollection,
  CampaignsCollection,
  CanViewEmployerProfileCollection,
  CartApprovalsCollection,
  CartItemsCollection,
  CartsCollection,
  CategoryPageContentCollection,
  CategoryPageViewUsersCollection,
  CertificationsCollection,
  CheckInScheduleCollection,
  CommentFeedsCollection,
  CommentsCollection,
  ContentCustomizationCollection,
  ContestBudgetRangesCollection,
  ContestCollaborationsCollection,
  ContestCommentsCollection,
  ContestEngagementCollection,
  ContestEntriesCollection,
  ContestEntryOffersCollection,
  ContestFeesCollection,
  ContestFilesCollection,
  ContestHandoverFilesCollection,
  ContestHandoversCollection,
  ContestInterestedUsersCollection,
  ContestPrizeDetailsCollection,
  ContestQuickviewEntriesCollection,
  ContestUserHasGivenFeedbackCollection,
  ContestViewContestsCollection,
  ContestViewEntriesCollection,
  ContestViewEntryIdsCollection,
  ContestsCollection,
  ContractDownloadUrlsCollection,
  ContractsCollection,
  CountriesCollection,
  CurrenciesCollection,
  CurrenciesIncludingExternalCollection,
  CurrencyDetectCollection,
  CustomBannersCollection,
  CustomFieldInfoConfigurationsCollection,
  CustomFieldRelationshipsCollection,
  CustomFieldValidationsCollection,
  DashboardPollsCollection,
  DashboardWidgetsCollection,
  DeliveryContactsSensitiveDetailsCollection,
  DepositPageVarsCollection,
  DiscoverCollectionItemSaveCountCollection,
  DiscoverCollectionItemsCollection,
  DiscoverCollectionsCollection,
  DomainsCollection,
  DriveFilePermissionsCollection,
  DriveFilesCollection,
  EducationsCollection,
  EmployerReputationsRehireRatesCollection,
  EncodedUrlCollection,
  EnterpriseBillingAgreementsBalanceCollection,
  EnterprisesCollection,
  EntryRestrictionsCollection,
  EquipmentGroupCollection,
  ExamsCollection,
  ExchangeRatesCollection,
  ExperiencesCollection,
  ExternalReferenceCollection,
  FeedCollection,
  FeedMetaCollection,
  FeedPostsCollection,
  FlareActionMapCollection,
  FlareCheckpointsCollection,
  FlareRulesCollection,
  FlareRulesetsCollection,
  FreelancerReputationsCollection,
  FreelancerReputationsRehireRatesCollection,
  GrantsCollection,
  GroupActionsCollection,
  GroupAttachmentsCollection,
  GroupFeedCollection,
  GroupMembersCollection,
  GroupPermissionsCollection,
  GroupsCollection,
  GroupsSelfCollection,
  GuidelinesCollection,
  HirePageDetailsCollection,
  HourlyContractsCollection,
  HourlyProjectMilestoneFeesCollection,
  InvitationsCollection,
  InvoicesCollection,
  IpContractsCollection,
  IsFreeBidsCollection,
  IsLookingForWorkCollection,
  LanguagesCollection,
  LoadPriceEstimateCollection,
  LoadshiftSiteStatsCollection,
  LoginDevicesCollection,
  ManageViewContestHandoversCollection,
  ManageViewContestsCollection,
  ManageViewOngoingBidsCollection,
  ManageViewOngoingProjectsCollection,
  ManageViewOpenBidsCollection,
  ManageViewOpenProjectsCollection,
  ManageViewPastBidsCollection,
  ManageViewPastProjectsCollection,
  MembershipBenefitsCollection,
  MembershipDowngradesCollection,
  MembershipPackagesCollection,
  MembershipRenewalsCollection,
  MembershipSubscriptionHistoryCollection,
  MembershipTrialsCollection,
  MessagesCollection,
  MilestoneDraftsCollection,
  MilestoneRequestsCollection,
  MilestonesCollection,
  NativeExchangeRatesCollection,
  NearbyProjectsCollection,
  NewsfeedCollection,
  NotificationSettingsCollection,
  NotificationsCollection,
  NotificationsPreferencesCollection,
  OnBehalfProjectsCollection,
  OnlineOfflineCollection,
  OperatingAreasCollection,
  PaymentShareInterestsCollection,
  PaymentShareMembersCollection,
  PaymentShareMembersSpentAmountCollection,
  PaymentShareTeamsCollection,
  PaymentShareWhitelistItemsCollection,
  PayoutAccountFormCollection,
  PayoutAccountsCollection,
  PayoutContextCollection,
  PayoutLimitExemptionsCollection,
  PayoutMethodsCollection,
  PoolsCollection,
  PortfoliosCollection,
  PostFilesCollection,
  PostJobPageFormStateCollection,
  PredefinedDiscoverCollectionsCollection,
  PredictedSkillsCollection,
  PreferredBillingAgreementsCollection,
  PreferredSupportAgentCollection,
  ProfileCategoriesCollection,
  ProfileViewUsersCollection,
  ProfilesCollection,
  ProjectBidInfoCollection,
  ProjectBookmarksCollection,
  ProjectBudgetOptionsCollection,
  ProjectCollaborationsCollection,
  ProjectFeedCollection,
  ProjectGroupsCollection,
  ProjectQualityDetailsCollection,
  ProjectSpamControlsCollection,
  ProjectTitleEditRequestCollection,
  ProjectUpgradeFeesCollection,
  ProjectViewBidsCollection,
  ProjectViewProjectsCollection,
  ProjectViewUsersCollection,
  ProjectsCollection,
  ProjectsSeoCollection,
  PublicationsCollection,
  QuotationItemRevisionsCollection,
  QuotationLogosCollection,
  QuotationRevisionsCollection,
  ReactionsTypeCountCollection,
  RecentSavedSearchAlertsCollection,
  RecommendedMembershipCollection,
  RecommendedUsernamesCollection,
  RecurringBillingInformationCollection,
  ReferralInvitationCheckCollection,
  ReferralInvitationsCollection,
  ReferralTokenCollection,
  ReviewsCollection,
  ReviewsSkillsCollection,
  SalesTaxCollection,
  ScheduledSubscriptionPaymentsCollection,
  SearchActiveContestsCollection,
  SearchActiveLoadsCollection,
  SearchActiveProjectsCollection,
  SearchProjectsSelfCollection,
  SearchSavedFiltersCollection,
  SearchUsersCollection,
  SecurityPhoneRequestsCollection,
  ServiceOfferingBuyerHistoriesCollection,
  ServiceOfferingShopCategoriesCollection,
  ServiceOfferingShopCategoryServicesCollection,
  ServiceOfferingShopsCollection,
  ServiceOfferingsCollection,
  ServiceOfferingsWithOwnerDetailsCollection,
  SessionResolveReasonsCollection,
  ShowcaseCollection,
  ShowcaseSourceCollection,
  SimilarFreelancersCollection,
  SimilarShowcasesCollection,
  SiteStatsCollection,
  SkillsCollection,
  SkillsSubNavigationCollection,
  SlaTasksCollection,
  SubscriptionItemsCollection,
  SuggestedSkillsCollection,
  SuperuserBidsCollection,
  SuperuserGroupMembersCollection,
  SuperuserGroupPermissionsCollection,
  SuperuserGroupsCollection,
  SuperuserMilestonesCollection,
  SuperuserProjectNotesCollection,
  SuperuserProjectViewAllProjectsCollection,
  SuperuserProjectViewProjectsCollection,
  SuperuserProjectViewUsersCollection,
  SupportAnswersCollection,
  SupportFeedbackIsAllowedCollection,
  SupportTagsCollection,
  TaskGroupCollection,
  TaskGroupMembersCollection,
  TaskGroupSectionCollection,
  TasksCollection,
  TaxCollection,
  TaxOptOutOptionsCollection,
  TaxStatementCollection,
  TeamMembersCollection,
  TeamsCollection,
  ThreadProjectsCollection,
  ThreadsCollection,
  TimeTrackingPunchesCollection,
  TimeTrackingSessionCollection,
  TimelinesCollection,
  TimezonesCollection,
  TopSkillsCollection,
  UniversitiesCollection,
  UsStatesCollection,
  User,
  UserAvailabilitiesCollection,
  UserBalancesCollection,
  UserBidLimitCollection,
  UserBidOnRussianProjectsCollection,
  UserCalifornianStatusesCollection,
  UserCartRequiresEmailVerificationCollection,
  UserContestCollaborationWhitelistCollection,
  UserDepositMethodsCollection,
  UserDocumentSubmissionsCollection,
  UserEmailRequestCollection,
  UserGiveGetDetailsCollection,
  UserHasDuplicateIpCollection,
  UserHasGivenFeedbackCollection,
  UserInfoCollection,
  UserInsigniasCollection,
  UserInteractionsCollection,
  UserNotificationPreferencesCollection,
  UserNpsCollection,
  UserPaymentControlsCollection,
  UserPhoneVerificationBackoffCollection,
  UserQualityDetailsCollection,
  UserRecentProjectsAndContestsCollection,
  UserRequiresGdprCollection,
  UserRequiresPaymentVerificationCollection,
  UserRequiresPhoneVerificationCollection,
  UserSettingsCollection,
  UserSkillsCollection,
  UserTaxInfoCollection,
  UserTaxOptOutReasonsCollection,
  UserVerificationProcessesCollection,
  UsersCollection,
  UsersFollowCollection,
  UsersLocationCollection,
  UsersMfaCollection,
  UsersProfileCollection,
  UsersSelfCollection,
  VerificationAddressDocumentTypesCollection,
  VerificationFilesCollection,
  VerificationIdTypesCollection,
  VerificationProcessTypesCollection,
  VerificationRequestCollection,
  VideoVerificationRequestCollection,
} from '@freelancer/datastore/collections';
import {
  generateAccountProgressObject,
  generateAdminEnterpriseObjects,
  generateAdminIrisOffsitingObject,
  generateAdminPoolObjects,
  generateAdminProjectDescriptionUpdateObject,
  generateAdminUserVerificationProcessObject,
  generateAgentObject,
  generateAgentObjects,
  generateAgentSessionObject,
  generateAgentSessionObjects,
  generateAiPromptObject,
  generateAnalyticsObjects,
  generateArticleObject,
  generateBannerObjects,
  generateBidBuyerProjectFeeObject,
  generateBidObject,
  generateBidObjects,
  generateBidPitchObject,
  generateBidRestrictionsObject,
  generateBidScoreObject,
  generateBidSummariesObject,
  generateBidUpgradeFeesObject,
  generateBillingAgreementObject,
  generateBillingAgreementObjects,
  generateCampaignObject,
  generateCanViewEmployerProfileObject,
  generateCartApprovalObject,
  generateCartItemObject,
  generateCartObject,
  generateCategoryObject,
  generateCategoryPageContent,
  generateCategoryPageViewUserObject,
  generateCertificationObject,
  generateCheckInScheduleObject,
  generateCommentFeedObject,
  generateCommentObject,
  generateCommentObjects,
  generateContentCustomizationObject,
  generateContentCustomizationObjects,
  generateContestBudgetRangesObjects,
  generateContestCollaborationObject,
  generateContestEngagementObject,
  generateContestEntryObject,
  generateContestEntryObjects,
  generateContestEntryOfferObject,
  generateContestFeesObject,
  generateContestFileObject,
  generateContestFileObjects,
  generateContestHandoverFileObject,
  generateContestHandoverObject,
  generateContestInterestedUserObject,
  generateContestObject,
  generateContestPrizeDetailObject,
  generateContestQuickviewEntryObjects,
  generateContestUserHasGivenFeedbackObject,
  generateContestViewContestObject,
  generateContestViewEntryIdsObject,
  generateContestViewEntryObjects,
  generateContractDownloadUrlObject,
  generateContractObject,
  generateCountryObjects,
  generateCurrencyIncludingExternalObjects,
  generateCurrencyObject,
  generateCurrencyObjects,
  generateCustomBannerObject,
  generateCustomFieldInfoConfigurationObjects,
  generateCustomFieldRelationshipObject,
  generateCustomFieldValidation,
  generateDashboardPollObject,
  generateDashboardWidgetObjects,
  generateDepositPageVarsObjects,
  generateDiscoverCollectionItemObject,
  generateDiscoverCollectionItemSaveCountObject,
  generateDiscoverCollectionItemSaveCounts,
  generateDiscoverCollectionObject,
  generateDomainObject,
  generateDriveFileObject,
  generateDriveFilePermissionObject,
  generateEducationObject,
  generateEmployerReputationsRehireRatesObjects,
  generateEncodedUrlObject,
  generateEnterpriseBillingAgreementsBalance,
  generateEnterpriseObject,
  generateEnterpriseObjects,
  generateEntryRestrictionsObject,
  generateExamObjects,
  generateExchangeRatesObject,
  generateExperienceObject,
  generateExperienceObjects,
  generateExternalReferenceObject,
  generateFeedMetaObjects,
  generateFeedObject,
  generateFeedPostObjects,
  generateFlareActionMapObject,
  generateFlareCheckpointObject,
  generateFlareRuleObject,
  generateFlareRulesetObject,
  generateFreelancerReputationsObject,
  generateFreelancerReputationsObjects,
  generateFreelancerReputationsRehireRatesObject,
  generateFreelancerReputationsRehireRatesObjects,
  generateGrantObject,
  generateGrantsObjects,
  generateGroupActionsObject,
  generateGroupAttachmentsObjects,
  generateGroupFeedObject,
  generateGroupMembersObject,
  generateGroupObject,
  generateGroupPermissionsObject,
  generateGroupsObject,
  generateGroupsSelf,
  generateGuideline,
  generateHirePageDetailsObject,
  generateHourlyContractObject,
  generateHourlyProjectMilestoneFeeObject,
  generateInvitationObject,
  generateInvoiceObject,
  generateIpContractObject,
  generateIsFreeBidsObject,
  generateIsLookingForWork,
  generateLanguagesObject,
  generateLanguagesObjects,
  generateLoadPriceEstimateObject,
  generateLoadTypeObjects,
  generateLoadshiftSiteStatsObject,
  generateLoginDevicesObject,
  generateManageViewContestHandoverObject,
  generateManageViewContestHandoverObjects,
  generateManageViewContestObject,
  generateManageViewOngoingBidObjects,
  generateManageViewOngoingProjectsObject,
  generateManageViewOpenBidsObjects,
  generateManageViewOpenProject,
  generateManageViewOpenProjects,
  generateManageViewPastBidObjects,
  generateManageViewPastProject,
  generateManageViewPastProjects,
  generateMarketingMobileNumberObject,
  generateMembershipBenefitObject,
  generateMembershipDowngradesObject,
  generateMembershipPackagesObjects,
  generateMembershipRenewalsObjects,
  generateMembershipSubscriptionHistoryObjects,
  generateMembershipTrialsObjects,
  generateMessage,
  generateMessages,
  generateMilestoneDraftObject,
  generateMilestoneObject,
  generateMilestoneRequestObject,
  generateMilestoneRequestObjects,
  generateNativeExchangeRatesObject,
  generateNearbyProjectObject,
  generateNotificationAcceptedObject,
  generateNotificationActivateFreelancerObject,
  generateNotificationAwardObject,
  generateNotificationAwardProjectCorporateTeamObject,
  generateNotificationBidObject,
  generateNotificationBookmarkedProjecAwardedObject,
  generateNotificationClientViewedBidObject,
  generateNotificationContestPcbObject,
  generateNotificationCorporateTeamBidPlacedObject,
  generateNotificationCustomAdminNotificationObject,
  generateNotificationDeniedObject,
  generateNotificationDraftContestObject,
  generateNotificationEarningTaxCollectedObject,
  generateNotificationEscalateDisputeObject,
  generateNotificationGroupNotificationObject,
  generateExternalReferenceReminderObject,
  generateNotificationGroupReactNotificationObject,
  generateNotificationGroupReplyNotificationObject,
  generateNotificationHireMeExpiredObject,
  generateNotificationHireMeObject,
  generateNotificationIndianMandateMembershipRecurringReminderObject,
  generateNotificationIndianMandateMembershipVerificationReminderObject,
  generateNotificationInternalLinkedProjectAwardReminderObject,
  generateNotificationInviteUserBidObject,
  generateNotificationMilestoneCreatedObject,
  generateNotificationMilestoneReleaseObject,
  generateNotificationMilestoneRequestObject,
  generateNotificationMilestoneRequestToReleaseObject,
  generateNotificationPreferenceObjects,
  generateNotificationProjectCommentCreateObject,
  generateNotificationProjectCompletedObject,
  generateNotificationProjectTitleEditRequestObject,
  generateNotificationRejectedObject,
  generateNotificationReviewActivateObject,
  generateNotificationReviewPostedNewObject,
  generateNotificationSendQuoteObject,
  generateNotificationSettingsObject,
  generateNotificationShowcaseSourceApprovalObject,
  generateNotificationTaskCommentCreateObject,
  generateNotificationTaskCreateEditObject,
  generateNotificationTaskGroupNewUserObject,
  generateNotificationUpgradeToNonFreeMembershipObject,
  generateNotificationUploadFileObject,
  generateOnBehalfProject,
  generateOnlineOfflineObjects,
  generateOperatingAreaObjects,
  generateParentContestCommentObject,
  generatePaymentShareInterestObject,
  generatePaymentShareMemberObject,
  generatePaymentShareMemberSpentAmountObject,
  generatePaymentShareTeamObject,
  generatePaymentShareWhitelistItemObject,
  generatePayoutAccountFormObjects,
  generatePayoutAccountObjects,
  generatePayoutContextObjects,
  generatePayoutLimitExemptionObjects,
  generatePayoutMethodObjects,
  generatePjpDraftObject,
  generatePoolObjects,
  generatePortfolioObject,
  generatePortfolioObjects,
  generatePostFilesObjects,
  generatePredefinedDiscoverCollectionObject,
  generatePredictedSkillsObject,
  generatePreferredBillingAgreementObject,
  generatePreferredSupportAgentAssignmentObject,
  generateProfileObject,
  generateProfileObjects,
  generateProfileViewUserObject,
  generateProfileViewUserObjects,
  generateProjectBidInfoObject,
  generateProjectBookmarkObject,
  generateProjectBookmarkObjects,
  generateProjectBudgetOptions,
  generateProjectCollaborationObject,
  generateProjectCollaborationObjects,
  generateProjectFeedObjects,
  generateProjectGroupObject,
  generateProjectGroupObjects,
  generateProjectObject,
  generateProjectQualityDetailsObjects,
  generateProjectSeo,
  generateProjectSpamControlsObject,
  generateProjectTitleEditRequestObject,
  generateProjectUpgradeFeeObjects,
  generateProjectViewBidObjects,
  generateProjectViewProjectObject,
  generateProjectViewUserObject,
  generateProjectViewUserObjects,
  generatePublicationObject,
  generateQuotationItemRevisionObject,
  generateQuotationItemRevisionObjects,
  generateQuotationLogoObject,
  generateQuotationRevisionObject,
  generateReactionTypeCountObject,
  generateReactionTypeCountObjects,
  generateRecentProjectsAndContestsObjects,
  generateRecentSavedSearchAlert,
  generateRecommendedMembershipObject,
  generateRecommendedUserNamesObject,
  generateRecurringBillingInformationObject,
  generateRecurringBillingInformationObjects,
  generateReferralInvitationCheckObject,
  generateReferralInvitationObject,
  generateReferralInvitations,
  generateReferralTokenObject,
  generateReplyContestCommentObject,
  generateReviewObject,
  generateReviewObjects,
  generateReviewSkillObject,
  generateSalesTaxObject,
  generateScheduledSubscriptionPaymentObject,
  generateScheduledSubscriptionPaymentObjects,
  generateSearchActiveContestObject,
  generateSearchActiveContestObjects,
  generateSearchActiveLoadObject,
  generateSearchActiveLoadsObject,
  generateSearchActiveProjectObject,
  generateSearchActiveProjectObjects,
  generateSearchProjectsSelfObject,
  generateSearchSavedFiltersObject,
  generateSearchUsersObject,
  generateSearchUsersObjects,
  generateSecurityPhoneRequestObject,
  generateSensitiveDetailsObject,
  generateServiceOfferingBuyerHistoriesObject,
  generateServiceOfferingCategoryObject,
  generateServiceOfferingObject,
  generateServiceOfferingShopCategoryServiceObject,
  generateServiceOfferingShopObject,
  generateServiceOfferingWithOwnerDetailsObject,
  generateSessionResolveReasonObject,
  generateShowcase,
  generateShowcaseClientNotification,
  generateShowcaseSource,
  generateShowcases,
  generateSimilarFreelancersObject,
  generateSimilarShowcasesObject,
  generateSiteStatsObject,
  generateSkillObjects,
  generateSkillsSubNavigationObject,
  generateSlaTaskObject,
  generateSubscriptionItemObject,
  generateSubscriptionItemObjects,
  generateSuggestedSkillsObject,
  generateSuggestionForFreelancerAfterReceiveReviewObject,
  generateSuperuserGroupMemberObject,
  generateSuperuserGroupPermissionsObject,
  generateSuperuserMilestoneObject,
  generateSuperuserProjectNoteObjects,
  generateSuperuserProjectViewAllProjectObject,
  generateSuperuserProjectViewProjectObject,
  generateSuperuserProjectViewUserObject,
  generateSupportAnswerObject,
  generateSupportFeedbackIsAllowedObject,
  generateSupportTagObject,
  generateTaskGroupMembersObject,
  generateTaskGroupObject,
  generateTaskGroupSectionObject,
  generateTaskGroupSectionObjects,
  generateTaskObject,
  generateTaxObject,
  generateTaxOptOutOptionObjects,
  generateTaxStatementObjects,
  generateTeamMemberObject,
  generateTeamMemberObjects,
  generateTeamObject,
  generateThread,
  generateThreadProjectObject,
  generateTimeTrackingPunch,
  generateTimeTrackingPunches,
  generateTimeTrackingSession,
  generateTimelineObject,
  generateTimezoneObjects,
  generateTopSkillObjects,
  generateUniversityObjects,
  generateUsStateObjects,
  generateUserAvailabilityObject,
  generateUserBalanceObjects,
  generateUserBidLimitObject,
  generateUserBidOnRussianProjectsObject,
  generateUserCalifornianStatusObjects,
  generateUserCartRequiresEmailVerificationObject,
  generateUserContestCollaborationWhitelist,
  generateUserDepositMethodsObjects,
  generateUserDocumentSubmission,
  generateUserDocumentSubmissions,
  generateUserEmailRequestObject,
  generateUserEnterpriseObjects,
  generateUserFollowObject,
  generateUserFollowObjects,
  generateUserGiveGetDetailsObject,
  generateUserHasDuplicateIpObject,
  generateUserHasGivenFeedbackObject,
  generateUserInfoObject,
  generateUserInsignias,
  generateUserInteractions,
  generateUserNotificationPreferencesObject,
  generateUserNpsObject,
  generateUserObject,
  generateUserObjects,
  generateUserPaymentControlObject,
  generateUserPhoneVerificationBackoffObject,
  generateUserProfileObject,
  generateUserQualityDetailsObjects,
  generateUserRequiresGdprObject,
  generateUserRequiresPaymentVerificationObject,
  generateUserRequiresPhoneVerificationObject,
  generateUserSelfObject,
  generateUserSettingsObject,
  generateUserSkillObject,
  generateUserTaxInfoObject,
  generateUserTaxOptOutReasonObject,
  generateUserVerificationProcesses,
  generateUsersLocationObject,
  generateUsersMfaObject,
  generateVehicleTypeObjects,
  generateVerificationAddressDocumentTypesObjects,
  generateVerificationFilesObject,
  generateVerificationIdTypesObjects,
  generateVerificationProcessType,
  generateVerificationProcessTypes,
  generateVerificationRequestObject,
  generateVideoVerificationRequestObject,
  generateWelcomeObject,
} from '@freelancer/datastore/collections';
import type {
  BackendPushResponse,
  DatastoreCollectionType,
  DatastoreDeleteCollectionType,
  DatastoreFetchCollectionType,
  DatastorePushCollectionType,
  DatastoreSetCollectionType,
  DatastoreUpdateCollectionType,
  Ordering,
} from '@freelancer/datastore/core';
import type {
  DatastoreTestingController,
  DeleteRequestErrorCode,
  FetchRequestErrorCode,
  IdOrIdsOrQuery,
  MutationPropagator,
  PushRequestErrorCode,
  PushTransformer,
  SearchTransformer,
  SetRequestErrorCode,
  UpdateRequestErrorCode,
  UpdateTransformer,
} from '@freelancer/datastore/testing';
import { generateId } from '@freelancer/datastore/testing/helpers';
import { isArray } from '@freelancer/utils';
import { firstValueFrom } from 'rxjs';

let globalAuth: AuthServiceInterface | undefined;
let globalDatastoreController: DatastoreTestingController | undefined;

type Unpacked<T> = T extends readonly (infer U)[] ? U : T;

function getAuth(): AuthServiceInterface {
  if (!globalAuth) {
    throw new Error('Missing Auth. Did you forget to call `setAuth()?`');
  }

  return globalAuth;
}

export function setAuth(auth: AuthServiceInterface): void {
  globalAuth = auth;
}

function getDatastoreController(): DatastoreTestingController {
  if (!globalDatastoreController) {
    throw new Error(
      'Missing Datastore. Did you forget to call `setDatastoreController()?`',
    );
  }

  return globalDatastoreController;
}

export function setDatastoreController(
  datastoreController: DatastoreTestingController,
): void {
  globalDatastoreController = datastoreController;
}

async function createDoc<
  C extends DatastoreCollectionType,
  F extends (...args: any) => any,
>(
  collectionName: C['Name'],
  generateDocument: (...args: Parameters<F>) => ReturnType<F>,
  ...config: Parameters<F>
): Promise<ReturnType<F>> {
  const docs = generateDocument(...config);
  if (!Array.isArray(docs)) {
    await getDatastoreController().createRawDocument(collectionName, docs);
  } else {
    await Promise.all(
      docs.map((document: C['DocumentType']) =>
        getDatastoreController().createRawDocument(collectionName, document),
      ),
    );
  }
  return docs;
}

// #region Controller functions

export async function createRawDocument<
  C extends DatastoreCollectionType & DatastorePushCollectionType,
>(
  collectionName: C['Name'],
  document: C['DocumentType'],
): Promise<BackendPushResponse<any>> {
  return getDatastoreController().createRawDocument<C>(
    collectionName,
    document,
  );
}

export async function resetDatastoreState<C extends DatastoreCollectionType>(
  collectionName?: C['Name'],
): Promise<void> {
  return getDatastoreController().resetState<C>(collectionName);
}

export async function printRawDatastoreState(): Promise<void> {
  return getDatastoreController().printRawState();
}

export async function printState(collectionName?: string): Promise<void>;
export async function printState(
  collectionNames: readonly string[],
): Promise<void>;
export async function printState(
  collectionNames?: string | readonly string[],
): Promise<void> {
  if (isArray(collectionNames)) {
    return getDatastoreController().printState(collectionNames);
  }
  return getDatastoreController().printState(collectionNames);
}

export function addMutationPropagator<
  C1 extends DatastoreCollectionType & DatastorePushCollectionType,
  C2 extends DatastoreCollectionType & DatastorePushCollectionType,
>(propagator: MutationPropagator<C1, C2>): void {
  return getDatastoreController().addMutationPropagator<C1, C2>(propagator);
}

export function addPushTransformer<
  C extends DatastoreCollectionType & DatastorePushCollectionType,
>(collectionName: C['Name'], transformer: PushTransformer<C>): void {
  return getDatastoreController().addPushTransformer<C>(
    collectionName,
    transformer,
  );
}

export function addUpdateTransformer<
  C extends DatastoreCollectionType & DatastoreUpdateCollectionType,
>(collectionName: C['Name'], transformer: UpdateTransformer<C>): void {
  return getDatastoreController().addUpdateTransformer<C>(
    collectionName,
    transformer,
  );
}

export function addSearchTransformer<C extends DatastoreCollectionType>(
  collectionName: C['Name'],
  transformer: SearchTransformer<C>,
  order?: Ordering<C>,
): void {
  return getDatastoreController().addSearchTransformer<C>(
    collectionName,
    transformer,
    order,
  );
}

export function makeRequestFail<
  C extends DatastoreCollectionType & DatastoreFetchCollectionType,
>(
  collectionName: C['Name'],
  idOrIdsOrQuery: IdOrIdsOrQuery<C>,
  errorCode: FetchRequestErrorCode<C> = 'UNKNOWN_ERROR',
): void {
  return getDatastoreController().makeRequestFail<C>(
    collectionName,
    idOrIdsOrQuery,
    errorCode,
  );
}

export function clearRequestFail(): void {
  return getDatastoreController().clearRequestFail();
}

export function makeCollectionFailFetch<
  C extends DatastoreCollectionType & DatastoreFetchCollectionType,
>(
  collectionName: C['Name'],
  errorCode: FetchRequestErrorCode<C> = 'UNKNOWN_ERROR',
): void {
  return getDatastoreController().makeCollectionFailFetch<C>(
    collectionName,
    errorCode,
  );
}

export function clearCollectionFailFetch(): void {
  return getDatastoreController().clearCollectionFailFetch();
}

export function makeCollectionFailPush<
  C extends DatastoreCollectionType & DatastorePushCollectionType,
>(
  collectionName: C['Name'],
  errorCode: PushRequestErrorCode<C> = 'UNKNOWN_ERROR',
): void {
  return getDatastoreController().makeCollectionFailPush<C>(
    collectionName,
    errorCode,
  );
}

export function clearCollectionFailPush(): void {
  return getDatastoreController().clearCollectionFailPush();
}

export function makeCollectionFailSet<
  C extends DatastoreCollectionType & DatastoreSetCollectionType,
>(
  collectionName: C['Name'],
  errorCode: SetRequestErrorCode<C> = 'UNKNOWN_ERROR',
): void {
  return getDatastoreController().makeCollectionFailSet<C>(
    collectionName,
    errorCode,
  );
}

export function clearCollectionFailSet(): void {
  return getDatastoreController().clearCollectionFailSet();
}

export function makeCollectionFailUpdate<
  C extends DatastoreCollectionType & DatastoreUpdateCollectionType,
>(
  collectionName: C['Name'],
  errorCode: UpdateRequestErrorCode<C> = 'UNKNOWN_ERROR',
): void {
  return getDatastoreController().makeCollectionFailUpdate<C>(
    collectionName,
    errorCode,
  );
}

export function clearCollectionFailUpdate(): void {
  return getDatastoreController().clearCollectionFailUpdate();
}

export function makeCollectionFailDelete<
  C extends DatastoreCollectionType & DatastoreDeleteCollectionType,
>(
  collectionName: C['Name'],
  errorCode: DeleteRequestErrorCode<C> = 'UNKNOWN_ERROR',
): void {
  return getDatastoreController().makeCollectionFailDelete<C>(
    collectionName,
    errorCode,
  );
}

export function clearCollectionFailDelete(): void {
  return getDatastoreController().clearCollectionFailDelete();
}

export function makeCollectionFailWhenEmpty<
  C extends DatastoreCollectionType & DatastoreFetchCollectionType,
>(collectionName: C['Name']): void {
  return getDatastoreController().makeCollectionFailWhenEmpty<C>(
    collectionName,
  );
}

export function clearCollectionFailWhenEmpty(): void {
  return getDatastoreController().clearCollectionFailWhenEmpty();
}

export function makeCollectionDelayed<C extends DatastoreCollectionType>({
  collectionName,
  delayMilliseconds,
  requestType,
}: {
  readonly collectionName: C['Name'];
  readonly delayMilliseconds: number;
  readonly requestType: 'fetch' | 'push' | 'set' | 'update' | 'delete';
}): void {
  return getDatastoreController().makeCollectionDelayed<C>(
    collectionName,
    delayMilliseconds,
    requestType,
  );
}

export function clearCollectionDelayed(): void {
  return getDatastoreController().clearCollectionDelayed();
}

export function makeCollectionPending<
  C extends DatastoreCollectionType & DatastoreFetchCollectionType,
>(collectionName: C['Name']): void {
  return getDatastoreController().makeCollectionPending<C>(collectionName);
}

export function clearCollectionPending(): void {
  return getDatastoreController().clearCollectionPending();
}

// #endregion Controller functions

// #region Helper functions for creating account.

export async function signup(
  userOptions: Unpacked<Parameters<typeof generateUserObject>> = {},
): Promise<User> {
  const userId = userOptions.userId || generateId();

  const isLoggedIn = await firstValueFrom(getAuth().isLoggedIn());
  if (!isLoggedIn) {
    console.warn(
      `User ${userId} has been created before calling signupAndLogin - it will not be visible to logged in users`,
    );
  }

  return createDoc<UsersCollection, typeof generateUserObject>(
    'users',
    generateUserObject,
    {
      userId,
      ...userOptions,
    },
  );
}

export async function signupAndLogin(
  userOptions: Unpacked<Parameters<typeof generateUserObject>> = {},
  userSelfOptions: Unpacked<Parameters<typeof generateUserSelfObject>> = {},
): Promise<User> {
  // We have to "login" first because otherwise the datastore documents are not
  // accessible to the user that just signed up. See `signup()`

  // Log in
  const userId = userOptions.userId || generateId();
  getAuth().setSession(userId.toString(), '');

  const user = await createDoc<UsersCollection, typeof generateUserObject>(
    'users',
    generateUserObject,
    {
      userId,
      ...userOptions,
    },
  );

  await createDoc<UsersSelfCollection, typeof generateUserSelfObject>(
    'usersSelf',
    generateUserSelfObject,
    {
      userId,
      ...userSelfOptions,
    },
  );

  // Ref T254483: Clean up after A/B test
  await createUserRequiresPhoneVerification({
    userId: userId.toString(),
    userRequiresPhoneVerification: false,
  });

  return user;
}

// #endregion Helper functions for creating account.

// #region Single object creation functions. These should all be singular.

export async function createAdminProjectDescriptionUpdate(
  ...config: Parameters<typeof generateAdminProjectDescriptionUpdateObject>
): Promise<ReturnType<typeof generateAdminProjectDescriptionUpdateObject>> {
  return createDoc<
    AdminProjectDescriptionUpdateCollection,
    typeof generateAdminProjectDescriptionUpdateObject
  >(
    'adminProjectDescriptionUpdate',
    generateAdminProjectDescriptionUpdateObject,
    ...config,
  );
}

export async function createBidRestriction(
  ...config: Parameters<typeof generateBidRestrictionsObject>
): Promise<ReturnType<typeof generateBidRestrictionsObject>> {
  return createDoc<
    BidRestrictionsCollection,
    typeof generateBidRestrictionsObject
  >('bidRestrictions', generateBidRestrictionsObject, ...config);
}

export async function createBidSummary(
  ...config: Parameters<typeof generateBidSummariesObject>
): Promise<ReturnType<typeof generateBidSummariesObject>> {
  return createDoc<BidSummariesCollection, typeof generateBidSummariesObject>(
    'bidSummaries',
    generateBidSummariesObject,
    ...config,
  );
}

export async function createBidUpgradeFee(
  ...config: Parameters<typeof generateBidUpgradeFeesObject>
): Promise<ReturnType<typeof generateBidUpgradeFeesObject>> {
  return createDoc<
    BidUpgradeFeesCollection,
    typeof generateBidUpgradeFeesObject
  >('bidUpgradeFees', generateBidUpgradeFeesObject, ...config);
}

export async function createBillingAgreement(
  ...config: Parameters<typeof generateBillingAgreementObject>
): Promise<ReturnType<typeof generateBillingAgreementObject>> {
  return createDoc<
    BillingAgreementsCollection,
    typeof generateBillingAgreementObject
  >('billingAgreements', generateBillingAgreementObject, ...config);
}

export async function createCanViewEmployerProfile(
  ...config: Parameters<typeof generateCanViewEmployerProfileObject>
): Promise<ReturnType<typeof generateCanViewEmployerProfileObject>> {
  return createDoc<
    CanViewEmployerProfileCollection,
    typeof generateCanViewEmployerProfileObject
  >('canViewEmployerProfile', generateCanViewEmployerProfileObject, ...config);
}

export async function createCartItem(
  ...config: Parameters<typeof generateCartItemObject>
): Promise<ReturnType<typeof generateCartItemObject>> {
  return createDoc<CartItemsCollection, typeof generateCartItemObject>(
    'cartItems',
    generateCartItemObject,
    ...config,
  );
}

export async function createCart(
  ...config: Parameters<typeof generateCartObject>
): Promise<ReturnType<typeof generateCartObject>> {
  return createDoc<CartsCollection, typeof generateCartObject>(
    'carts',
    generateCartObject,
    ...config,
  );
}

export async function createCartApproval(
  ...config: Parameters<typeof generateCartApprovalObject>
): Promise<ReturnType<typeof generateCartApprovalObject>> {
  return createDoc<CartApprovalsCollection, typeof generateCartApprovalObject>(
    'cartApprovals',
    generateCartApprovalObject,
    ...config,
  );
}

export async function createCategoryPageContent(
  ...config: Parameters<typeof generateCategoryPageContent>
): Promise<ReturnType<typeof generateCategoryPageContent>> {
  return createDoc<
    CategoryPageContentCollection,
    typeof generateCategoryPageContent
  >('categoryPageContent', generateCategoryPageContent, ...config);
}

export async function createCertification(
  ...config: Parameters<typeof generateCertificationObject>
): Promise<ReturnType<typeof generateCertificationObject>> {
  return createDoc<
    CertificationsCollection,
    typeof generateCertificationObject
  >('certifications', generateCertificationObject, ...config);
}

export async function createContentCustomization(
  ...config: Parameters<typeof generateContentCustomizationObject>
): Promise<ReturnType<typeof generateContentCustomizationObject>> {
  return createDoc<
    ContentCustomizationCollection,
    typeof generateContentCustomizationObject
  >('contentCustomization', generateContentCustomizationObject, ...config);
}

export async function createCustomBanner(
  ...config: Parameters<typeof generateCustomBannerObject>
): Promise<ReturnType<typeof generateCustomBannerObject>> {
  return createDoc<CustomBannersCollection, typeof generateCustomBannerObject>(
    'customBanners',
    generateCustomBannerObject,
    ...config,
  );
}

export async function createContest(
  ...config: Parameters<typeof generateContestObject>
): Promise<ReturnType<typeof generateContestObject>> {
  return createDoc<ContestsCollection, typeof generateContestObject>(
    'contests',
    generateContestObject,
    ...config,
  );
}

export async function createContestEngagement(
  ...config: Parameters<typeof generateContestEngagementObject>
): Promise<ReturnType<typeof generateContestEngagementObject>> {
  return createDoc<
    ContestEngagementCollection,
    typeof generateContestEngagementObject
  >('contestEngagement', generateContestEngagementObject, ...config);
}

export async function createContestEntry(
  ...config: Parameters<typeof generateContestEntryObject>
): Promise<ReturnType<typeof generateContestEntryObject>> {
  return createDoc<ContestEntriesCollection, typeof generateContestEntryObject>(
    'contestEntries',
    generateContestEntryObject,
    ...config,
  );
}

export async function createContestEntryOffer(
  ...config: Parameters<typeof generateContestEntryOfferObject>
): Promise<ReturnType<typeof generateContestEntryOfferObject>> {
  return createDoc<
    ContestEntryOffersCollection,
    typeof generateContestEntryOfferObject
  >('contestEntryOffers', generateContestEntryOfferObject, ...config);
}

export async function createContestHandover(
  ...config: Parameters<typeof generateContestHandoverObject>
): Promise<ReturnType<typeof generateContestHandoverObject>> {
  return createDoc<
    ContestHandoversCollection,
    typeof generateContestHandoverObject
  >('contestHandovers', generateContestHandoverObject, ...config);
}

export async function createContestHandoverFile(
  ...config: Parameters<typeof generateContestHandoverFileObject>
): Promise<ReturnType<typeof generateContestHandoverFileObject>> {
  return createDoc<
    ContestHandoverFilesCollection,
    typeof generateContestHandoverFileObject
  >('contestHandoverFiles', generateContestHandoverFileObject, ...config);
}

export async function createContestViewContest(
  ...config: Parameters<typeof generateContestViewContestObject>
): Promise<ReturnType<typeof generateContestViewContestObject>> {
  return createDoc<
    ContestViewContestsCollection,
    typeof generateContestViewContestObject
  >('contestViewContests', generateContestViewContestObject, ...config);
}

export async function createContestFile(
  ...config: Parameters<typeof generateContestFileObject>
): Promise<ReturnType<typeof generateContestFileObject>> {
  return createDoc<ContestFilesCollection, typeof generateContestFileObject>(
    'contestFiles',
    generateContestFileObject,
    ...config,
  );
}

export async function createContestCollaborator(
  ...config: Parameters<typeof generateContestCollaborationObject>
): Promise<ReturnType<typeof generateContestCollaborationObject>> {
  return createDoc<
    ContestCollaborationsCollection,
    typeof generateContestCollaborationObject
  >('contestCollaborations', generateContestCollaborationObject, ...config);
}

export async function createContestPrizeDetail(
  ...config: Parameters<typeof generateContestPrizeDetailObject>
): Promise<ReturnType<typeof generateContestPrizeDetailObject>> {
  return createDoc<
    ContestPrizeDetailsCollection,
    typeof generateContestPrizeDetailObject
  >('contestPrizeDetails', generateContestPrizeDetailObject, ...config);
}

export async function createContestUserHasGivenFeedback(
  ...config: Parameters<typeof generateContestUserHasGivenFeedbackObject>
): Promise<ReturnType<typeof generateContestUserHasGivenFeedbackObject>> {
  return createDoc<
    ContestUserHasGivenFeedbackCollection,
    typeof generateContestUserHasGivenFeedbackObject
  >(
    'contestUserHasGivenFeedback',
    generateContestUserHasGivenFeedbackObject,
    ...config,
  );
}

export async function createContract(
  ...config: Parameters<typeof generateContractObject>
): Promise<ReturnType<typeof generateContractObject>> {
  return createDoc<ContractsCollection, typeof generateContractObject>(
    'contracts',
    generateContractObject,
    ...config,
  );
}

export async function createContractDownloadUrl(
  ...config: Parameters<typeof generateContractDownloadUrlObject>
): Promise<ReturnType<typeof generateContractDownloadUrlObject>> {
  return createDoc<
    ContractDownloadUrlsCollection,
    typeof generateContractDownloadUrlObject
  >('contractDownloadUrls', generateContractDownloadUrlObject, ...config);
}

export async function createDomain(
  ...config: Parameters<typeof generateDomainObject>
): Promise<ReturnType<typeof generateDomainObject>> {
  return createDoc<DomainsCollection, typeof generateDomainObject>(
    'domains',
    generateDomainObject,
    ...config,
  );
}

export async function createEducation(
  ...config: Parameters<typeof generateEducationObject>
): Promise<ReturnType<typeof generateEducationObject>> {
  return createDoc<EducationsCollection, typeof generateEducationObject>(
    'educations',
    generateEducationObject,
    ...config,
  );
}

export async function createEncodedUrl(
  ...config: Parameters<typeof generateEncodedUrlObject>
): Promise<ReturnType<typeof generateEncodedUrlObject>> {
  return createDoc<EncodedUrlCollection, typeof generateEncodedUrlObject>(
    'encodedUrl',
    generateEncodedUrlObject,
    ...config,
  );
}

export async function createEntryRestriction(
  ...config: Parameters<typeof generateEntryRestrictionsObject>
): Promise<ReturnType<typeof generateEntryRestrictionsObject>> {
  return createDoc<
    EntryRestrictionsCollection,
    typeof generateEntryRestrictionsObject
  >('entryRestrictions', generateEntryRestrictionsObject, ...config);
}

export async function createExperience(
  ...config: Parameters<typeof generateExperienceObject>
): Promise<ReturnType<typeof generateExperienceObject>> {
  return createDoc<ExperiencesCollection, typeof generateExperienceObject>(
    'experiences',
    generateExperienceObject,
    ...config,
  );
}

export async function createExperiences(
  ...config: Parameters<typeof generateExperienceObjects>
): Promise<ReturnType<typeof generateExperienceObjects>> {
  return createDoc<ExperiencesCollection, typeof generateExperienceObjects>(
    'experiences',
    generateExperienceObjects,
    ...config,
  );
}

export async function createFreelancerReputation(
  ...config: Parameters<typeof generateFreelancerReputationsObject>
): Promise<ReturnType<typeof generateFreelancerReputationsObject>> {
  return createDoc<
    FreelancerReputationsCollection,
    typeof generateFreelancerReputationsObject
  >('freelancerReputations', generateFreelancerReputationsObject, ...config);
}

export async function createFreelancerReputationsRehireRate(
  ...config: Parameters<typeof generateFreelancerReputationsRehireRatesObject>
): Promise<ReturnType<typeof generateFreelancerReputationsRehireRatesObject>> {
  return createDoc<
    FreelancerReputationsRehireRatesCollection,
    typeof generateFreelancerReputationsRehireRatesObject
  >(
    'freelancerReputationsRehireRates',
    generateFreelancerReputationsRehireRatesObject,
    ...config,
  );
}

export async function createHourlyContract(
  ...config: Parameters<typeof generateHourlyContractObject>
): Promise<ReturnType<typeof generateHourlyContractObject>> {
  return createDoc<
    HourlyContractsCollection,
    typeof generateHourlyContractObject
  >('hourlyContracts', generateHourlyContractObject, ...config);
}
export async function createHourlyProjectMilestoneFee(
  ...config: Parameters<typeof generateHourlyProjectMilestoneFeeObject>
): Promise<ReturnType<typeof generateHourlyProjectMilestoneFeeObject>> {
  return createDoc<
    HourlyProjectMilestoneFeesCollection,
    typeof generateHourlyProjectMilestoneFeeObject
  >(
    'hourlyProjectMilestoneFees',
    generateHourlyProjectMilestoneFeeObject,
    ...config,
  );
}

export async function createInvitation(
  ...config: Parameters<typeof generateInvitationObject>
): Promise<ReturnType<typeof generateInvitationObject>> {
  return createDoc<InvitationsCollection, typeof generateInvitationObject>(
    'invitations',
    generateInvitationObject,
    ...config,
  );
}

export async function createLanguage(
  ...config: Parameters<typeof generateLanguagesObject>
): Promise<ReturnType<typeof generateLanguagesObject>> {
  return createDoc<LanguagesCollection, typeof generateLanguagesObject>(
    'languages',
    generateLanguagesObject,
    ...config,
  );
}

export async function createLoadshiftSiteStats(
  ...config: Parameters<typeof generateLoadshiftSiteStatsObject>
): Promise<ReturnType<typeof generateLoadshiftSiteStatsObject>> {
  return createDoc<
    LoadshiftSiteStatsCollection,
    typeof generateLoadshiftSiteStatsObject
  >('loadshiftSiteStats', generateLoadshiftSiteStatsObject, ...config);
}

export async function createMembershipBenefit(
  ...config: Parameters<typeof generateMembershipBenefitObject>
): Promise<ReturnType<typeof generateMembershipBenefitObject>> {
  return createDoc<
    MembershipBenefitsCollection,
    typeof generateMembershipBenefitObject
  >('membershipBenefits', generateMembershipBenefitObject, ...config);
}

export async function createMessage(
  ...config: Parameters<typeof generateMessage>
): Promise<ReturnType<typeof generateMessage>> {
  return createDoc<MessagesCollection, typeof generateMessage>(
    'messages',
    generateMessage,
    ...config,
  );
}

export async function createMilestone(
  ...config: Parameters<typeof generateMilestoneObject>
): Promise<ReturnType<typeof generateMilestoneObject>> {
  return createDoc<MilestonesCollection, typeof generateMilestoneObject>(
    'milestones',
    generateMilestoneObject,
    ...config,
  );
}

export async function createMilestoneDraft(
  ...config: Parameters<typeof generateMilestoneDraftObject>
): Promise<ReturnType<typeof generateMilestoneDraftObject>> {
  return createDoc<
    MilestoneDraftsCollection,
    typeof generateMilestoneDraftObject
  >('milestoneDrafts', generateMilestoneDraftObject, ...config);
}

export async function createNearbyProject(
  ...config: Parameters<typeof generateNearbyProjectObject>
): Promise<ReturnType<typeof generateNearbyProjectObject>> {
  return createDoc<
    NearbyProjectsCollection,
    typeof generateNearbyProjectObject
  >('nearbyProjects', generateNearbyProjectObject, ...config);
}

export async function createNewsfeedProjectAccepted(
  ...config: Parameters<typeof generateNotificationAcceptedObject>
): Promise<ReturnType<typeof generateNotificationAcceptedObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationAcceptedObject
  >('newsfeed', generateNotificationAcceptedObject, ...config);
}

export async function createNewsfeedActivateFreelancer(
  ...config: Parameters<typeof generateNotificationActivateFreelancerObject>
): Promise<ReturnType<typeof generateNotificationActivateFreelancerObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationActivateFreelancerObject
  >('newsfeed', generateNotificationActivateFreelancerObject, ...config);
}

export async function createNewsfeedProjectAwarded(
  ...config: Parameters<typeof generateNotificationAwardObject>
): Promise<ReturnType<typeof generateNotificationAwardObject>> {
  return createDoc<NewsfeedCollection, typeof generateNotificationAwardObject>(
    'newsfeed',
    generateNotificationAwardObject,
    ...config,
  );
}

export async function createNewsfeedDenied(
  ...config: Parameters<typeof generateNotificationDeniedObject>
): Promise<ReturnType<typeof generateNotificationDeniedObject>> {
  return createDoc<NewsfeedCollection, typeof generateNotificationDeniedObject>(
    'newsfeed',
    generateNotificationDeniedObject,
    ...config,
  );
}

export async function createNewsfeedInviteUserBid(
  ...config: Parameters<typeof generateNotificationInviteUserBidObject>
): Promise<ReturnType<typeof generateNotificationInviteUserBidObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationInviteUserBidObject
  >('newsfeed', generateNotificationInviteUserBidObject, ...config);
}

export async function createNewsfeedProjectBidOn(
  ...config: Parameters<typeof generateNotificationBidObject>
): Promise<ReturnType<typeof generateNotificationBidObject>> {
  return createDoc<NewsfeedCollection, typeof generateNotificationBidObject>(
    'newsfeed',
    generateNotificationBidObject,
    ...config,
  );
}

export async function createNewsfeedProjectReviewPostedNew(
  ...config: Parameters<typeof generateNotificationReviewPostedNewObject>
): Promise<ReturnType<typeof generateNotificationReviewPostedNewObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationReviewPostedNewObject
  >('newsfeed', generateNotificationReviewPostedNewObject, ...config);
}

export async function createNewsfeedProjectEditRequest(
  ...config: Parameters<
    typeof generateNotificationProjectTitleEditRequestObject
  >
): Promise<
  ReturnType<typeof generateNotificationProjectTitleEditRequestObject>
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationProjectTitleEditRequestObject
  >('newsfeed', generateNotificationProjectTitleEditRequestObject, ...config);
}

export async function createNewsfeedProjectReviewActivate(
  ...config: Parameters<typeof generateNotificationReviewActivateObject>
): Promise<ReturnType<typeof generateNotificationReviewActivateObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationReviewActivateObject
  >('newsfeed', generateNotificationReviewActivateObject, ...config);
}

export async function createNewsfeedRejected(
  ...config: Parameters<typeof generateNotificationRejectedObject>
): Promise<ReturnType<typeof generateNotificationRejectedObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationRejectedObject
  >('newsfeed', generateNotificationRejectedObject, ...config);
}

export async function createNewsfeedBookmarkedProjectAwarded(
  ...config: Parameters<
    typeof generateNotificationBookmarkedProjecAwardedObject
  >
): Promise<
  ReturnType<typeof generateNotificationBookmarkedProjecAwardedObject>
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationBookmarkedProjecAwardedObject
  >('newsfeed', generateNotificationBookmarkedProjecAwardedObject, ...config);
}

export async function createNewsfeedContestPcb(
  ...config: Parameters<typeof generateNotificationContestPcbObject>
): Promise<ReturnType<typeof generateNotificationContestPcbObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationContestPcbObject
  >('newsfeed', generateNotificationContestPcbObject, ...config);
}

export async function createNewsfeedDraftContest(
  ...config: Parameters<typeof generateNotificationDraftContestObject>
): Promise<ReturnType<typeof generateNotificationDraftContestObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationDraftContestObject
  >('newsfeed', generateNotificationDraftContestObject, ...config);
}

export async function createNewsfeedCreateMilestone(
  ...config: Parameters<typeof generateNotificationMilestoneCreatedObject>
): Promise<ReturnType<typeof generateNotificationMilestoneCreatedObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationMilestoneCreatedObject
  >('newsfeed', generateNotificationMilestoneCreatedObject, ...config);
}

export async function createNewsfeedHireMe(
  ...config: Parameters<typeof generateNotificationHireMeObject>
): Promise<ReturnType<typeof generateNotificationHireMeObject>> {
  return createDoc<NewsfeedCollection, typeof generateNotificationHireMeObject>(
    'newsfeed',
    generateNotificationHireMeObject,
    ...config,
  );
}

export async function createNewsfeedHireMeExpired(
  ...config: Parameters<typeof generateNotificationHireMeExpiredObject>
): Promise<ReturnType<typeof generateNotificationHireMeExpiredObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationHireMeExpiredObject
  >('newsfeed', generateNotificationHireMeExpiredObject, ...config);
}

export async function createNewsfeedInternalLinkedProjectAwardReminder(
  ...config: Parameters<
    typeof generateNotificationInternalLinkedProjectAwardReminderObject
  >
): Promise<
  ReturnType<
    typeof generateNotificationInternalLinkedProjectAwardReminderObject
  >
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationInternalLinkedProjectAwardReminderObject
  >(
    'newsfeed',
    generateNotificationInternalLinkedProjectAwardReminderObject,
    ...config,
  );
}

export async function createNewsfeedRequestToRelease(
  ...config: Parameters<
    typeof generateNotificationMilestoneRequestToReleaseObject
  >
): Promise<
  ReturnType<typeof generateNotificationMilestoneRequestToReleaseObject>
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationMilestoneRequestToReleaseObject
  >('newsfeed', generateNotificationMilestoneRequestToReleaseObject, ...config);
}

export async function createNewsfeedSendQuote(
  ...config: Parameters<typeof generateNotificationSendQuoteObject>
): Promise<ReturnType<typeof generateNotificationSendQuoteObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationSendQuoteObject
  >('newsfeed', generateNotificationSendQuoteObject, ...config);
}

export async function createNewsfeedReleaseMilestone(
  ...config: Parameters<typeof generateNotificationMilestoneReleaseObject>
): Promise<ReturnType<typeof generateNotificationMilestoneReleaseObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationMilestoneReleaseObject
  >('newsfeed', generateNotificationMilestoneReleaseObject, ...config);
}

export async function createNewsfeedRequestMilestone(
  ...config: Parameters<typeof generateNotificationMilestoneRequestObject>
): Promise<ReturnType<typeof generateNotificationMilestoneRequestObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationMilestoneRequestObject
  >('newsfeed', generateNotificationMilestoneRequestObject, ...config);
}

export async function createNewsfeedIndianMandateMembershipRecurringReminder(
  ...config: Parameters<
    typeof generateNotificationIndianMandateMembershipRecurringReminderObject
  >
): Promise<
  ReturnType<
    typeof generateNotificationIndianMandateMembershipRecurringReminderObject
  >
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationIndianMandateMembershipRecurringReminderObject
  >(
    'newsfeed',
    generateNotificationIndianMandateMembershipRecurringReminderObject,
    ...config,
  );
}

export async function createNewsfeedIndianMandateMembershipVerificationReminder(
  ...config: Parameters<
    typeof generateNotificationIndianMandateMembershipVerificationReminderObject
  >
): Promise<
  ReturnType<
    typeof generateNotificationIndianMandateMembershipVerificationReminderObject
  >
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationIndianMandateMembershipVerificationReminderObject
  >(
    'newsfeed',
    generateNotificationIndianMandateMembershipVerificationReminderObject,
    ...config,
  );
}

export async function createNewsfeedEscalateDispute(
  ...config: Parameters<typeof generateNotificationEscalateDisputeObject>
): Promise<ReturnType<typeof generateNotificationEscalateDisputeObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationEscalateDisputeObject
  >('newsfeed', generateNotificationEscalateDisputeObject, ...config);
}

export async function createNewsfeedCorporateTeamBidPlaced(
  ...config: Parameters<typeof generateNotificationCorporateTeamBidPlacedObject>
): Promise<
  ReturnType<typeof generateNotificationCorporateTeamBidPlacedObject>
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationCorporateTeamBidPlacedObject
  >('newsfeed', generateNotificationCorporateTeamBidPlacedObject, ...config);
}

export async function createNewsfeedCustomAdminNotification(
  ...config: Parameters<
    typeof generateNotificationCustomAdminNotificationObject
  >
): Promise<
  ReturnType<typeof generateNotificationCustomAdminNotificationObject>
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationCustomAdminNotificationObject
  >('newsfeed', generateNotificationCustomAdminNotificationObject, ...config);
}

export async function createNewsfeedAwardProjectCorporateTeam(
  ...config: Parameters<
    typeof generateNotificationAwardProjectCorporateTeamObject
  >
): Promise<
  ReturnType<typeof generateNotificationAwardProjectCorporateTeamObject>
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationAwardProjectCorporateTeamObject
  >('newsfeed', generateNotificationAwardProjectCorporateTeamObject, ...config);
}

export async function createShowcaseClientNotification(
  ...config: Parameters<typeof generateShowcaseClientNotification>
): Promise<ReturnType<typeof generateShowcaseClientNotification>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateShowcaseClientNotification
  >('newsfeed', generateShowcaseClientNotification, ...config);
}

export async function createNewsfeedShowcaseSourceApproval(
  ...config: Parameters<typeof generateNotificationShowcaseSourceApprovalObject>
): Promise<
  ReturnType<typeof generateNotificationShowcaseSourceApprovalObject>
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationShowcaseSourceApprovalObject
  >('newsfeed', generateNotificationShowcaseSourceApprovalObject, ...config);
}

export async function createNewsfeedClientViewedBid(
  ...config: Parameters<typeof generateNotificationClientViewedBidObject>
): Promise<ReturnType<typeof generateNotificationClientViewedBidObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationClientViewedBidObject
  >('newsfeed', generateNotificationClientViewedBidObject, ...config);
}

export async function createNewsfeedSuggestionForFreelancerAfterReceiveReview(
  ...config: Parameters<
    typeof generateSuggestionForFreelancerAfterReceiveReviewObject
  >
): Promise<
  ReturnType<typeof generateSuggestionForFreelancerAfterReceiveReviewObject>
> {
  return createDoc<
    NewsfeedCollection,
    typeof generateSuggestionForFreelancerAfterReceiveReviewObject
  >(
    'newsfeed',
    generateSuggestionForFreelancerAfterReceiveReviewObject,
    ...config,
  );
}

export async function createNewsfeedTaskGroupNewUser(
  ...config: Parameters<typeof generateNotificationTaskGroupNewUserObject>
): Promise<ReturnType<typeof generateNotificationTaskGroupNewUserObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateNotificationTaskGroupNewUserObject
  >('newsfeed', generateNotificationTaskGroupNewUserObject, ...config);
}

export async function createNewsfeedWelcome(
  ...config: Parameters<typeof generateWelcomeObject>
): Promise<ReturnType<typeof generateWelcomeObject>> {
  return createDoc<NewsfeedCollection, typeof generateWelcomeObject>(
    'newsfeed',
    generateWelcomeObject,
    ...config,
  );
}

export async function createExternalReferenceReminder(
  ...config: Parameters<typeof generateExternalReferenceReminderObject>
): Promise<ReturnType<typeof generateExternalReferenceReminderObject>> {
  return createDoc<
    NewsfeedCollection,
    typeof generateExternalReferenceReminderObject
  >('newsfeed', generateExternalReferenceReminderObject, ...config);
}

export async function createNotificationGroupNotification(
  ...config: Parameters<typeof generateNotificationGroupNotificationObject>
): Promise<ReturnType<typeof generateNotificationGroupNotificationObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationGroupNotificationObject
  >('notifications', generateNotificationGroupNotificationObject, ...config);
}

export async function createNotificationGroupReactNotification(
  ...config: Parameters<typeof generateNotificationGroupReactNotificationObject>
): Promise<
  ReturnType<typeof generateNotificationGroupReactNotificationObject>
> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationGroupReactNotificationObject
  >(
    'notifications',
    generateNotificationGroupReactNotificationObject,
    ...config,
  );
}

export async function createNotificationGroupReplyNotification(
  ...config: Parameters<typeof generateNotificationGroupReplyNotificationObject>
): Promise<
  ReturnType<typeof generateNotificationGroupReplyNotificationObject>
> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationGroupReplyNotificationObject
  >(
    'notifications',
    generateNotificationGroupReplyNotificationObject,
    ...config,
  );
}

export async function createNotificationProjectCommentCreate(
  ...config: Parameters<typeof generateNotificationProjectCommentCreateObject>
): Promise<ReturnType<typeof generateNotificationProjectCommentCreateObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationProjectCommentCreateObject
  >('notifications', generateNotificationProjectCommentCreateObject, ...config);
}

export async function createNotificationTaskCommentCreate(
  ...config: Parameters<typeof generateNotificationTaskCommentCreateObject>
): Promise<ReturnType<typeof generateNotificationTaskCommentCreateObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationTaskCommentCreateObject
  >('notifications', generateNotificationTaskCommentCreateObject, ...config);
}

export async function createNotificationTaskCreateEdit(
  ...config: Parameters<typeof generateNotificationTaskCreateEditObject>
): Promise<ReturnType<typeof generateNotificationTaskCreateEditObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationTaskCreateEditObject
  >('notifications', generateNotificationTaskCreateEditObject, ...config);
}

export async function createNotificationTaskGroupNewUser(
  ...config: Parameters<typeof generateNotificationTaskGroupNewUserObject>
): Promise<ReturnType<typeof generateNotificationTaskGroupNewUserObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationTaskGroupNewUserObject
  >('notifications', generateNotificationTaskGroupNewUserObject, ...config);
}

export async function createNotificationRequestMilestone(
  ...config: Parameters<typeof generateNotificationMilestoneRequestObject>
): Promise<ReturnType<typeof generateNotificationMilestoneRequestObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationMilestoneRequestObject
  >('notifications', generateNotificationMilestoneRequestObject, ...config);
}

export async function createNotificationRequestToRelease(
  ...config: Parameters<
    typeof generateNotificationMilestoneRequestToReleaseObject
  >
): Promise<
  ReturnType<typeof generateNotificationMilestoneRequestToReleaseObject>
> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationMilestoneRequestToReleaseObject
  >(
    'notifications',
    generateNotificationMilestoneRequestToReleaseObject,
    ...config,
  );
}

export async function createNotificationUpgradeToNonFreeMembership(
  ...config: Parameters<
    typeof generateNotificationUpgradeToNonFreeMembershipObject
  >
): Promise<
  ReturnType<typeof generateNotificationUpgradeToNonFreeMembershipObject>
> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationUpgradeToNonFreeMembershipObject
  >(
    'notifications',
    generateNotificationUpgradeToNonFreeMembershipObject,
    ...config,
  );
}

export async function createNotificationUploadFile(
  ...config: Parameters<typeof generateNotificationUploadFileObject>
): Promise<ReturnType<typeof generateNotificationUploadFileObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationUploadFileObject
  >('notifications', generateNotificationUploadFileObject, ...config);
}

export async function createNotificationProjectCompleted(
  ...config: Parameters<typeof generateNotificationProjectCompletedObject>
): Promise<ReturnType<typeof generateNotificationProjectCompletedObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationProjectCompletedObject
  >('notifications', generateNotificationProjectCompletedObject, ...config);
}

export async function createNotificationEarningTaxCollected(
  ...config: Parameters<typeof generateNotificationEarningTaxCollectedObject>
): Promise<ReturnType<typeof generateNotificationEarningTaxCollectedObject>> {
  return createDoc<
    NotificationsCollection,
    typeof generateNotificationEarningTaxCollectedObject
  >('notifications', generateNotificationEarningTaxCollectedObject, ...config);
}

/* This actually creates a single portfolio item, not a whole portfolio */
export async function createPortfolio(
  ...config: Parameters<typeof generatePortfolioObject>
): Promise<ReturnType<typeof generatePortfolioObject>> {
  return createDoc<PortfoliosCollection, typeof generatePortfolioObject>(
    'portfolios',
    generatePortfolioObject,
    ...config,
  );
}

export async function createCategory(
  ...config: Parameters<typeof generateCategoryObject>
): Promise<ReturnType<typeof generateCategoryObject>> {
  return createDoc<ProfileCategoriesCollection, typeof generateCategoryObject>(
    'profileCategories',
    generateCategoryObject,
    ...config,
  );
}

export async function createProfileViewUser(
  ...config: Parameters<typeof generateProfileViewUserObject>
): Promise<ReturnType<typeof generateProfileViewUserObject>> {
  return createDoc<
    ProfileViewUsersCollection,
    typeof generateProfileViewUserObject
  >('profileViewUsers', generateProfileViewUserObject, ...config);
}

export async function createProjectBidInfo(
  ...config: Parameters<typeof generateProjectBidInfoObject>
): Promise<ReturnType<typeof generateProjectBidInfoObject>> {
  return createDoc<
    ProjectBidInfoCollection,
    typeof generateProjectBidInfoObject
  >('projectBidInfo', generateProjectBidInfoObject, ...config);
}

export async function createProjectBookmark(
  ...config: Parameters<typeof generateProjectBookmarkObject>
): Promise<ReturnType<typeof generateProjectBookmarkObject>> {
  return createDoc<
    ProjectBookmarksCollection,
    typeof generateProjectBookmarkObject
  >('projectBookmarks', generateProjectBookmarkObject, ...config);
}

export async function createProjectBookmarks(
  ...config: Parameters<typeof generateProjectBookmarkObjects>
): Promise<ReturnType<typeof generateProjectBookmarkObjects>> {
  return createDoc<
    ProjectBookmarksCollection,
    typeof generateProjectBookmarkObjects
  >('projectBookmarks', generateProjectBookmarkObjects, ...config);
}

export async function createProject(
  ...config: Parameters<typeof generateProjectObject>
): Promise<ReturnType<typeof generateProjectObject>> {
  return createDoc<ProjectsCollection, typeof generateProjectObject>(
    'projects',
    generateProjectObject,
    ...config,
  );
}

export async function createProjectTitleEditRequest(
  ...config: Parameters<typeof generateProjectTitleEditRequestObject>
): Promise<ReturnType<typeof generateProjectTitleEditRequestObject>> {
  return createDoc<
    ProjectTitleEditRequestCollection,
    typeof generateProjectTitleEditRequestObject
  >(
    'projectTitleEditRequest',
    generateProjectTitleEditRequestObject,
    ...config,
  );
}

export async function createThreadProject(
  ...config: Parameters<typeof generateThreadProjectObject>
): Promise<ReturnType<typeof generateThreadProjectObject>> {
  return createDoc<
    ThreadProjectsCollection,
    typeof generateThreadProjectObject
  >('threadProjects', generateThreadProjectObject, ...config);
}

export async function createProjectViewProject(
  ...config: Parameters<typeof generateProjectViewProjectObject>
): Promise<ReturnType<typeof generateProjectViewProjectObject>> {
  return createDoc<
    ProjectViewProjectsCollection,
    typeof generateProjectViewProjectObject
  >('projectViewProjects', generateProjectViewProjectObject, ...config);
}

export async function createProjectDeliveryContactSensitiveDetails(
  ...config: Parameters<typeof generateSensitiveDetailsObject>
): Promise<ReturnType<typeof generateSensitiveDetailsObject>> {
  return createDoc<
    DeliveryContactsSensitiveDetailsCollection,
    typeof generateSensitiveDetailsObject
  >(
    'deliveryContactsSensitiveDetails',
    generateSensitiveDetailsObject,
    ...config,
  );
}

export async function createMilestoneRequest(
  ...config: Parameters<typeof generateMilestoneRequestObject>
): Promise<ReturnType<typeof generateMilestoneRequestObject>> {
  return createDoc<
    MilestoneRequestsCollection,
    typeof generateMilestoneRequestObject
  >('milestoneRequests', generateMilestoneRequestObject, ...config);
}

export async function createMilestoneRequests(
  ...config: Parameters<typeof generateMilestoneRequestObjects>
): Promise<ReturnType<typeof generateMilestoneRequestObjects>> {
  return createDoc<
    MilestoneRequestsCollection,
    typeof generateMilestoneRequestObjects
  >('milestoneRequests', generateMilestoneRequestObjects, ...config);
}

export async function createProjectViewUser(
  ...config: Parameters<typeof generateProjectViewUserObject>
): Promise<ReturnType<typeof generateProjectViewUserObject>> {
  return createDoc<
    ProjectViewUsersCollection,
    typeof generateProjectViewUserObject
  >('projectViewUsers', generateProjectViewUserObject, ...config);
}

export async function createPublication(
  ...config: Parameters<typeof generatePublicationObject>
): Promise<ReturnType<typeof generatePublicationObject>> {
  return createDoc<PublicationsCollection, typeof generatePublicationObject>(
    'publications',
    generatePublicationObject,
    ...config,
  );
}

export async function createReview(
  ...config: Parameters<typeof generateReviewObject>
): Promise<ReturnType<typeof generateReviewObject>> {
  return createDoc<ReviewsCollection, typeof generateReviewObject>(
    'reviews',
    generateReviewObject,
    ...config,
  );
}

export async function createReviews(
  ...config: Parameters<typeof generateReviewObjects>
): Promise<ReturnType<typeof generateReviewObjects>> {
  return createDoc<ReviewsCollection, typeof generateReviewObjects>(
    'reviews',
    generateReviewObjects,
    ...config,
  );
}

export async function createReviewSkill(
  ...config: Parameters<typeof generateReviewSkillObject>
): Promise<ReturnType<typeof generateReviewSkillObject>> {
  return createDoc<ReviewsSkillsCollection, typeof generateReviewSkillObject>(
    'reviewsSkills',
    generateReviewSkillObject,
    ...config,
  );
}

export async function createReferralInvitationCheck(
  ...config: Parameters<typeof generateReferralInvitationCheckObject>
): Promise<ReturnType<typeof generateReferralInvitationCheckObject>> {
  return createDoc<
    ReferralInvitationCheckCollection,
    typeof generateReferralInvitationCheckObject
  >(
    'referralInvitationCheck',
    generateReferralInvitationCheckObject,
    ...config,
  );
}

export async function createReferralTokenObject(
  ...config: Parameters<typeof generateReferralTokenObject>
): Promise<ReturnType<typeof generateReferralTokenObject>> {
  return createDoc<ReferralTokenCollection, typeof generateReferralTokenObject>(
    'referralToken',
    generateReferralTokenObject,
    ...config,
  );
}

export async function createSimilarFreelancers(
  ...config: Parameters<typeof generateSimilarFreelancersObject>
): Promise<ReturnType<typeof generateSimilarFreelancersObject>> {
  return createDoc<
    SimilarFreelancersCollection,
    typeof generateSimilarFreelancersObject
  >('similarFreelancers', generateSimilarFreelancersObject, ...config);
}

export async function createSimilarShowcases(
  ...config: Parameters<typeof generateSimilarShowcasesObject>
): Promise<ReturnType<typeof generateSimilarShowcasesObject>> {
  return createDoc<
    SimilarShowcasesCollection,
    typeof generateSimilarShowcasesObject
  >('similarShowcases', generateSimilarShowcasesObject, ...config);
}

export async function createSiteStat(
  ...config: Parameters<typeof generateSiteStatsObject>
): Promise<ReturnType<typeof generateSiteStatsObject>> {
  return createDoc<SiteStatsCollection, typeof generateSiteStatsObject>(
    'siteStats',
    generateSiteStatsObject,
    ...config,
  );
}

export async function createLoadPriceEstimate(
  ...config: Parameters<typeof generateLoadPriceEstimateObject>
): Promise<ReturnType<typeof generateLoadPriceEstimateObject>> {
  return createDoc<
    LoadPriceEstimateCollection,
    typeof generateLoadPriceEstimateObject
  >('loadPriceEstimate', generateLoadPriceEstimateObject, ...config);
}

export async function createSuggestedSkills(
  ...config: Parameters<typeof generateSuggestedSkillsObject>
): Promise<ReturnType<typeof generateSuggestedSkillsObject>> {
  return createDoc<
    SuggestedSkillsCollection,
    typeof generateSuggestedSkillsObject
  >('suggestedSkills', generateSuggestedSkillsObject, ...config);
}

export async function createSearchActiveContest(
  ...config: Parameters<typeof generateSearchActiveContestObject>
): Promise<ReturnType<typeof generateSearchActiveContestObject>> {
  return createDoc<
    SearchActiveContestsCollection,
    typeof generateSearchActiveContestObject
  >('searchActiveContests', generateSearchActiveContestObject, ...config);
}

export async function createSearchActiveContests(
  ...config: Parameters<typeof generateSearchActiveContestObjects>
): Promise<ReturnType<typeof generateSearchActiveContestObjects>> {
  return createDoc<
    SearchActiveContestsCollection,
    typeof generateSearchActiveContestObjects
  >('searchActiveContests', generateSearchActiveContestObjects, ...config);
}

export async function createSearchActiveProject(
  ...config: Parameters<typeof generateSearchActiveProjectObject>
): Promise<ReturnType<typeof generateSearchActiveProjectObject>> {
  return createDoc<
    SearchActiveProjectsCollection,
    typeof generateSearchActiveProjectObject
  >('searchActiveProjects', generateSearchActiveProjectObject, ...config);
}

export async function createSearchActiveProjects(
  ...config: Parameters<typeof generateSearchActiveProjectObjects>
): Promise<ReturnType<typeof generateSearchActiveProjectObjects>> {
  return createDoc<
    SearchActiveProjectsCollection,
    typeof generateSearchActiveProjectObjects
  >('searchActiveProjects', generateSearchActiveProjectObjects, ...config);
}

export async function createSearchUser(
  ...config: Parameters<typeof generateSearchUsersObject>
): Promise<ReturnType<typeof generateSearchUsersObject>> {
  return createDoc<SearchUsersCollection, typeof generateSearchUsersObject>(
    'searchUsers',
    generateSearchUsersObject,
    ...config,
  );
}

export async function createSearchSavedFilters(
  ...config: Parameters<typeof generateSearchSavedFiltersObject>
): Promise<ReturnType<typeof generateSearchSavedFiltersObject>> {
  return createDoc<
    SearchSavedFiltersCollection,
    typeof generateSearchSavedFiltersObject
  >('searchSavedFilters', generateSearchSavedFiltersObject, ...config);
}

export async function createSearchProjectsSelf(
  ...config: Parameters<typeof generateSearchProjectsSelfObject>
): Promise<ReturnType<typeof generateSearchProjectsSelfObject>> {
  return createDoc<
    SearchProjectsSelfCollection,
    typeof generateSearchProjectsSelfObject
  >('searchProjectsSelf', generateSearchProjectsSelfObject, ...config);
}

export async function createSuperuserProjectViewAllProject(
  ...config: Parameters<typeof generateSuperuserProjectViewAllProjectObject>
): Promise<ReturnType<typeof generateSuperuserProjectViewAllProjectObject>> {
  return createDoc<
    SuperuserProjectViewAllProjectsCollection,
    typeof generateSuperuserProjectViewAllProjectObject
  >(
    'superuserProjectViewAllProjects',
    generateSuperuserProjectViewAllProjectObject,
    ...config,
  );
}

export async function createSuperuserProjectViewProject(
  ...config: Parameters<typeof generateSuperuserProjectViewProjectObject>
): Promise<ReturnType<typeof generateSuperuserProjectViewProjectObject>> {
  return createDoc<
    SuperuserProjectViewProjectsCollection,
    typeof generateSuperuserProjectViewProjectObject
  >(
    'superuserProjectViewProjects',
    generateSuperuserProjectViewProjectObject,
    ...config,
  );
}

export async function createSuperuserProjectViewUser(
  ...config: Parameters<typeof generateSuperuserProjectViewUserObject>
): Promise<ReturnType<typeof generateSuperuserProjectViewUserObject>> {
  return createDoc<
    SuperuserProjectViewUsersCollection,
    typeof generateSuperuserProjectViewUserObject
  >(
    'superuserProjectViewUsers',
    generateSuperuserProjectViewUserObject,
    ...config,
  );
}

export async function createSuperuserBids(
  ...config: Parameters<typeof generateBidObjects>
): Promise<ReturnType<typeof generateBidObjects>> {
  return createDoc<SuperuserBidsCollection, typeof generateBidObjects>(
    'superuserBids',
    generateBidObjects,
    ...config,
  );
}

export async function createTaskGroup(
  ...config: Parameters<typeof generateTaskGroupObject>
): Promise<ReturnType<typeof generateTaskGroupObject>> {
  return createDoc<TaskGroupCollection, typeof generateTaskGroupObject>(
    'taskGroup',
    generateTaskGroupObject,
    ...config,
  );
}

export async function createTaskGroupSection(
  ...config: Parameters<typeof generateTaskGroupSectionObject>
): Promise<ReturnType<typeof generateTaskGroupSectionObject>> {
  return createDoc<
    TaskGroupSectionCollection,
    typeof generateTaskGroupSectionObject
  >('taskGroupSection', generateTaskGroupSectionObject, ...config);
}

export async function createTaskGroupSections(
  ...config: Parameters<typeof generateTaskGroupSectionObjects>
): Promise<ReturnType<typeof generateTaskGroupSectionObjects>> {
  return createDoc<
    TaskGroupSectionCollection,
    typeof generateTaskGroupSectionObjects
  >('taskGroupSection', generateTaskGroupSectionObjects, ...config);
}

export async function createTask(
  ...config: Parameters<typeof generateTaskObject>
): Promise<ReturnType<typeof generateTaskObject>> {
  return createDoc<TasksCollection, typeof generateTaskObject>(
    'tasks',
    generateTaskObject,
    ...config,
  );
}

export async function createTaskGroupMembers(
  ...config: Parameters<typeof generateTaskGroupMembersObject>
): Promise<ReturnType<typeof generateTaskGroupMembersObject>> {
  return createDoc<
    TaskGroupMembersCollection,
    typeof generateTaskGroupMembersObject
  >('taskGroupMembers', generateTaskGroupMembersObject, ...config);
}

export async function createTax(
  ...config: Parameters<typeof generateTaxObject>
): Promise<ReturnType<typeof generateTaxObject>> {
  return createDoc<TaxCollection, typeof generateTaxObject>(
    'tax',
    generateTaxObject,
    ...config,
  );
}

export async function createTeam(
  ...config: Parameters<typeof generateTeamObject>
): Promise<ReturnType<typeof generateTeamObject>> {
  return createDoc<TeamsCollection, typeof generateTeamObject>(
    'teams',
    generateTeamObject,
    ...config,
  );
}

export async function createTeamMember(
  ...config: Parameters<typeof generateTeamMemberObject>
): Promise<ReturnType<typeof generateTeamMemberObject>> {
  return createDoc<TeamMembersCollection, typeof generateTeamMemberObject>(
    'teamMembers',
    generateTeamMemberObject,
    ...config,
  );
}

export async function createTeamMembers(
  ...config: Parameters<typeof generateTeamMemberObjects>
): Promise<ReturnType<typeof generateTeamMemberObjects>> {
  return createDoc<TeamMembersCollection, typeof generateTeamMemberObjects>(
    'teamMembers',
    generateTeamMemberObjects,
    ...config,
  );
}

export async function createTimeTrackingPunches(
  ...config: Parameters<typeof generateTimeTrackingPunches>
): Promise<ReturnType<typeof generateTimeTrackingPunches>> {
  return createDoc<
    TimeTrackingPunchesCollection,
    typeof generateTimeTrackingPunches
  >('timeTrackingPunches', generateTimeTrackingPunches, ...config);
}

export async function createTimeTrackingPunch(
  ...config: Parameters<typeof generateTimeTrackingPunch>
): Promise<ReturnType<typeof generateTimeTrackingPunch>> {
  return createDoc<
    TimeTrackingPunchesCollection,
    typeof generateTimeTrackingPunch
  >('timeTrackingPunches', generateTimeTrackingPunch, ...config);
}

export async function createTimeTrackingSession(
  ...config: Parameters<typeof generateTimeTrackingSession>
): Promise<ReturnType<typeof generateTimeTrackingSession>> {
  return createDoc<
    TimeTrackingSessionCollection,
    typeof generateTimeTrackingSession
  >('timeTrackingSession', generateTimeTrackingSession, ...config);
}

export async function createThread(
  ...config: Parameters<typeof generateThread>
): Promise<ReturnType<typeof generateThread>> {
  return createDoc<ThreadsCollection, typeof generateThread>(
    'threads',
    generateThread,
    ...config,
  );
}

export async function createUserAvailability(
  ...config: Parameters<typeof generateUserAvailabilityObject>
): Promise<ReturnType<typeof generateUserAvailabilityObject>> {
  return createDoc<
    UserAvailabilitiesCollection,
    typeof generateUserAvailabilityObject
  >('userAvailabilities', generateUserAvailabilityObject, ...config);
}

export async function createUserBidLimit(
  ...config: Parameters<typeof generateUserBidLimitObject>
): Promise<ReturnType<typeof generateUserBidLimitObject>> {
  return createDoc<UserBidLimitCollection, typeof generateUserBidLimitObject>(
    'userBidLimit',
    generateUserBidLimitObject,
    ...config,
  );
}

export async function createUserEmailRequest(
  ...config: Parameters<typeof generateUserEmailRequestObject>
): Promise<ReturnType<typeof generateUserEmailRequestObject>> {
  return createDoc<
    UserEmailRequestCollection,
    typeof generateUserEmailRequestObject
  >('userEmailRequest', generateUserEmailRequestObject, ...config);
}

export async function createUserFollow(
  ...config: Parameters<typeof generateUserFollowObject>
): Promise<ReturnType<typeof generateUserFollowObject>> {
  return createDoc<UsersFollowCollection, typeof generateUserFollowObject>(
    'usersFollow',
    generateUserFollowObject,
    ...config,
  );
}

export async function createUserGiveGetDetail(
  ...config: Parameters<typeof generateUserGiveGetDetailsObject>
): Promise<ReturnType<typeof generateUserGiveGetDetailsObject>> {
  return createDoc<
    UserGiveGetDetailsCollection,
    typeof generateUserGiveGetDetailsObject
  >('userGiveGetDetails', generateUserGiveGetDetailsObject, ...config);
}

export async function createUserHasDuplicateIp(
  ...config: Parameters<typeof generateUserHasDuplicateIpObject>
): Promise<ReturnType<typeof generateUserHasDuplicateIpObject>> {
  return createDoc<
    UserHasDuplicateIpCollection,
    typeof generateUserHasDuplicateIpObject
  >('userHasDuplicateIp', generateUserHasDuplicateIpObject, ...config);
}

export async function createUserHasGivenFeedback(
  ...config: Parameters<typeof generateUserHasGivenFeedbackObject>
): Promise<ReturnType<typeof generateUserHasGivenFeedbackObject>> {
  return createDoc<
    UserHasGivenFeedbackCollection,
    typeof generateUserHasGivenFeedbackObject
  >('userHasGivenFeedback', generateUserHasGivenFeedbackObject, ...config);
}

export async function createUserInfo(
  ...config: Parameters<typeof generateUserInfoObject>
): Promise<ReturnType<typeof generateUserInfoObject>> {
  return createDoc<UserInfoCollection, typeof generateUserInfoObject>(
    'userInfo',
    generateUserInfoObject,
    ...config,
  );
}

export async function createUserInsignias(
  ...config: Parameters<typeof generateUserInsignias>
): Promise<ReturnType<typeof generateUserInsignias>> {
  return createDoc<UserInsigniasCollection, typeof generateUserInsignias>(
    'userInsignias',
    generateUserInsignias,
    ...config,
  );
}

export async function createUserLocation(
  ...config: Parameters<typeof generateUsersLocationObject>
): Promise<ReturnType<typeof generateUsersLocationObject>> {
  return createDoc<UsersLocationCollection, typeof generateUsersLocationObject>(
    'usersLocation',
    generateUsersLocationObject,
    ...config,
  );
}

export async function createUserRequiresGdpr(
  ...config: Parameters<typeof generateUserRequiresGdprObject>
): Promise<ReturnType<typeof generateUserRequiresGdprObject>> {
  return createDoc<
    UserRequiresGdprCollection,
    typeof generateUserRequiresGdprObject
  >('userRequiresGdpr', generateUserRequiresGdprObject, ...config);
}

export async function createUserRequiresPaymentVerification(
  ...config: Parameters<typeof generateUserRequiresPaymentVerificationObject>
): Promise<ReturnType<typeof generateUserRequiresPaymentVerificationObject>> {
  return createDoc<
    UserRequiresPaymentVerificationCollection,
    typeof generateUserRequiresPaymentVerificationObject
  >(
    'userRequiresPaymentVerification',
    generateUserRequiresPaymentVerificationObject,
    ...config,
  );
}

export async function createUserRequiresPhoneVerification(
  ...config: Parameters<typeof generateUserRequiresPhoneVerificationObject>
): Promise<ReturnType<typeof generateUserRequiresPhoneVerificationObject>> {
  return createDoc<
    UserRequiresPhoneVerificationCollection,
    typeof generateUserRequiresPhoneVerificationObject
  >(
    'userRequiresPhoneVerification',
    generateUserRequiresPhoneVerificationObject,
    ...config,
  );
}

export async function createProjectSpamControls(
  ...config: Parameters<typeof generateProjectSpamControlsObject>
): Promise<ReturnType<typeof generateProjectSpamControlsObject>> {
  return createDoc<
    ProjectSpamControlsCollection,
    typeof generateProjectSpamControlsObject
  >('projectSpamControls', generateProjectSpamControlsObject, ...config);
}

export async function createUser(
  ...config: Parameters<typeof generateUserObject>
): Promise<ReturnType<typeof generateUserObject>> {
  return createDoc<UsersCollection, typeof generateUserObject>(
    'users',
    generateUserObject,
    ...config,
  );
}

export async function createUsersProfile(
  ...config: Parameters<typeof generateUserProfileObject>
): Promise<ReturnType<typeof generateUserProfileObject>> {
  return createDoc<UsersProfileCollection, typeof generateUserProfileObject>(
    'usersProfile',
    generateUserProfileObject,
    ...config,
  );
}

export async function createUsersSelf(
  ...config: Parameters<typeof generateUserSelfObject>
): Promise<ReturnType<typeof generateUserSelfObject>> {
  return createDoc<UsersSelfCollection, typeof generateUserSelfObject>(
    'usersSelf',
    generateUserSelfObject,
    ...config,
  );
}

export async function createUsersMfaObject(
  ...config: Parameters<typeof generateUsersMfaObject>
): Promise<ReturnType<typeof generateUsersMfaObject>> {
  return createDoc<UsersMfaCollection, typeof generateUsersMfaObject>(
    'usersMfa',
    generateUsersMfaObject,
    ...config,
  );
}

export async function createUserPaymentControl(
  ...config: Parameters<typeof generateUserPaymentControlObject>
): Promise<ReturnType<typeof generateUserPaymentControlObject>> {
  return createDoc<
    UserPaymentControlsCollection,
    typeof generateUserPaymentControlObject
  >('userPaymentControls', generateUserPaymentControlObject, ...config);
}

export async function createLoginDevicesObject(
  ...config: Parameters<typeof generateLoginDevicesObject>
): Promise<ReturnType<typeof generateLoginDevicesObject>> {
  return createDoc<LoginDevicesCollection, typeof generateLoginDevicesObject>(
    'loginDevices',
    generateLoginDevicesObject,
    ...config,
  );
}

export async function createUserTaxInfoObject(
  ...config: Parameters<typeof generateUserTaxInfoObject>
): Promise<ReturnType<typeof generateUserTaxInfoObject>> {
  return createDoc<UserTaxInfoCollection, typeof generateUserTaxInfoObject>(
    'userTaxInfo',
    generateUserTaxInfoObject,
    ...config,
  );
}

export async function createUserTaxOptOutReasonObject(
  ...config: Parameters<typeof generateUserTaxOptOutReasonObject>
): Promise<ReturnType<typeof generateUserTaxOptOutReasonObject>> {
  return createDoc<
    UserTaxOptOutReasonsCollection,
    typeof generateUserTaxOptOutReasonObject
  >('userTaxOptOutReasons', generateUserTaxOptOutReasonObject, ...config);
}

export async function createSecurityPhoneRequestObject(
  ...config: Parameters<typeof generateSecurityPhoneRequestObject>
): Promise<ReturnType<typeof generateSecurityPhoneRequestObject>> {
  return createDoc<
    SecurityPhoneRequestsCollection,
    typeof generateSecurityPhoneRequestObject
  >('securityPhoneRequests', generateSecurityPhoneRequestObject, ...config);
}

export function createInvoice(
  ...config: Parameters<typeof generateInvoiceObject>
): Promise<ReturnType<typeof generateInvoiceObject>> {
  return createDoc<InvoicesCollection, typeof generateInvoiceObject>(
    'invoices',
    generateInvoiceObject,
    ...config,
  );
}

export function createIsFreeBids(
  ...config: Parameters<typeof generateIsFreeBidsObject>
): Promise<ReturnType<typeof generateIsFreeBidsObject>> {
  return createDoc<IsFreeBidsCollection, typeof generateIsFreeBidsObject>(
    'isFreeBids',
    generateIsFreeBidsObject,
    ...config,
  );
}

export async function createUserBidOnRussianProject(
  ...config: Parameters<typeof generateUserBidOnRussianProjectsObject>
): Promise<ReturnType<typeof generateUserBidOnRussianProjectsObject>> {
  return createDoc<
    UserBidOnRussianProjectsCollection,
    typeof generateUserBidOnRussianProjectsObject
  >(
    'userBidOnRussianProjects',
    generateUserBidOnRussianProjectsObject,
    ...config,
  );
}

export function createRecentSavedSearchAlert(
  ...config: Parameters<typeof generateRecentSavedSearchAlert>
): Promise<ReturnType<typeof generateRecentSavedSearchAlert>> {
  return createDoc<
    RecentSavedSearchAlertsCollection,
    typeof generateRecentSavedSearchAlert
  >('recentSavedSearchAlerts', generateRecentSavedSearchAlert, ...config);
}

export function createAdminUserVerificationProcess(
  ...config: Parameters<typeof generateAdminUserVerificationProcessObject>
): Promise<ReturnType<typeof generateAdminUserVerificationProcessObject>> {
  return createDoc<
    AdminUserVerificationProcessesCollection,
    typeof generateAdminUserVerificationProcessObject
  >(
    'adminUserVerificationProcesses',
    generateAdminUserVerificationProcessObject,
    ...config,
  );
}

export async function createSessionResolveReason(
  ...config: Parameters<typeof generateSessionResolveReasonObject>
): Promise<ReturnType<typeof generateSessionResolveReasonObject>> {
  return createDoc<
    SessionResolveReasonsCollection,
    typeof generateSessionResolveReasonObject
  >('sessionResolveReasons', generateSessionResolveReasonObject, ...config);
}

export async function createDriveFile(
  ...config: Parameters<typeof generateDriveFileObject>
): Promise<ReturnType<typeof generateDriveFileObject>> {
  return createDoc<DriveFilesCollection, typeof generateDriveFileObject>(
    'driveFiles',
    generateDriveFileObject,
    ...config,
  );
}

export async function createDriveFilePermission(
  ...config: Parameters<typeof generateDriveFilePermissionObject>
): Promise<ReturnType<typeof generateDriveFilePermissionObject>> {
  return createDoc<
    DriveFilePermissionsCollection,
    typeof generateDriveFilePermissionObject
  >('driveFilePermissions', generateDriveFilePermissionObject, ...config);
}

export async function createSuperuserMilestone(
  ...config: Parameters<typeof generateSuperuserMilestoneObject>
): Promise<ReturnType<typeof generateSuperuserMilestoneObject>> {
  return createDoc<
    SuperuserMilestonesCollection,
    typeof generateSuperuserMilestoneObject
  >('superuserMilestones', generateSuperuserMilestoneObject, ...config);
}

export async function createSuperuserGroupPermissions(
  ...config: Parameters<typeof generateSuperuserGroupPermissionsObject>
): Promise<ReturnType<typeof generateSuperuserGroupPermissionsObject>> {
  return createDoc<
    SuperuserGroupPermissionsCollection,
    typeof generateSuperuserGroupPermissionsObject
  >(
    'superuserGroupPermissions',
    generateSuperuserGroupPermissionsObject,
    ...config,
  );
}

export async function createSuperuserGroupMembers(
  ...config: Parameters<typeof generateSuperuserGroupMemberObject>
): Promise<ReturnType<typeof generateSuperuserGroupMemberObject>> {
  return createDoc<
    SuperuserGroupMembersCollection,
    typeof generateSuperuserGroupMemberObject
  >('superuserGroupMembers', generateSuperuserGroupMemberObject, ...config);
}

export function createCustomFieldRelationship(
  ...config: Parameters<typeof generateCustomFieldRelationshipObject>
): Promise<ReturnType<typeof generateCustomFieldRelationshipObject>> {
  return createDoc<
    CustomFieldRelationshipsCollection,
    typeof generateCustomFieldRelationshipObject
  >(
    'customFieldRelationships',
    generateCustomFieldRelationshipObject,
    ...config,
  );
}

export function createCustomFieldValidation(
  ...config: Parameters<typeof generateCustomFieldValidation>
): Promise<ReturnType<typeof generateCustomFieldValidation>> {
  return createDoc<
    CustomFieldValidationsCollection,
    typeof generateCustomFieldValidation
  >('customFieldValidations', generateCustomFieldValidation, ...config);
}

export async function createArticle(
  ...config: Parameters<typeof generateArticleObject>
): Promise<ReturnType<typeof generateArticleObject>> {
  return createDoc<ArticlesCollection, typeof generateArticleObject>(
    'articles',
    generateArticleObject,
    ...config,
  );
}

export function createUserNps(
  ...config: Parameters<typeof generateUserNpsObject>
): Promise<ReturnType<typeof generateUserNpsObject>> {
  return createDoc<UserNpsCollection, typeof generateUserNpsObject>(
    'userNps',
    generateUserNpsObject,
    ...config,
  );
}

export function createAccountProgress(
  ...config: Parameters<typeof generateAccountProgressObject>
): Promise<ReturnType<typeof generateAccountProgressObject>> {
  return createDoc<
    AccountProgressCollection,
    typeof generateAccountProgressObject
  >('accountProgress', generateAccountProgressObject, ...config);
}

export async function createDashboardPoll(
  ...config: Parameters<typeof generateDashboardPollObject>
): Promise<ReturnType<typeof generateDashboardPollObject>> {
  return createDoc<
    DashboardPollsCollection,
    typeof generateDashboardPollObject
  >('dashboardPolls', generateDashboardPollObject, ...config);
}

export async function createVideoVerificationRequest(
  ...config: Parameters<typeof generateVideoVerificationRequestObject>
): Promise<ReturnType<typeof generateVideoVerificationRequestObject>> {
  return createDoc<
    VideoVerificationRequestCollection,
    typeof generateVideoVerificationRequestObject
  >(
    'videoVerificationRequest',
    generateVideoVerificationRequestObject,
    ...config,
  );
}

export async function createMarketingMobileNumber(
  ...config: Parameters<typeof generateMarketingMobileNumberObject>
): Promise<ReturnType<typeof generateMarketingMobileNumberObject>> {
  return createDoc(
    'marketingMobileNumber',
    generateMarketingMobileNumberObject,
    ...config,
  );
}

export async function createTimeline(
  ...config: Parameters<typeof generateTimelineObject>
): Promise<ReturnType<typeof generateTimelineObject>> {
  return createDoc<TimelinesCollection, typeof generateTimelineObject>(
    'timelines',
    generateTimelineObject,
    ...config,
  );
}

export async function createReferralInvitation(
  ...config: Parameters<typeof generateReferralInvitationObject>
): Promise<ReturnType<typeof generateReferralInvitationObject>> {
  return createDoc<
    ReferralInvitationsCollection,
    typeof generateReferralInvitationObject
  >('referralInvitations', generateReferralInvitationObject, ...config);
}

export async function createUserPhoneVerificationBackoff(
  ...config: Parameters<typeof generateUserPhoneVerificationBackoffObject>
): Promise<ReturnType<typeof generateUserPhoneVerificationBackoffObject>> {
  return createDoc<
    UserPhoneVerificationBackoffCollection,
    typeof generateUserPhoneVerificationBackoffObject
  >(
    'userPhoneVerificationBackoff',
    generateUserPhoneVerificationBackoffObject,
    ...config,
  );
}

export async function createCategoryPageViewUser(
  ...config: Parameters<typeof generateCategoryPageViewUserObject>
): Promise<ReturnType<typeof generateCategoryPageViewUserObject>> {
  return createDoc<
    CategoryPageViewUsersCollection,
    typeof generateCategoryPageViewUserObject
  >('categoryPageViewUsers', generateCategoryPageViewUserObject, ...config);
}

export async function createDiscoverCollectionItemSaveCount(
  ...config: Parameters<typeof generateDiscoverCollectionItemSaveCountObject>
): Promise<ReturnType<typeof generateDiscoverCollectionItemSaveCountObject>> {
  return createDoc<
    DiscoverCollectionItemSaveCountCollection,
    typeof generateDiscoverCollectionItemSaveCountObject
  >(
    'discoverCollectionItemSaveCount',
    generateDiscoverCollectionItemSaveCountObject,
    ...config,
  );
}

export async function createDiscoverCollection(
  ...config: Parameters<typeof generateDiscoverCollectionObject>
): Promise<ReturnType<typeof generateDiscoverCollectionObject>> {
  return createDoc<
    DiscoverCollectionsCollection,
    typeof generateDiscoverCollectionObject
  >('discoverCollections', generateDiscoverCollectionObject, ...config);
}

export async function createSupportFeedbackIsAllowed(
  ...config: Parameters<typeof generateSupportFeedbackIsAllowedObject>
): Promise<ReturnType<typeof generateSupportFeedbackIsAllowedObject>> {
  return createDoc<
    SupportFeedbackIsAllowedCollection,
    typeof generateSupportFeedbackIsAllowedObject
  >(
    'supportFeedbackIsAllowed',
    generateSupportFeedbackIsAllowedObject,
    ...config,
  );
}

export async function createSlaTask(
  ...config: Parameters<typeof generateSlaTaskObject>
): Promise<ReturnType<typeof generateSlaTaskObject>> {
  return createDoc<SlaTasksCollection, typeof generateSlaTaskObject>(
    'slaTasks',
    generateSlaTaskObject,
    ...config,
  );
}

export async function createUserCartRequiresEmailVerification(
  ...config: Parameters<typeof generateUserCartRequiresEmailVerificationObject>
): Promise<ReturnType<typeof generateUserCartRequiresEmailVerificationObject>> {
  return createDoc<
    UserCartRequiresEmailVerificationCollection,
    typeof generateUserCartRequiresEmailVerificationObject
  >(
    'userCartRequiresEmailVerification',
    generateUserCartRequiresEmailVerificationObject,
    ...config,
  );
}

// #endregion Single object creation functions. These should all be singular.

// #region Multiple object creation functions. These should all be plural.

export async function createBanners(
  ...config: Parameters<typeof generateBannerObjects>
): Promise<ReturnType<typeof generateBannerObjects>> {
  return createDoc<BannersCollection, typeof generateBannerObjects>(
    'banners',
    generateBannerObjects,
    ...config,
  );
}

export async function createBid(
  ...config: Parameters<typeof generateBidObject>
): Promise<ReturnType<typeof generateBidObject>> {
  return createDoc<BidsCollection, typeof generateBidObject>(
    'bids',
    generateBidObject,
    ...config,
  );
}

export async function createBids(
  ...config: Parameters<typeof generateBidObjects>
): Promise<ReturnType<typeof generateBidObjects>> {
  return createDoc<BidsCollection, typeof generateBidObjects>(
    'bids',
    generateBidObjects,
    ...config,
  );
}

export async function createProjectCollaboration(
  ...config: Parameters<typeof generateProjectCollaborationObject>
): Promise<ReturnType<typeof generateProjectCollaborationObject>> {
  return createDoc<
    ProjectCollaborationsCollection,
    typeof generateProjectCollaborationObject
  >('projectCollaborations', generateProjectCollaborationObject, ...config);
}

export async function createProjectCollaborations(
  ...config: Parameters<typeof generateProjectCollaborationObjects>
): Promise<ReturnType<typeof generateProjectCollaborationObjects>> {
  return createDoc<
    ProjectCollaborationsCollection,
    typeof generateProjectCollaborationObjects
  >('projectCollaborations', generateProjectCollaborationObjects, ...config);
}

export async function createContestEntries(
  ...config: Parameters<typeof generateContestEntryObjects>
): Promise<ReturnType<typeof generateContestEntryObjects>> {
  return createDoc<
    ContestEntriesCollection,
    typeof generateContestEntryObjects
  >('contestEntries', generateContestEntryObjects, ...config);
}

export async function createContestViewEntryIds(
  ...config: Parameters<typeof generateContestViewEntryIdsObject>
): Promise<ReturnType<typeof generateContestViewEntryIdsObject>> {
  return createDoc<
    ContestViewEntryIdsCollection,
    typeof generateContestViewEntryIdsObject
  >('contestViewEntryIds', generateContestViewEntryIdsObject, ...config);
}

export async function createContestFees(
  ...config: Parameters<typeof generateContestFeesObject>
): Promise<ReturnType<typeof generateContestFeesObject>> {
  return createDoc<ContestFeesCollection, typeof generateContestFeesObject>(
    'contestFees',
    generateContestFeesObject,
    ...config,
  );
}

export async function createContestFiles(
  ...config: Parameters<typeof generateContestFileObjects>
): Promise<ReturnType<typeof generateContestFileObjects>> {
  return createDoc<ContestFilesCollection, typeof generateContestFileObjects>(
    'contestFiles',
    generateContestFileObjects,
    ...config,
  );
}

export async function createContestInterestedUser(
  ...config: Parameters<typeof generateContestInterestedUserObject>
): Promise<ReturnType<typeof generateContestInterestedUserObject>> {
  return createDoc<
    ContestInterestedUsersCollection,
    typeof generateContestInterestedUserObject
  >('contestInterestedUsers', generateContestInterestedUserObject, ...config);
}

export async function createContestQuickviewEntries(
  ...config: Parameters<typeof generateContestQuickviewEntryObjects>
): Promise<ReturnType<typeof generateContestQuickviewEntryObjects>> {
  return createDoc<
    ContestQuickviewEntriesCollection,
    typeof generateContestQuickviewEntryObjects
  >('contestQuickviewEntries', generateContestQuickviewEntryObjects, ...config);
}

export async function createContestViewEntries(
  ...config: Parameters<typeof generateContestViewEntryObjects>
): Promise<ReturnType<typeof generateContestViewEntryObjects>> {
  return createDoc<
    ContestViewEntriesCollection,
    typeof generateContestViewEntryObjects
  >('contestViewEntries', generateContestViewEntryObjects, ...config);
}

export async function createContestBudgetRanges(
  ...config: Parameters<typeof generateContestBudgetRangesObjects>
): Promise<ReturnType<typeof generateContestBudgetRangesObjects>> {
  return createDoc<
    ContestBudgetRangesCollection,
    typeof generateContestBudgetRangesObjects
  >('contestBudgetRanges', generateContestBudgetRangesObjects, ...config);
}

export async function createCountries(
  ...config: Parameters<typeof generateCountryObjects>
): Promise<ReturnType<typeof generateCountryObjects>> {
  return createDoc<CountriesCollection, typeof generateCountryObjects>(
    'countries',
    generateCountryObjects,
    ...config,
  );
}

export async function createCurrencies(
  ...config: Parameters<typeof generateCurrencyObjects>
): Promise<ReturnType<typeof generateCurrencyObjects>> {
  return createDoc<CurrenciesCollection, typeof generateCurrencyObjects>(
    'currencies',
    generateCurrencyObjects,
    ...config,
  );
}

export async function createDetectedCurrencies(
  ...config: Parameters<typeof generateCurrencyObject>
): Promise<ReturnType<typeof generateCurrencyObject>> {
  return createDoc<CurrencyDetectCollection, typeof generateCurrencyObject>(
    'currencyDetect',
    generateCurrencyObject,
    ...config,
  );
}

export async function createVehicleTypes(
  ...config: Parameters<typeof generateVehicleTypeObjects>
): Promise<ReturnType<typeof generateVehicleTypeObjects>> {
  return createDoc<EquipmentGroupCollection, typeof generateVehicleTypeObjects>(
    'equipmentGroup',
    generateVehicleTypeObjects,
    ...config,
  );
}

export async function createCurrencyIncludingExternal(
  ...config: Parameters<typeof generateCurrencyIncludingExternalObjects>
): Promise<ReturnType<typeof generateCurrencyIncludingExternalObjects>> {
  return createDoc<
    CurrenciesIncludingExternalCollection,
    typeof generateCurrencyIncludingExternalObjects
  >(
    'currenciesIncludingExternal',
    generateCurrencyIncludingExternalObjects,
    ...config,
  );
}

export async function createDashboardWidgets(
  ...config: Parameters<typeof generateDashboardWidgetObjects>
): Promise<ReturnType<typeof generateDashboardWidgetObjects>> {
  return createDoc<
    DashboardWidgetsCollection,
    typeof generateDashboardWidgetObjects
  >('dashboardWidgets', generateDashboardWidgetObjects, ...config);
}

export async function createExams(
  ...config: Parameters<typeof generateExamObjects>
): Promise<ReturnType<typeof generateExamObjects>> {
  return createDoc<ExamsCollection, typeof generateExamObjects>(
    'exams',
    generateExamObjects,
    ...config,
  );
}

export async function createDepositPageVars(
  ...config: Parameters<typeof generateDepositPageVarsObjects>
): Promise<ReturnType<typeof generateDepositPageVarsObjects>> {
  return createDoc<
    DepositPageVarsCollection,
    typeof generateDepositPageVarsObjects
  >('depositPageVars', generateDepositPageVarsObjects, ...config);
}

export async function createUserDepositMethods(
  ...config: Parameters<typeof generateUserDepositMethodsObjects>
): Promise<ReturnType<typeof generateUserDepositMethodsObjects>> {
  return createDoc<
    UserDepositMethodsCollection,
    typeof generateUserDepositMethodsObjects
  >('userDepositMethods', generateUserDepositMethodsObjects, ...config);
}

export async function createUserPayoutAccounts(
  ...config: Parameters<typeof generatePayoutAccountObjects>
): Promise<ReturnType<typeof generatePayoutAccountObjects>> {
  return createDoc<
    PayoutAccountsCollection,
    typeof generatePayoutAccountObjects
  >('payoutAccounts', generatePayoutAccountObjects, ...config);
}

export async function createUserPayoutAccountForms(
  ...config: Parameters<typeof generatePayoutAccountFormObjects>
): Promise<ReturnType<typeof generatePayoutAccountFormObjects>> {
  return createDoc<
    PayoutAccountFormCollection,
    typeof generatePayoutAccountFormObjects
  >('payoutAccountForm', generatePayoutAccountFormObjects, ...config);
}

export async function createUserPayoutContext(
  ...config: Parameters<typeof generatePayoutContextObjects>
): Promise<ReturnType<typeof generatePayoutContextObjects>> {
  return createDoc<
    PayoutContextCollection,
    typeof generatePayoutContextObjects
  >('payoutContext', generatePayoutContextObjects, ...config);
}

export async function createUserPayoutMethods(
  ...config: Parameters<typeof generatePayoutMethodObjects>
): Promise<ReturnType<typeof generatePayoutMethodObjects>> {
  return createDoc<PayoutMethodsCollection, typeof generatePayoutMethodObjects>(
    'payoutMethods',
    generatePayoutMethodObjects,
    ...config,
  );
}

export async function createExchangeRates(
  ...config: Parameters<typeof generateExchangeRatesObject>
): Promise<ReturnType<typeof generateExchangeRatesObject>> {
  return createDoc<ExchangeRatesCollection, typeof generateExchangeRatesObject>(
    'exchangeRates',
    generateExchangeRatesObject,
    ...config,
  );
}

export async function createNativeExchangeRates(
  ...config: Parameters<typeof generateNativeExchangeRatesObject>
): Promise<ReturnType<typeof generateNativeExchangeRatesObject>> {
  return createDoc<
    NativeExchangeRatesCollection,
    typeof generateNativeExchangeRatesObject
  >('nativeExchangeRates', generateNativeExchangeRatesObject, ...config);
}

export async function createFreelancerReputations(
  ...config: Parameters<typeof generateFreelancerReputationsObjects>
): Promise<ReturnType<typeof generateFreelancerReputationsObjects>> {
  return createDoc<
    FreelancerReputationsCollection,
    typeof generateFreelancerReputationsObjects
  >('freelancerReputations', generateFreelancerReputationsObjects, ...config);
}

export async function createFreelancerReputationsRehireRates(
  ...config: Parameters<typeof generateFreelancerReputationsRehireRatesObjects>
): Promise<ReturnType<typeof generateFreelancerReputationsRehireRatesObjects>> {
  return createDoc<
    FreelancerReputationsRehireRatesCollection,
    typeof generateFreelancerReputationsRehireRatesObjects
  >(
    'freelancerReputationsRehireRates',
    generateFreelancerReputationsRehireRatesObjects,
    ...config,
  );
}

export async function createEmployerReputationsRehireRates(
  ...config: Parameters<typeof generateEmployerReputationsRehireRatesObjects>
): Promise<ReturnType<typeof generateEmployerReputationsRehireRatesObjects>> {
  return createDoc<
    EmployerReputationsRehireRatesCollection,
    typeof generateEmployerReputationsRehireRatesObjects
  >(
    'employerReputationsRehireRates',
    generateEmployerReputationsRehireRatesObjects,
    ...config,
  );
}

export async function createManageViewOpenBids(
  ...config: Parameters<typeof generateManageViewOpenBidsObjects>
): Promise<ReturnType<typeof generateManageViewOpenBidsObjects>> {
  return createDoc<
    ManageViewOpenBidsCollection,
    typeof generateManageViewOpenBidsObjects
  >('manageViewOpenBids', generateManageViewOpenBidsObjects, ...config);
}

export async function createManageViewOngoingBids(
  ...config: Parameters<typeof generateManageViewOngoingBidObjects>
): Promise<ReturnType<typeof generateManageViewOngoingBidObjects>> {
  return createDoc<
    ManageViewOngoingBidsCollection,
    typeof generateManageViewOngoingBidObjects
  >('manageViewOngoingBids', generateManageViewOngoingBidObjects, ...config);
}

export async function createManageViewOngoingProjects(
  ...config: Parameters<typeof generateManageViewOngoingProjectsObject>
): Promise<ReturnType<typeof generateManageViewOngoingProjectsObject>> {
  return createDoc<
    ManageViewOngoingProjectsCollection,
    typeof generateManageViewOngoingProjectsObject
  >(
    'manageViewOngoingProjects',
    generateManageViewOngoingProjectsObject,
    ...config,
  );
}

export async function createMessages(
  ...config: Parameters<typeof generateMessages>
): Promise<ReturnType<typeof generateMessages>> {
  return createDoc<MessagesCollection, typeof generateMessages>(
    'messages',
    generateMessages,
    ...config,
  );
}

export async function createNotificationsPreferences(
  ...config: Parameters<typeof generateNotificationPreferenceObjects>
): Promise<ReturnType<typeof generateNotificationPreferenceObjects>> {
  return createDoc<
    NotificationsPreferencesCollection,
    typeof generateNotificationPreferenceObjects
  >(
    'notificationsPreferences',
    generateNotificationPreferenceObjects,
    ...config,
  );
}

export async function createOnlineOffline(
  ...config: Parameters<typeof generateOnlineOfflineObjects>
): Promise<ReturnType<typeof generateOnlineOfflineObjects>> {
  return createDoc<
    OnlineOfflineCollection,
    typeof generateOnlineOfflineObjects
  >('onlineOffline', generateOnlineOfflineObjects, ...config);
}

export async function createPools(
  ...config: Parameters<typeof generatePoolObjects>
): Promise<ReturnType<typeof generatePoolObjects>> {
  return createDoc<PoolsCollection, typeof generatePoolObjects>(
    'pools',
    generatePoolObjects,
    ...config,
  );
}

export async function createAdminPools(
  ...config: Parameters<typeof generateAdminPoolObjects>
): Promise<ReturnType<typeof generateAdminPoolObjects>> {
  return createDoc<AdminPoolsCollection, typeof generateAdminPoolObjects>(
    'adminPools',
    generateAdminPoolObjects,
    ...config,
  );
}

export async function createAgent(
  ...config: Parameters<typeof generateAgentObject>
): Promise<ReturnType<typeof generateAgentObject>> {
  return createDoc<AgentsCollection, typeof generateAgentObject>(
    'agents',
    generateAgentObject,
    ...config,
  );
}

export async function createAgents(
  ...config: Parameters<typeof generateAgentObjects>
): Promise<ReturnType<typeof generateAgentObjects>> {
  return createDoc<AgentsCollection, typeof generateAgentObjects>(
    'agents',
    generateAgentObjects,
    ...config,
  );
}

export async function createAgentSession(
  ...config: Parameters<typeof generateAgentSessionObject>
): Promise<ReturnType<typeof generateAgentSessionObject>> {
  return createDoc<AgentSessionsCollection, typeof generateAgentSessionObject>(
    'agentSessions',
    generateAgentSessionObject,
    ...config,
  );
}

export async function createAgentSessions(
  ...config: Parameters<typeof generateAgentSessionObjects>
): Promise<ReturnType<typeof generateAgentSessionObjects>> {
  return createDoc<AgentSessionsCollection, typeof generateAgentSessionObjects>(
    'agentSessions',
    generateAgentSessionObjects,
    ...config,
  );
}

export async function createSupportTag(
  ...config: Parameters<typeof generateSupportTagObject>
): Promise<ReturnType<typeof generateSupportTagObject>> {
  return createDoc<SupportTagsCollection, typeof generateSupportTagObject>(
    'supportTags',
    generateSupportTagObject,
    ...config,
  );
}

export async function createAiPrompt(
  ...config: Parameters<typeof generateAiPromptObject>
): Promise<ReturnType<typeof generateAiPromptObject>> {
  return createDoc<AiPromptsCollection, typeof generateAiPromptObject>(
    'aiPrompts',
    generateAiPromptObject,
    ...config,
  );
}

export async function createSalesTax(
  ...config: Parameters<typeof generateSalesTaxObject>
): Promise<ReturnType<typeof generateSalesTaxObject>> {
  return createDoc<SalesTaxCollection, typeof generateSalesTaxObject>(
    'salesTax',
    generateSalesTaxObject,
    ...config,
  );
}

/* This actually creates a list of portfolio items, not multiple portfolios */
export async function createPortfolios(
  ...config: Parameters<typeof generatePortfolioObjects>
): Promise<ReturnType<typeof generatePortfolioObjects>> {
  return createDoc<PortfoliosCollection, typeof generatePortfolioObjects>(
    'portfolios',
    generatePortfolioObjects,
    ...config,
  );
}

export async function createPredictedSkill(
  ...config: Parameters<typeof generatePredictedSkillsObject>
): Promise<ReturnType<typeof generatePredictedSkillsObject>> {
  return createDoc<
    PredictedSkillsCollection,
    typeof generatePredictedSkillsObject
  >('predictedSkills', generatePredictedSkillsObject, ...config);
}

export async function createPreferredBillingAgreement(
  ...config: Parameters<typeof generatePreferredBillingAgreementObject>
): Promise<ReturnType<typeof generatePreferredBillingAgreementObject>> {
  return createDoc<
    PreferredBillingAgreementsCollection,
    typeof generatePreferredBillingAgreementObject
  >(
    'preferredBillingAgreements',
    generatePreferredBillingAgreementObject,
    ...config,
  );
}

export async function createProfileViewUsers(
  ...config: Parameters<typeof generateProfileViewUserObjects>
): Promise<ReturnType<typeof generateProfileViewUserObjects>> {
  return createDoc<
    ProfileViewUsersCollection,
    typeof generateProfileViewUserObjects
  >('profileViewUsers', generateProfileViewUserObjects, ...config);
}

export async function createProjectBudgetOptions(
  ...config: Parameters<typeof generateProjectBudgetOptions>
): Promise<ReturnType<typeof generateProjectBudgetOptions>> {
  return createDoc<
    ProjectBudgetOptionsCollection,
    typeof generateProjectBudgetOptions
  >('projectBudgetOptions', generateProjectBudgetOptions, ...config);
}

export async function createProjectFeed(
  ...config: Parameters<typeof generateProjectFeedObjects>
): Promise<ReturnType<typeof generateProjectFeedObjects>> {
  return createDoc<ProjectFeedCollection, typeof generateProjectFeedObjects>(
    'projectFeed',
    generateProjectFeedObjects,
    ...config,
  );
}

export async function createProjectGroup(
  ...config: Parameters<typeof generateProjectGroupObject>
): Promise<ReturnType<typeof generateProjectGroupObject>> {
  return createDoc<ProjectGroupsCollection, typeof generateProjectGroupObject>(
    'projectGroups',
    generateProjectGroupObject,
    ...config,
  );
}

export async function createProjectGroups(
  ...config: Parameters<typeof generateProjectGroupObjects>
): Promise<ReturnType<typeof generateProjectGroupObjects>> {
  return createDoc<ProjectGroupsCollection, typeof generateProjectGroupObjects>(
    'projectGroups',
    generateProjectGroupObjects,
    ...config,
  );
}

export async function createProjectQualityDetails(
  ...config: Parameters<typeof generateProjectQualityDetailsObjects>
): Promise<ReturnType<typeof generateProjectQualityDetailsObjects>> {
  return createDoc<
    ProjectQualityDetailsCollection,
    typeof generateProjectQualityDetailsObjects
  >('projectQualityDetails', generateProjectQualityDetailsObjects, ...config);
}

export async function createProjectUpgradeFees(
  ...config: Parameters<typeof generateProjectUpgradeFeeObjects>
): Promise<ReturnType<typeof generateProjectUpgradeFeeObjects>> {
  return createDoc<
    ProjectUpgradeFeesCollection,
    typeof generateProjectUpgradeFeeObjects
  >('projectUpgradeFees', generateProjectUpgradeFeeObjects, ...config);
}

export async function createProjectViewBids(
  ...config: Parameters<typeof generateProjectViewBidObjects>
): Promise<ReturnType<typeof generateProjectViewBidObjects>> {
  return createDoc<
    ProjectViewBidsCollection,
    typeof generateProjectViewBidObjects
  >('projectViewBids', generateProjectViewBidObjects, ...config);
}

export async function createBidScore(
  ...config: Parameters<typeof generateBidScoreObject>
): Promise<ReturnType<typeof generateBidScoreObject>> {
  return createDoc<BidScoresCollection, typeof generateBidScoreObject>(
    'bidScores',
    generateBidScoreObject,
    ...config,
  );
}

export async function createBidBuyerProjectFee(
  ...config: Parameters<typeof generateBidBuyerProjectFeeObject>
): Promise<ReturnType<typeof generateBidBuyerProjectFeeObject>> {
  return createDoc<
    BidBuyerProjectFeesCollection,
    typeof generateBidBuyerProjectFeeObject
  >('bidBuyerProjectFees', generateBidBuyerProjectFeeObject, ...config);
}

export async function createProjectViewUsers(
  ...config: Parameters<typeof generateProjectViewUserObjects>
): Promise<ReturnType<typeof generateProjectViewUserObjects>> {
  return createDoc<
    ProjectViewUsersCollection,
    typeof generateProjectViewUserObjects
  >('projectViewUsers', generateProjectViewUserObjects, ...config);
}

export async function createSearchUsers(
  ...config: Parameters<typeof generateSearchUsersObjects>
): Promise<ReturnType<typeof generateSearchUsersObjects>> {
  return createDoc<SearchUsersCollection, typeof generateSearchUsersObjects>(
    'searchUsers',
    generateSearchUsersObjects,
    ...config,
  );
}

export async function createShowcaseSources(
  ...config: Parameters<typeof generateShowcaseSource>
): Promise<ReturnType<typeof generateShowcaseSource>> {
  return createDoc<ShowcaseSourceCollection, typeof generateShowcaseSource>(
    'showcaseSource',
    generateShowcaseSource,
    ...config,
  );
}

export async function createSkills(
  ...config: Parameters<typeof generateSkillObjects>
): Promise<ReturnType<typeof generateSkillObjects>> {
  return createDoc<SkillsCollection, typeof generateSkillObjects>(
    'skills',
    generateSkillObjects,
    ...config,
  );
}

export async function createTopSkills(
  ...config: Parameters<typeof generateTopSkillObjects>
): Promise<ReturnType<typeof generateTopSkillObjects>> {
  return createDoc<TopSkillsCollection, typeof generateTopSkillObjects>(
    'topSkills',
    generateTopSkillObjects,
    ...config,
  );
}

export async function createUserSkills(
  ...config: Parameters<typeof generateUserSkillObject>
): Promise<ReturnType<typeof generateUserSkillObject>> {
  return createDoc<UserSkillsCollection, typeof generateUserSkillObject>(
    'userSkills',
    generateUserSkillObject,
    ...config,
  );
}

export async function createUniversities(
  ...config: Parameters<typeof generateUniversityObjects>
): Promise<ReturnType<typeof generateUniversityObjects>> {
  return createDoc<UniversitiesCollection, typeof generateUniversityObjects>(
    'universities',
    generateUniversityObjects,
    ...config,
  );
}

export async function createUserBalances(
  ...config: Parameters<typeof generateUserBalanceObjects>
): Promise<ReturnType<typeof generateUserBalanceObjects>> {
  return createDoc<UserBalancesCollection, typeof generateUserBalanceObjects>(
    'userBalances',
    generateUserBalanceObjects,
    ...config,
  );
}

export async function createUserCalifornianStatuses(
  ...config: Parameters<typeof generateUserCalifornianStatusObjects>
): Promise<ReturnType<typeof generateUserCalifornianStatusObjects>> {
  return createDoc<
    UserCalifornianStatusesCollection,
    typeof generateUserCalifornianStatusObjects
  >('userCalifornianStatuses', generateUserCalifornianStatusObjects, ...config);
}

export async function createUserQualityDetails(
  ...config: Parameters<typeof generateUserQualityDetailsObjects>
): Promise<ReturnType<typeof generateUserQualityDetailsObjects>> {
  return createDoc<
    UserQualityDetailsCollection,
    typeof generateUserQualityDetailsObjects
  >('userQualityDetails', generateUserQualityDetailsObjects, ...config);
}

export async function createUserRecentProjectsAndContests(
  ...config: Parameters<typeof generateRecentProjectsAndContestsObjects>
): Promise<ReturnType<typeof generateRecentProjectsAndContestsObjects>> {
  return createDoc<
    UserRecentProjectsAndContestsCollection,
    typeof generateRecentProjectsAndContestsObjects
  >(
    'userRecentProjectsAndContests',
    generateRecentProjectsAndContestsObjects,
    ...config,
  );
}

export async function createUsers(
  ...config: Parameters<typeof generateUserObjects>
): Promise<ReturnType<typeof generateUserObjects>> {
  return createDoc<UsersCollection, typeof generateUserObjects>(
    'users',
    generateUserObjects,
    ...config,
  );
}

export async function createOnBehalfProject(
  ...config: Parameters<typeof generateOnBehalfProject>
): Promise<ReturnType<typeof generateOnBehalfProject>> {
  return createDoc<OnBehalfProjectsCollection, typeof generateOnBehalfProject>(
    'onBehalfProjects',
    generateOnBehalfProject,
    ...config,
  );
}

export async function createCustomFieldInfoConfigurations(
  ...config: Parameters<typeof generateCustomFieldInfoConfigurationObjects>
): Promise<ReturnType<typeof generateCustomFieldInfoConfigurationObjects>> {
  return createDoc<
    CustomFieldInfoConfigurationsCollection,
    typeof generateCustomFieldInfoConfigurationObjects
  >(
    'customFieldInfoConfigurations',
    generateCustomFieldInfoConfigurationObjects,
    ...config,
  );
}

export async function createGroup(
  ...config: Parameters<typeof generateGroupObject>
): Promise<ReturnType<typeof generateGroupObject>> {
  return createDoc<GroupsCollection, typeof generateGroupObject>(
    'groups',
    generateGroupObject,
    ...config,
  );
}

export async function createGroups(
  ...config: Parameters<typeof generateGroupsObject>
): Promise<ReturnType<typeof generateGroupsObject>> {
  return createDoc<GroupsCollection, typeof generateGroupsObject>(
    'groups',
    generateGroupsObject,
    ...config,
  );
}

export async function createSuperuserGroup(
  ...config: Parameters<typeof generateGroupObject>
): Promise<ReturnType<typeof generateGroupObject>> {
  return createDoc<SuperuserGroupsCollection, typeof generateGroupObject>(
    'superuserGroups',
    generateGroupObject,
    ...config,
  );
}

export async function createSuperuserGroups(
  ...config: Parameters<typeof generateGroupsObject>
): Promise<ReturnType<typeof generateGroupsObject>> {
  return createDoc<SuperuserGroupsCollection, typeof generateGroupsObject>(
    'superuserGroups',
    generateGroupsObject,
    ...config,
  );
}

export async function createGroupsSelf(
  ...config: Parameters<typeof generateGroupsSelf>
): Promise<ReturnType<typeof generateGroupsSelf>> {
  return createDoc<GroupsSelfCollection, typeof generateGroupsSelf>(
    'groupsSelf',
    generateGroupsSelf,
    ...config,
  );
}

export async function createGroupMembers(
  ...config: Parameters<typeof generateGroupMembersObject>
): Promise<ReturnType<typeof generateGroupMembersObject>> {
  return createDoc<GroupMembersCollection, typeof generateGroupMembersObject>(
    'groupMembers',
    generateGroupMembersObject,
    ...config,
  );
}

export async function createGroupActions(
  ...config: Parameters<typeof generateGroupActionsObject>
): Promise<ReturnType<typeof generateGroupActionsObject>> {
  return createDoc<GroupActionsCollection, typeof generateGroupActionsObject>(
    'groupActions',
    generateGroupActionsObject,
    ...config,
  );
}

export async function createGroupPermissions(
  ...config: Parameters<typeof generateGroupPermissionsObject>
): Promise<ReturnType<typeof generateGroupPermissionsObject>> {
  return createDoc<
    GroupPermissionsCollection,
    typeof generateGroupPermissionsObject
  >('groupPermissions', generateGroupPermissionsObject, ...config);
}

export async function createPostFiles(
  ...config: Parameters<typeof generatePostFilesObjects>
): Promise<ReturnType<typeof generatePostFilesObjects>> {
  return createDoc<PostFilesCollection, typeof generatePostFilesObjects>(
    'postFiles',
    generatePostFilesObjects,
    ...config,
  );
}

export async function createGroupAttachments(
  ...config: Parameters<typeof generateGroupAttachmentsObjects>
): Promise<ReturnType<typeof generateGroupAttachmentsObjects>> {
  return createDoc<
    GroupAttachmentsCollection,
    typeof generateGroupAttachmentsObjects
  >('groupAttachments', generateGroupAttachmentsObjects, ...config);
}

export async function createFeedPost(
  ...config: Parameters<typeof generateFeedPostObjects>
): Promise<ReturnType<typeof generateFeedPostObjects>> {
  return createDoc<FeedPostsCollection, typeof generateFeedPostObjects>(
    'feedPosts',
    generateFeedPostObjects,
    ...config,
  );
}

export async function createFeedMeta(
  ...config: Parameters<typeof generateFeedMetaObjects>
): Promise<ReturnType<typeof generateFeedMetaObjects>> {
  return createDoc<FeedMetaCollection, typeof generateFeedMetaObjects>(
    'feedMeta',
    generateFeedMetaObjects,
    ...config,
  );
}

export async function createFeedItem(
  ...config: Parameters<typeof generateFeedObject>
): Promise<ReturnType<typeof generateFeedObject>> {
  return createDoc<FeedCollection, typeof generateFeedObject>(
    'feed',
    generateFeedObject,
    ...config,
  );
}

export async function createGroupFeed(
  ...config: Parameters<typeof generateGroupFeedObject>
): Promise<ReturnType<typeof generateGroupFeedObject>> {
  return createDoc<GroupFeedCollection, typeof generateGroupFeedObject>(
    'groupFeed',
    generateGroupFeedObject,
    ...config,
  );
}

export async function createCommentFeed(
  ...config: Parameters<typeof generateCommentFeedObject>
): Promise<ReturnType<typeof generateCommentFeedObject>> {
  return createDoc<CommentFeedsCollection, typeof generateCommentFeedObject>(
    'commentFeeds',
    generateCommentFeedObject,
    ...config,
  );
}

export async function createContentCustomizations(
  ...config: Parameters<typeof generateContentCustomizationObjects>
): Promise<ReturnType<typeof generateContentCustomizationObjects>> {
  return createDoc<
    ContentCustomizationCollection,
    typeof generateContentCustomizationObjects
  >('contentCustomization', generateContentCustomizationObjects, ...config);
}

export async function createGrant(
  ...config: Parameters<typeof generateGrantObject>
): Promise<ReturnType<typeof generateGrantObject>> {
  return createDoc<GrantsCollection, typeof generateGrantObject>(
    'grants',
    generateGrantObject,
    ...config,
  );
}

export async function createGrants(
  ...config: Parameters<typeof generateGrantsObjects>
): Promise<ReturnType<typeof generateGrantsObjects>> {
  return createDoc<GrantsCollection, typeof generateGrantsObjects>(
    'grants',
    generateGrantsObjects,
    ...config,
  );
}

export async function createParentContestComment(
  ...config: Parameters<typeof generateParentContestCommentObject>
): Promise<ReturnType<typeof generateParentContestCommentObject>> {
  return createDoc<
    ContestCommentsCollection,
    typeof generateParentContestCommentObject
  >('contestComments', generateParentContestCommentObject, ...config);
}

export async function createReplyContestComment(
  ...config: Parameters<typeof generateReplyContestCommentObject>
): Promise<ReturnType<typeof generateReplyContestCommentObject>> {
  return createDoc<
    ContestCommentsCollection,
    typeof generateReplyContestCommentObject
  >('contestComments', generateReplyContestCommentObject, ...config);
}

export async function createEnterprise(
  ...config: Parameters<typeof generateEnterpriseObject>
): Promise<ReturnType<typeof generateEnterpriseObject>> {
  return createDoc<EnterprisesCollection, typeof generateEnterpriseObject>(
    'enterprises',
    generateEnterpriseObject,
    ...config,
  );
}

export async function createEnterprises(
  ...config: Parameters<typeof generateEnterpriseObjects>
): Promise<ReturnType<typeof generateEnterpriseObjects>> {
  return createDoc<EnterprisesCollection, typeof generateEnterpriseObjects>(
    'enterprises',
    generateEnterpriseObjects,
    ...config,
  );
}

export async function createAdminEnterprises(
  ...config: Parameters<typeof generateAdminEnterpriseObjects>
): Promise<ReturnType<typeof generateAdminEnterpriseObjects>> {
  return createDoc<
    AdminEnterprisesCollection,
    typeof generateAdminEnterpriseObjects
  >('adminEnterprises', generateAdminEnterpriseObjects, ...config);
}

export async function createAdminUserEnterprises(
  ...config: Parameters<typeof generateUserEnterpriseObjects>
): Promise<ReturnType<typeof generateUserEnterpriseObjects>> {
  return createDoc<
    AdminUserEnterprisesCollection,
    typeof generateUserEnterpriseObjects
  >('adminUserEnterprises', generateUserEnterpriseObjects, ...config);
}

export async function createReactionsTypeCount(
  ...config: Parameters<typeof generateReactionTypeCountObjects>
): Promise<ReturnType<typeof generateReactionTypeCountObjects>> {
  return createDoc<
    ReactionsTypeCountCollection,
    typeof generateReactionTypeCountObjects
  >('reactionsTypeCount', generateReactionTypeCountObjects, ...config);
}

export async function createReactionTypeCount(
  ...config: Parameters<typeof generateReactionTypeCountObject>
): Promise<ReturnType<typeof generateReactionTypeCountObject>> {
  return createDoc<
    ReactionsTypeCountCollection,
    typeof generateReactionTypeCountObject
  >('reactionsTypeCount', generateReactionTypeCountObject, ...config);
}

export async function createComments(
  ...config: Parameters<typeof generateCommentObjects>
): Promise<ReturnType<typeof generateCommentObjects>> {
  return createDoc<CommentsCollection, typeof generateCommentObjects>(
    'comments',
    generateCommentObjects,
    ...config,
  );
}

export async function createComment(
  ...config: Parameters<typeof generateCommentObject>
): Promise<ReturnType<typeof generateCommentObject>> {
  return createDoc<CommentsCollection, typeof generateCommentObject>(
    'comments',
    generateCommentObject,
    ...config,
  );
}

export async function createUserContestCollaborationWhitelist(
  ...config: Parameters<typeof generateUserContestCollaborationWhitelist>
): Promise<ReturnType<typeof generateUserContestCollaborationWhitelist>> {
  return createDoc<
    UserContestCollaborationWhitelistCollection,
    typeof generateUserContestCollaborationWhitelist
  >(
    'userContestCollaborationWhitelist',
    generateUserContestCollaborationWhitelist,
    ...config,
  );
}

export async function createPaymentShareWhitelistItem(
  ...config: Parameters<typeof generatePaymentShareWhitelistItemObject>
): Promise<ReturnType<typeof generatePaymentShareWhitelistItemObject>> {
  return createDoc<
    PaymentShareWhitelistItemsCollection,
    typeof generatePaymentShareWhitelistItemObject
  >(
    'paymentShareWhitelistItems',
    generatePaymentShareWhitelistItemObject,
    ...config,
  );
}

export async function createPaymentShareTeams(
  ...config: Parameters<typeof generatePaymentShareTeamObject>
): Promise<ReturnType<typeof generatePaymentShareTeamObject>> {
  return createDoc<
    PaymentShareTeamsCollection,
    typeof generatePaymentShareTeamObject
  >('paymentShareTeams', generatePaymentShareTeamObject, ...config);
}

export async function createPaymentShareMembers(
  ...config: Parameters<typeof generatePaymentShareMemberObject>
): Promise<ReturnType<typeof generatePaymentShareMemberObject>> {
  return createDoc<
    PaymentShareMembersCollection,
    typeof generatePaymentShareMemberObject
  >('paymentShareMembers', generatePaymentShareMemberObject, ...config);
}

export async function createPaymentShareMembersSpentAmount(
  ...config: Parameters<typeof generatePaymentShareMemberSpentAmountObject>
): Promise<ReturnType<typeof generatePaymentShareMemberSpentAmountObject>> {
  return createDoc<
    PaymentShareMembersSpentAmountCollection,
    typeof generatePaymentShareMemberSpentAmountObject
  >(
    'paymentShareMembersSpentAmount',
    generatePaymentShareMemberSpentAmountObject,
    ...config,
  );
}

export async function createPaymentShareInterest(
  ...config: Parameters<typeof generatePaymentShareInterestObject>
): Promise<ReturnType<typeof generatePaymentShareInterestObject>> {
  return createDoc<
    PaymentShareInterestsCollection,
    typeof generatePaymentShareInterestObject
  >('paymentShareInterests', generatePaymentShareInterestObject, ...config);
}

export async function createEnterpriseBillingAgreementBalance(
  ...config: Parameters<typeof generateEnterpriseBillingAgreementsBalance>
): Promise<ReturnType<typeof generateEnterpriseBillingAgreementsBalance>> {
  return createDoc<
    EnterpriseBillingAgreementsBalanceCollection,
    typeof generateEnterpriseBillingAgreementsBalance
  >(
    'enterpriseBillingAgreementsBalance',
    generateEnterpriseBillingAgreementsBalance,
    ...config,
  );
}

export async function createMembershipSubscriptionHistory(
  ...config: Parameters<typeof generateMembershipSubscriptionHistoryObjects>
): Promise<ReturnType<typeof generateMembershipSubscriptionHistoryObjects>> {
  return createDoc<
    MembershipSubscriptionHistoryCollection,
    typeof generateMembershipSubscriptionHistoryObjects
  >(
    'membershipSubscriptionHistory',
    generateMembershipSubscriptionHistoryObjects,
    ...config,
  );
}

export async function createMembershipPackages(
  ...config: Parameters<typeof generateMembershipPackagesObjects>
): Promise<ReturnType<typeof generateMembershipPackagesObjects>> {
  return createDoc<
    MembershipPackagesCollection,
    typeof generateMembershipPackagesObjects
  >('membershipPackages', generateMembershipPackagesObjects, ...config);
}

export async function createMembershipTrials(
  ...config: Parameters<typeof generateMembershipTrialsObjects>
): Promise<ReturnType<typeof generateMembershipTrialsObjects>> {
  return createDoc<
    MembershipTrialsCollection,
    typeof generateMembershipTrialsObjects
  >('membershipTrials', generateMembershipTrialsObjects, ...config);
}

export async function createMembershipRenewals(
  ...config: Parameters<typeof generateMembershipRenewalsObjects>
): Promise<ReturnType<typeof generateMembershipRenewalsObjects>> {
  return createDoc<
    MembershipRenewalsCollection,
    typeof generateMembershipRenewalsObjects
  >('membershipRenewals', generateMembershipRenewalsObjects, ...config);
}

export async function createMembershipDowngrade(
  ...config: Parameters<typeof generateMembershipDowngradesObject>
): Promise<ReturnType<typeof generateMembershipDowngradesObject>> {
  return createDoc<
    MembershipDowngradesCollection,
    typeof generateMembershipDowngradesObject
  >('membershipDowngrades', generateMembershipDowngradesObject, ...config);
}

export async function createUserInteraction(
  ...config: Parameters<typeof generateUserInteractions>
): Promise<ReturnType<typeof generateUserInteractions>> {
  return createDoc<UserInteractionsCollection, typeof generateUserInteractions>(
    'userInteractions',
    generateUserInteractions,
    ...config,
  );
}

export async function createVerificationAddressDocumentTypes(
  ...config: Parameters<typeof generateVerificationAddressDocumentTypesObjects>
): Promise<ReturnType<typeof generateVerificationAddressDocumentTypesObjects>> {
  return createDoc<
    VerificationAddressDocumentTypesCollection,
    typeof generateVerificationAddressDocumentTypesObjects
  >(
    'verificationAddressDocumentTypes',
    generateVerificationAddressDocumentTypesObjects,
    ...config,
  );
}

export async function createVerificationFiles(
  ...config: Parameters<typeof generateVerificationFilesObject>
): Promise<ReturnType<typeof generateVerificationFilesObject>> {
  return createDoc<
    VerificationFilesCollection,
    typeof generateVerificationFilesObject
  >('verificationFiles', generateVerificationFilesObject, ...config);
}

export async function createVerificationIdTypes(
  ...config: Parameters<typeof generateVerificationIdTypesObjects>
): Promise<ReturnType<typeof generateVerificationIdTypesObjects>> {
  return createDoc<
    VerificationIdTypesCollection,
    typeof generateVerificationIdTypesObjects
  >('verificationIdTypes', generateVerificationIdTypesObjects, ...config);
}

export async function createVerificationRequest(
  ...config: Parameters<typeof generateVerificationRequestObject>
): Promise<ReturnType<typeof generateVerificationRequestObject>> {
  return createDoc<
    VerificationRequestCollection,
    typeof generateVerificationRequestObject
  >('verificationRequest', generateVerificationRequestObject, ...config);
}

export async function createQuotationRevision(
  ...config: Parameters<typeof generateQuotationRevisionObject>
): Promise<ReturnType<typeof generateQuotationRevisionObject>> {
  return createDoc<
    QuotationRevisionsCollection,
    typeof generateQuotationRevisionObject
  >('quotationRevisions', generateQuotationRevisionObject, ...config);
}

export async function createQuotationItemRevision(
  ...config: Parameters<typeof generateQuotationItemRevisionObject>
): Promise<ReturnType<typeof generateQuotationItemRevisionObject>> {
  return createDoc<
    QuotationItemRevisionsCollection,
    typeof generateQuotationItemRevisionObject
  >('quotationItemRevisions', generateQuotationItemRevisionObject, ...config);
}

export async function createQuotationItemRevisions(
  ...config: Parameters<typeof generateQuotationItemRevisionObjects>
): Promise<ReturnType<typeof generateQuotationItemRevisionObjects>> {
  return createDoc<
    QuotationItemRevisionsCollection,
    typeof generateQuotationItemRevisionObjects
  >('quotationItemRevisions', generateQuotationItemRevisionObjects, ...config);
}

export async function createQuotationLogo(
  ...config: Parameters<typeof generateQuotationLogoObject>
): Promise<ReturnType<typeof generateQuotationLogoObject>> {
  return createDoc<
    QuotationLogosCollection,
    typeof generateQuotationLogoObject
  >('quotationLogos', generateQuotationLogoObject, ...config);
}

export async function createUserNotificationPreferences(
  ...config: Parameters<typeof generateUserNotificationPreferencesObject>
): Promise<ReturnType<typeof generateUserNotificationPreferencesObject>> {
  return createDoc<
    UserNotificationPreferencesCollection,
    typeof generateUserNotificationPreferencesObject
  >(
    'userNotificationPreferences',
    generateUserNotificationPreferencesObject,
    ...config,
  );
}

export async function createNotificationSettings(
  ...config: Parameters<typeof generateNotificationSettingsObject>
): Promise<ReturnType<typeof generateNotificationSettingsObject>> {
  return createDoc<
    NotificationSettingsCollection,
    typeof generateNotificationSettingsObject
  >('notificationSettings', generateNotificationSettingsObject, ...config);
}

export async function createUserSettings(
  ...config: Parameters<typeof generateUserSettingsObject>
): Promise<ReturnType<typeof generateUserSettingsObject>> {
  return createDoc<UserSettingsCollection, typeof generateUserSettingsObject>(
    'userSettings',
    generateUserSettingsObject,
    ...config,
  );
}

export async function createTaxOptOutOptionObjects(
  ...config: Parameters<typeof generateTaxOptOutOptionObjects>
): Promise<ReturnType<typeof generateTaxOptOutOptionObjects>> {
  return createDoc<
    TaxOptOutOptionsCollection,
    typeof generateTaxOptOutOptionObjects
  >('taxOptOutOptions', generateTaxOptOutOptionObjects, ...config);
}

export async function createTimezones(
  ...config: Parameters<typeof generateTimezoneObjects>
): Promise<ReturnType<typeof generateTimezoneObjects>> {
  return createDoc<TimezonesCollection, typeof generateTimezoneObjects>(
    'timezones',
    generateTimezoneObjects,
    ...config,
  );
}

export async function createUsStates(
  ...config: Parameters<typeof generateUsStateObjects>
): Promise<ReturnType<typeof generateUsStateObjects>> {
  return createDoc<UsStatesCollection, typeof generateUsStateObjects>(
    'usStates',
    generateUsStateObjects,
    ...config,
  );
}

export async function createLanguages(
  ...config: Parameters<typeof generateLanguagesObjects>
): Promise<ReturnType<typeof generateLanguagesObjects>> {
  return createDoc<LanguagesCollection, typeof generateLanguagesObjects>(
    'languages',
    generateLanguagesObjects,
    ...config,
  );
}

export async function createPjpDraft(
  ...config: Parameters<typeof generatePjpDraftObject>
): Promise<ReturnType<typeof generatePjpDraftObject>> {
  return createDoc<
    PostJobPageFormStateCollection,
    typeof generatePjpDraftObject
  >('postJobPageFormState', generatePjpDraftObject, ...config);
}

export async function createPredefinedDiscoverCollection(
  ...config: Parameters<typeof generatePredefinedDiscoverCollectionObject>
): Promise<ReturnType<typeof generatePredefinedDiscoverCollectionObject>> {
  return createDoc<
    PredefinedDiscoverCollectionsCollection,
    typeof generatePredefinedDiscoverCollectionObject
  >(
    'predefinedDiscoverCollections',
    generatePredefinedDiscoverCollectionObject,
    ...config,
  );
}

export async function createManageViewContestHandovers(
  ...config: Parameters<typeof generateManageViewContestHandoverObjects>
): Promise<ReturnType<typeof generateManageViewContestHandoverObjects>> {
  return createDoc<
    ManageViewContestHandoversCollection,
    typeof generateManageViewContestHandoverObjects
  >(
    'manageViewContestHandovers',
    generateManageViewContestHandoverObjects,
    ...config,
  );
}

export async function createManageViewContestHandover(
  ...config: Parameters<typeof generateManageViewContestHandoverObject>
): Promise<ReturnType<typeof generateManageViewContestHandoverObject>> {
  return createDoc<
    ManageViewContestHandoversCollection,
    typeof generateManageViewContestHandoverObject
  >(
    'manageViewContestHandovers',
    generateManageViewContestHandoverObject,
    ...config,
  );
}

export async function createManageViewContest(
  ...config: Parameters<typeof generateManageViewContestObject>
): Promise<ReturnType<typeof generateManageViewContestObject>> {
  return createDoc<
    ManageViewContestsCollection,
    typeof generateManageViewContestObject
  >('manageViewContests', generateManageViewContestObject, ...config);
}

export async function createDiscoverCollectionItem(
  ...config: Parameters<typeof generateDiscoverCollectionItemObject>
): Promise<ReturnType<typeof generateDiscoverCollectionItemObject>> {
  return createDoc<
    DiscoverCollectionItemsCollection,
    typeof generateDiscoverCollectionItemObject
  >('discoverCollectionItems', generateDiscoverCollectionItemObject, ...config);
}

export async function createCampaign(
  ...config: Parameters<typeof generateCampaignObject>
): Promise<ReturnType<typeof generateCampaignObject>> {
  return createDoc<CampaignsCollection, typeof generateCampaignObject>(
    'campaigns',
    generateCampaignObject,
    ...config,
  );
}

export async function createServiceOffering(
  ...config: Parameters<typeof generateServiceOfferingObject>
): Promise<ReturnType<typeof generateServiceOfferingObject>> {
  return createDoc<
    ServiceOfferingsCollection,
    typeof generateServiceOfferingObject
  >('serviceOfferings', generateServiceOfferingObject, ...config);
}

export async function createServiceOfferingWithOwnerDetails(
  ...config: Parameters<typeof generateServiceOfferingWithOwnerDetailsObject>
): Promise<ReturnType<typeof generateServiceOfferingWithOwnerDetailsObject>> {
  return createDoc<
    ServiceOfferingsWithOwnerDetailsCollection,
    typeof generateServiceOfferingWithOwnerDetailsObject
  >(
    'serviceOfferingsWithOwnerDetails',
    generateServiceOfferingWithOwnerDetailsObject,
    ...config,
  );
}

export async function createServiceOfferingBuyerHistories(
  ...config: Parameters<typeof generateServiceOfferingBuyerHistoriesObject>
): Promise<ReturnType<typeof generateServiceOfferingBuyerHistoriesObject>> {
  return createDoc<
    ServiceOfferingBuyerHistoriesCollection,
    typeof generateServiceOfferingBuyerHistoriesObject
  >(
    'serviceOfferingBuyerHistories',
    generateServiceOfferingBuyerHistoriesObject,
    ...config,
  );
}

export async function createServiceOfferingShop(
  ...config: Parameters<typeof generateServiceOfferingShopObject>
): Promise<ReturnType<typeof generateServiceOfferingShopObject>> {
  return createDoc<
    ServiceOfferingShopsCollection,
    typeof generateServiceOfferingShopObject
  >('serviceOfferingShops', generateServiceOfferingShopObject, ...config);
}

export async function createServiceOfferingCategories(
  ...config: Parameters<typeof generateServiceOfferingCategoryObject>
): Promise<ReturnType<typeof generateServiceOfferingCategoryObject>> {
  return createDoc<
    ServiceOfferingShopCategoriesCollection,
    typeof generateServiceOfferingCategoryObject
  >(
    'serviceOfferingShopCategories',
    generateServiceOfferingCategoryObject,
    ...config,
  );
}

export async function createServiceOfferingShopCategoryService(
  ...config: Parameters<typeof generateServiceOfferingShopCategoryServiceObject>
): Promise<
  ReturnType<typeof generateServiceOfferingShopCategoryServiceObject>
> {
  return createDoc<
    ServiceOfferingShopCategoryServicesCollection,
    typeof generateServiceOfferingShopCategoryServiceObject
  >(
    'serviceOfferingShopCategoryServices',
    generateServiceOfferingShopCategoryServiceObject,
    ...config,
  );
}

export async function createVerificationProcessType(
  ...config: Parameters<typeof generateVerificationProcessType>
): Promise<ReturnType<typeof generateVerificationProcessType>> {
  return createDoc<
    VerificationProcessTypesCollection,
    typeof generateVerificationProcessType
  >('verificationProcessTypes', generateVerificationProcessType, ...config);
}

export async function createVerificationProcessTypes(
  ...config: Parameters<typeof generateVerificationProcessTypes>
): Promise<ReturnType<typeof generateVerificationProcessTypes>> {
  return createDoc<
    VerificationProcessTypesCollection,
    typeof generateVerificationProcessTypes
  >('verificationProcessTypes', generateVerificationProcessTypes, ...config);
}

export async function createUserDocumentSubmission(
  ...config: Parameters<typeof generateUserDocumentSubmission>
): Promise<ReturnType<typeof generateUserDocumentSubmission>> {
  return createDoc<
    UserDocumentSubmissionsCollection,
    typeof generateUserDocumentSubmission
  >('userDocumentSubmissions', generateUserDocumentSubmission, ...config);
}

export async function createUserVerificationProcesses(
  ...config: Parameters<typeof generateUserVerificationProcesses>
): Promise<ReturnType<typeof generateUserVerificationProcesses>> {
  return createDoc<
    UserVerificationProcessesCollection,
    typeof generateUserVerificationProcesses
  >('userVerificationProcesses', generateUserVerificationProcesses, ...config);
}

export async function createSearchActiveLoad(
  ...config: Parameters<typeof generateSearchActiveLoadObject>
): Promise<ReturnType<typeof generateSearchActiveLoadObject>> {
  return createDoc<
    SearchActiveLoadsCollection,
    typeof generateSearchActiveLoadObject
  >('searchActiveLoads', generateSearchActiveLoadObject, ...config);
}

export async function createSearchActiveLoads(
  ...config: Parameters<typeof generateSearchActiveLoadsObject>
): Promise<ReturnType<typeof generateSearchActiveLoadsObject>> {
  return createDoc<
    SearchActiveLoadsCollection,
    typeof generateSearchActiveLoadsObject
  >('searchActiveLoads', generateSearchActiveLoadsObject, ...config);
}

export async function createOperatingAreaRegions(
  ...config: Parameters<typeof generateOperatingAreaObjects>
): Promise<ReturnType<typeof generateOperatingAreaObjects>> {
  return createDoc<
    OperatingAreasCollection,
    typeof generateOperatingAreaObjects
  >('operatingAreas', generateOperatingAreaObjects, ...config);
}

export async function createLoadTypes(
  ...config: Parameters<typeof generateLoadTypeObjects>
): Promise<ReturnType<typeof generateLoadTypeObjects>> {
  return createDoc<SkillsCollection, typeof generateLoadTypeObjects>(
    'skills',
    generateLoadTypeObjects,
    ...config,
  );
}

export async function createRecommendedUserNames(
  ...config: Parameters<typeof generateRecommendedUserNamesObject>
): Promise<ReturnType<typeof generateRecommendedUserNamesObject>> {
  return createDoc<
    RecommendedUsernamesCollection,
    typeof generateRecommendedUserNamesObject
  >('recommendedUsernames', generateRecommendedUserNamesObject, ...config);
}

export async function createRecommendedMembership(
  ...config: Parameters<typeof generateRecommendedMembershipObject>
): Promise<ReturnType<typeof generateRecommendedMembershipObject>> {
  return createDoc<
    RecommendedMembershipCollection,
    typeof generateRecommendedMembershipObject
  >('recommendedMembership', generateRecommendedMembershipObject, ...config);
}

export function createUserDocumentSubmissions(
  ...config: Parameters<typeof generateUserDocumentSubmissions>
): Promise<ReturnType<typeof generateUserDocumentSubmissions>> {
  return createDoc<
    UserDocumentSubmissionsCollection,
    typeof generateUserDocumentSubmissions
  >('userDocumentSubmissions', generateUserDocumentSubmissions, ...config);
}

export function createAdminUserDocumentSubmissions(
  ...config: Parameters<typeof generateUserDocumentSubmissions>
): Promise<ReturnType<typeof generateUserDocumentSubmissions>> {
  return createDoc<
    AdminUserDocumentSubmissionsCollection,
    typeof generateUserDocumentSubmissions
  >('adminUserDocumentSubmissions', generateUserDocumentSubmissions, ...config);
}

export async function createShowcase(
  ...config: Parameters<typeof generateShowcase>
): Promise<ReturnType<typeof generateShowcase>> {
  return createDoc<ShowcaseCollection, typeof generateShowcase>(
    'showcase',
    generateShowcase,
    ...config,
  );
}

export async function createShowcases(
  ...config: Parameters<typeof generateShowcases>
): Promise<ReturnType<typeof generateShowcases>> {
  return createDoc<ShowcaseCollection, typeof generateShowcases>(
    'showcase',
    generateShowcases,
    ...config,
  );
}

export async function createHirePageDetails(
  ...config: Parameters<typeof generateHirePageDetailsObject>
): Promise<ReturnType<typeof generateHirePageDetailsObject>> {
  return createDoc<
    HirePageDetailsCollection,
    typeof generateHirePageDetailsObject
  >('hirePageDetails', generateHirePageDetailsObject, ...config);
}

export async function createBillingAgreements(
  ...config: Parameters<typeof generateBillingAgreementObjects>
): Promise<ReturnType<typeof generateBillingAgreementObjects>> {
  return createDoc<
    BillingAgreementsCollection,
    typeof generateBillingAgreementObjects
  >('billingAgreements', generateBillingAgreementObjects, ...config);
}

export async function createTaxStatements(
  ...config: Parameters<typeof generateTaxStatementObjects>
): Promise<ReturnType<typeof generateTaxStatementObjects>> {
  return createDoc<TaxStatementCollection, typeof generateTaxStatementObjects>(
    'taxStatement',
    generateTaxStatementObjects,
    ...config,
  );
}

export async function createBidPitch(
  ...config: Parameters<typeof generateBidPitchObject>
): Promise<ReturnType<typeof generateBidPitchObject>> {
  return createDoc<BidPitchCollection, typeof generateBidPitchObject>(
    'bidPitch',
    generateBidPitchObject,
    ...config,
  );
}

export async function createIpContract(
  ...config: Parameters<typeof generateIpContractObject>
): Promise<ReturnType<typeof generateIpContractObject>> {
  return createDoc<IpContractsCollection, typeof generateIpContractObject>(
    'ipContracts',
    generateIpContractObject,
    ...config,
  );
}

export async function createAnalytics(
  ...config: Parameters<typeof generateAnalyticsObjects>
): Promise<ReturnType<typeof generateAnalyticsObjects>> {
  return createDoc<AnalyticsCollection, typeof generateAnalyticsObjects>(
    'analytics',
    generateAnalyticsObjects,
    ...config,
  );
}

export async function createPayoutLimitExemptions(
  ...config: Parameters<typeof generatePayoutLimitExemptionObjects>
): Promise<ReturnType<typeof generatePayoutLimitExemptionObjects>> {
  return createDoc<
    PayoutLimitExemptionsCollection,
    typeof generatePayoutLimitExemptionObjects
  >('payoutLimitExemptions', generatePayoutLimitExemptionObjects, ...config);
}

export async function createManageViewOpenProject(
  ...config: Parameters<typeof generateManageViewOpenProject>
): Promise<ReturnType<typeof generateManageViewOpenProject>> {
  return createDoc<
    ManageViewOpenProjectsCollection,
    typeof generateManageViewOpenProject
  >('manageViewOpenProjects', generateManageViewOpenProject, ...config);
}

export async function createManageViewPastBids(
  ...config: Parameters<typeof generateManageViewPastBidObjects>
): Promise<ReturnType<typeof generateManageViewPastBidObjects>> {
  return createDoc<
    ManageViewPastBidsCollection,
    typeof generateManageViewPastBidObjects
  >('manageViewPastBids', generateManageViewPastBidObjects, ...config);
}

export async function createManageViewPastProjects(
  ...config: Parameters<typeof generateManageViewPastProjects>
): Promise<ReturnType<typeof generateManageViewPastProjects>> {
  return createDoc<
    ManageViewPastProjectsCollection,
    typeof generateManageViewPastProjects
  >('manageViewPastProjects', generateManageViewPastProjects, ...config);
}

export async function createManageViewPastProject(
  ...config: Parameters<typeof generateManageViewPastProject>
): Promise<ReturnType<typeof generateManageViewPastProject>> {
  return createDoc<
    ManageViewPastProjectsCollection,
    typeof generateManageViewPastProject
  >('manageViewPastProjects', generateManageViewPastProject, ...config);
}

export async function createManageViewOpenProjects(
  ...config: Parameters<typeof generateManageViewOpenProjects>
): Promise<ReturnType<typeof generateManageViewOpenProjects>> {
  return createDoc<
    ManageViewOpenProjectsCollection,
    typeof generateManageViewOpenProjects
  >('manageViewOpenProjects', generateManageViewOpenProjects, ...config);
}

export async function createReferralInvitations(
  ...config: Parameters<typeof generateReferralInvitations>
): Promise<ReturnType<typeof generateReferralInvitations>> {
  return createDoc<
    ReferralInvitationsCollection,
    typeof generateReferralInvitations
  >('referralInvitations', generateReferralInvitations, ...config);
}

export async function createGuideline(
  ...config: Parameters<typeof generateGuideline>
): Promise<ReturnType<typeof generateGuideline>> {
  return createDoc<GuidelinesCollection, typeof generateGuideline>(
    'guidelines',
    generateGuideline,
    ...config,
  );
}

export async function createProfile(
  ...config: Parameters<typeof generateProfileObject>
): Promise<ReturnType<typeof generateProfileObject>> {
  return createDoc<ProfilesCollection, typeof generateProfileObject>(
    'profiles',
    generateProfileObject,
    ...config,
  );
}

export async function createProfiles(
  ...config: Parameters<typeof generateProfileObjects>
): Promise<ReturnType<typeof generateProfileObjects>> {
  return createDoc<ProfilesCollection, typeof generateProfileObjects>(
    'profiles',
    generateProfileObjects,
    ...config,
  );
}

export async function createDiscoverCollectionItemSaveCounts(
  ...config: Parameters<typeof generateDiscoverCollectionItemSaveCounts>
): Promise<ReturnType<typeof generateDiscoverCollectionItemSaveCounts>> {
  return createDoc<
    DiscoverCollectionItemSaveCountCollection,
    typeof generateDiscoverCollectionItemSaveCounts
  >(
    'discoverCollectionItemSaveCount',
    generateDiscoverCollectionItemSaveCounts,
    ...config,
  );
}

export async function createSuperuserProjectNotes(
  ...config: Parameters<typeof generateSuperuserProjectNoteObjects>
): Promise<ReturnType<typeof generateSuperuserProjectNoteObjects>> {
  return createDoc<
    SuperuserProjectNotesCollection,
    typeof generateSuperuserProjectNoteObjects
  >('superuserProjectNotes', generateSuperuserProjectNoteObjects, ...config);
}

export async function createIsLookingForWork(
  ...config: Parameters<typeof generateIsLookingForWork>
): Promise<ReturnType<typeof generateIsLookingForWork>> {
  return createDoc<IsLookingForWorkCollection, typeof generateIsLookingForWork>(
    'isLookingForWork',
    generateIsLookingForWork,
    ...config,
  );
}

export async function createRecurringBillingInformation(
  ...config: Parameters<typeof generateRecurringBillingInformationObject>
): Promise<ReturnType<typeof generateRecurringBillingInformationObject>> {
  return createDoc<
    RecurringBillingInformationCollection,
    typeof generateRecurringBillingInformationObject
  >(
    'recurringBillingInformation',
    generateRecurringBillingInformationObject,
    ...config,
  );
}

export async function createScheduledSubscriptionPayment(
  ...config: Parameters<typeof generateScheduledSubscriptionPaymentObject>
): Promise<ReturnType<typeof generateScheduledSubscriptionPaymentObject>> {
  return createDoc<
    ScheduledSubscriptionPaymentsCollection,
    typeof generateScheduledSubscriptionPaymentObject
  >(
    'scheduledSubscriptionPayments',
    generateScheduledSubscriptionPaymentObject,
    ...config,
  );
}

export async function createRecurringBillingInformations(
  ...config: Parameters<typeof generateRecurringBillingInformationObjects>
): Promise<ReturnType<typeof generateRecurringBillingInformationObjects>> {
  return createDoc<
    RecurringBillingInformationCollection,
    typeof generateRecurringBillingInformationObjects
  >(
    'recurringBillingInformation',
    generateRecurringBillingInformationObjects,
    ...config,
  );
}

export async function createScheduledSubscriptionPayments(
  ...config: Parameters<typeof generateScheduledSubscriptionPaymentObjects>
): Promise<ReturnType<typeof generateScheduledSubscriptionPaymentObjects>> {
  return createDoc<
    ScheduledSubscriptionPaymentsCollection,
    typeof generateScheduledSubscriptionPaymentObjects
  >(
    'scheduledSubscriptionPayments',
    generateScheduledSubscriptionPaymentObjects,
    ...config,
  );
}

export async function createSubscriptionItem(
  ...config: Parameters<typeof generateSubscriptionItemObject>
): Promise<ReturnType<typeof generateSubscriptionItemObject>> {
  return createDoc<
    SubscriptionItemsCollection,
    typeof generateSubscriptionItemObject
  >('subscriptionItems', generateSubscriptionItemObject, ...config);
}

export async function createSubscriptionItems(
  ...config: Parameters<typeof generateSubscriptionItemObjects>
): Promise<ReturnType<typeof generateSubscriptionItemObjects>> {
  return createDoc<
    SubscriptionItemsCollection,
    typeof generateSubscriptionItemObjects
  >('subscriptionItems', generateSubscriptionItemObjects, ...config);
}

export async function createProjectSeo(
  ...config: Parameters<typeof generateProjectSeo>
): Promise<ReturnType<typeof generateProjectSeo>> {
  return createDoc<ProjectsSeoCollection, typeof generateProjectSeo>(
    'projectsSeo',
    generateProjectSeo,
    ...config,
  );
}

export async function createUserFollows(
  ...config: Parameters<typeof generateUserFollowObjects>
): Promise<ReturnType<typeof generateUserFollowObjects>> {
  return createDoc<UsersFollowCollection, typeof generateUserFollowObjects>(
    'usersFollow',
    generateUserFollowObjects,
    ...config,
  );
}

export async function createPreferredSupportAgentAssignments(
  ...config: Parameters<typeof generatePreferredSupportAgentAssignmentObject>
): Promise<ReturnType<typeof generatePreferredSupportAgentAssignmentObject>> {
  return createDoc<
    PreferredSupportAgentCollection,
    typeof generatePreferredSupportAgentAssignmentObject
  >(
    'preferredSupportAgent',
    generatePreferredSupportAgentAssignmentObject,
    ...config,
  );
}

export async function createIrisOffsitingMessages(
  ...config: Parameters<typeof generateAdminIrisOffsitingObject>
): Promise<ReturnType<typeof generateAdminIrisOffsitingObject>> {
  return createDoc<
    AdminIrisOffsitingCollection,
    typeof generateAdminIrisOffsitingObject
  >('adminIrisOffsiting', generateAdminIrisOffsitingObject, ...config);
}

export async function createCheckInSchedule(
  ...config: Parameters<typeof generateCheckInScheduleObject>
): Promise<ReturnType<typeof generateCheckInScheduleObject>> {
  return createDoc<
    CheckInScheduleCollection,
    typeof generateCheckInScheduleObject
  >('checkInSchedule', generateCheckInScheduleObject, ...config);
}

export async function createSkillsSubNavigation(
  ...config: Parameters<typeof generateSkillsSubNavigationObject>
): Promise<ReturnType<typeof generateSkillsSubNavigationObject>> {
  return createDoc<
    SkillsSubNavigationCollection,
    typeof generateSkillsSubNavigationObject
  >('skillsSubNavigation', generateSkillsSubNavigationObject, ...config);
}

export async function createExternalReference(
  ...config: Parameters<typeof generateExternalReferenceObject>
): Promise<ReturnType<typeof generateExternalReferenceObject>> {
  return createDoc<
    ExternalReferenceCollection,
    typeof generateExternalReferenceObject
  >('externalReference', generateExternalReferenceObject, ...config);
}

export async function createFlareCheckpoint(
  ...config: Parameters<typeof generateFlareCheckpointObject>
): Promise<ReturnType<typeof generateFlareCheckpointObject>> {
  return createDoc<
    FlareCheckpointsCollection,
    typeof generateFlareCheckpointObject
  >('flareCheckpoints', generateFlareCheckpointObject, ...config);
}

export async function createFlareRule(
  ...config: Parameters<typeof generateFlareRuleObject>
): Promise<ReturnType<typeof generateFlareRuleObject>> {
  return createDoc<FlareRulesCollection, typeof generateFlareRuleObject>(
    'flareRules',
    generateFlareRuleObject,
    ...config,
  );
}

export async function createFlareRuleset(
  ...config: Parameters<typeof generateFlareRulesetObject>
): Promise<ReturnType<typeof generateFlareRulesetObject>> {
  return createDoc<FlareRulesetsCollection, typeof generateFlareRulesetObject>(
    'flareRulesets',
    generateFlareRulesetObject,
    ...config,
  );
}

export async function createFlareActionMap(
  ...config: Parameters<typeof generateFlareActionMapObject>
): Promise<ReturnType<typeof generateFlareActionMapObject>> {
  return createDoc<
    FlareActionMapCollection,
    typeof generateFlareActionMapObject
  >('flareActionMap', generateFlareActionMapObject, ...config);
}

export async function createSupportAnswer(
  ...config: Parameters<typeof generateSupportAnswerObject>
): Promise<ReturnType<typeof generateSupportAnswerObject>> {
  return createDoc<
    SupportAnswersCollection,
    typeof generateSupportAnswerObject
  >('supportAnswers', generateSupportAnswerObject, ...config);
}

// #endregion Multiple object creation functions. These should all be plural.
