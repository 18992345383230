import type {
  AfterContentInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  HostListener,
  Input,
  QueryList,
} from '@angular/core';
import { isDefined } from '@freelancer/utils';
import type { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ListItemComponent } from '../list-item/list-item.component';
import {
  ListItemInputAlignment,
  ListItemOrderedCounterType,
  ListItemPadding,
  ListItemType,
} from '../list-item/list-item.types';
import { FontColor } from '../text';

@Component({
  selector: 'fl-list',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements AfterContentInit, OnChanges, OnDestroy {
  ListItemType = ListItemType;

  @Input() bordered = true;
  /** Removes padding from the fl-list-item-body subcomponent */
  @Input() bodyEdgeToEdge = false;
  /** Adds an extra border to the bottom of the list */
  @Input() bottomBorder = false;
  @Input() clickable = false;
  @Input() expandable = false;
  /** Applies a blue border when the item is active */
  @Input() selectable = false;
  @Input() transparent = false;
  /** Applies left and right padding to the list items */
  @Input() outerPadding = true;
  @Input() indent = false;
  @Input() padding = ListItemPadding.XSMALL;
  @Input() paddingDesktop?: ListItemPadding;
  /**
   * Set left/right paddings to the list items,
   * instead of using the left/right paddings set by padding or paddingDesktop
   */
  @Input() horizontalPadding?: ListItemPadding;
  @Input() horizontalPaddingDesktop?: ListItemPadding;
  @Input() inputAlignment = ListItemInputAlignment.CENTER;
  @Input() selectByKeyboard = false;
  @Input() orderedListCounterType = ListItemOrderedCounterType.DECIMAL;

  @HostBinding('attr.data-type')
  @Input()
  type = ListItemType.DEFAULT;

  @Input() @HostBinding('attr.data-color-font') fontColor: FontColor =
    FontColor.FOREGROUND;

  @HostBinding('attr.role') ariaRole = 'list';

  // Child components
  @ContentChildren(ListItemComponent)
  listItemComponents: QueryList<ListItemComponent<unknown>>;

  private childListSubscription?: Subscription;
  private activeListItem?: ListItemComponent<unknown>;
  private activeListItemIndex?: number;

  ngOnChanges(changes: SimpleChanges): void {
    // apply properties to child list-items whenever the properties change.
    this.applyPropertiesToChildren();

    if ('selectable' in changes || 'type' in changes) {
      this.ariaRole =
        this.selectable ||
        [
          ListItemType.CHECKBOX,
          ListItemType.RADIO,
          ListItemType.TOGGLE,
        ].includes(this.type)
          ? 'listbox'
          : 'list';
    }
  }

  ngAfterContentInit(): void {
    // apply properties to child list-items whenever the list of children changes
    this.childListSubscription = this.listItemComponents.changes
      .pipe(startWith(this.listItemComponents.toArray()))
      .subscribe(() => this.applyPropertiesToChildren());
  }

  ngOnDestroy(): void {
    if (this.childListSubscription) {
      this.childListSubscription.unsubscribe();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    const key = event.key?.toLowerCase();
    if (this.selectByKeyboard && ['arrowup', 'up'].includes(key)) {
      // up arrow
      if (!this.activeListItem) {
        this.activeListItemIndex = this.listItemComponents.length - 1;
      } else if (
        isDefined(this.activeListItemIndex) &&
        this.activeListItemIndex >= 0
      ) {
        this.activeListItemIndex--;
      }
    } else if (
      this.selectByKeyboard &&
      ['arrowdown', 'down', 'tab'].includes(key)
    ) {
      // down arrow OR tab
      if (!this.activeListItem) {
        this.activeListItemIndex = 0;
      } else if (
        isDefined(this.activeListItemIndex) &&
        this.activeListItemIndex < this.listItemComponents.length
      ) {
        this.activeListItemIndex++;
      }
    }

    // Activate the current list item by activeListItemIndex
    // and deactivate the previous one if that's defined
    if (this.selectByKeyboard && isDefined(this.activeListItemIndex)) {
      this.activeListItem?.deactivate();
      this.activeListItem?.changeDetectorRef.detectChanges();
      this.activeListItem = this.listItemComponents.find(
        (component, index) => index === this.activeListItemIndex,
      );
      if (key === 'enter') {
        // Select list item after ENTER pressed
        this.activeListItem?.select();
      } else {
        this.activeListItem?.highlight();
        this.activeListItem?.changeDetectorRef.detectChanges();
      }
    }
  }

  /**
   * Applies all the input properties of this list to its child list items
   * Only affects direct children of list, to allow nesting.
   */
  applyPropertiesToChildren(): void {
    if (this.listItemComponents) {
      this.listItemComponents.forEach((component, index, arr) => {
        // detach to avoid "changed after it was checked" shenanigans"
        component.changeDetectorRef.detach();
        component.bodyEdgeToEdge = this.bodyEdgeToEdge;
        component.bottomBorder = this.bottomBorder;
        component.clickable = this.clickable;
        component.expandable = this.expandable;
        component.indent = this.indent;
        component.inputAlignment = this.inputAlignment;
        component.padding = this.padding;
        component.paddingDesktop = this.paddingDesktop;
        component.horizontalPadding = this.horizontalPadding;
        component.horizontalPaddingDesktop = this.horizontalPaddingDesktop;
        component.selectable = this.selectable;
        component.selectByKeyboard = this.selectByKeyboard;
        component.transparent = this.transparent;
        component.outerPadding = this.outerPadding;
        component.type = this.type;
        component.first = index === 0;
        component.last = index === arr.length - 1;
        component.bordered = this.bordered;
        component.orderedListCounterType = this.orderedListCounterType;

        // reattach and detect changes;
        component.changeDetectorRef.reattach();
        component.changeDetectorRef.detectChanges();
      });
    }
  }
}
