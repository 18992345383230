import { mapValues } from '@freelancer/datastore/core';
import {
  average,
  generateArrayWithValues,
  generateIntegersInRange,
  generateNumbersInRange,
  randomiseList,
} from '@freelancer/datastore/testing/helpers';
import { RoleApi } from 'api-typings/common/common';
import type {
  Reputation,
  ReputationData,
  ReputationDataWithRehire,
} from './reputation.model';

interface Range {
  readonly min: number;
  readonly max: number;
}

export type FreelancerReputationKeys =
  | 'total'
  | 'completePercentage'
  | 'onBudgetPercentage'
  | 'onTimePercentage'
  | 'reviewsPercentage'
  | 'incompleteReviewsPercentage'
  | 'communication'
  | 'expertise'
  | 'hireAgain'
  | 'quality'
  | 'professionalism';

export type EmployerReputationKeys =
  | 'total'
  | 'completePercentage'
  | 'onBudgetPercentage'
  | 'reviewsPercentage'
  | 'incompleteReviewsPercentage'
  | 'communication'
  | 'expertise'
  | 'hireAgain'
  | 'quality'
  | 'professionalism';

export type GenerateFreelancerReputationDataOptions = {
  readonly [k in FreelancerReputationKeys]?: Range;
};
export type GenerateFreelancerReputationDataWithRehireOptions = {
  readonly [k in FreelancerReputationKeys | 'rehirePercentage']?: Range;
};

export type GenerateEmployerReputationDataWithRehireOptions = {
  readonly [k in EmployerReputationKeys | 'rehirePercentage']?: Range;
};

export type GenerateEmployerReputationDataOption = {
  readonly [k in FreelancerReputationKeys]: number;
};

export type GenerateFreelancerReputationDataOption = {
  readonly [k in FreelancerReputationKeys]: number;
};
export type GenerateFreelancerReputationDataWithRehireOption = {
  readonly [k in FreelancerReputationKeys | 'rehirePercentage']: number;
};

export const generateReputationObject = generateFreelancerReputationObjects;

// FIXME: T267853 - Make this more like generateFreelancerReputationsObjects
export function generateFreelancerReputationObjects({
  reputationOptions = {},
  earningsScore = { min: 4, max: 10 },
  userIds,
}: {
  readonly reputationOptions?: GenerateFreelancerReputationDataOptions;
  readonly earningsScore?: Range;
  readonly userIds: readonly number[];
}): readonly Reputation[] {
  const entireHistoryList = generateFreelancerReputationDataObjects({
    ...reputationOptions,
    seed: 'entireHistory',
    numberToMake: userIds.length,
  });

  const last3MonthsList = generateFreelancerReputationDataObjects({
    ...mapValues(reputationOptions, range =>
      range
        ? {
            min: range.min / 4,
            max: range.max / 4,
          }
        : undefined,
    ),
    seed: 'last3Months',
    numberToMake: userIds.length,
  });

  const last12MonthsList = generateFreelancerReputationDataObjects({
    ...mapValues(reputationOptions, range =>
      range
        ? {
            min: range.min / 2,
            max: range.max / 2,
          }
        : undefined,
    ),
    seed: 'last12Months',
    numberToMake: userIds.length,
  });

  const earningsScores = randomiseList(
    generateNumbersInRange(
      earningsScore.min,
      earningsScore.max,
      userIds.length,
    ),
    'earningsScore',
  );

  return userIds.map((userId, index) => ({
    userId,
    entireHistory: entireHistoryList[index],
    last3Months: last3MonthsList[index],
    last12Months: last12MonthsList[index],
    role: RoleApi.EMPLOYER,
    earningsScore: earningsScores[index],
  }));
}

export function generateFreelancerReputationDataObjects(
  options: GenerateFreelancerReputationDataOptions & {
    readonly seed: string;
    readonly numberToMake: number;
  },
): readonly ReputationData[] {
  return generateFreelancerReputationDataObjectsHelper({
    ...options,
    calculateRehireRate: false,
  });
}

export function generateFreelancerReputationDataWithRehireObjects(
  options: GenerateFreelancerReputationDataWithRehireOptions & {
    readonly seed: string;
    readonly numberToMake: number;
  },
): readonly ReputationDataWithRehire[] {
  return generateFreelancerReputationDataObjectsHelper({
    ...options,
    calculateRehireRate: true,
  });
}

export function generateEmployerReputationDataWithRehireObjects(
  options: GenerateEmployerReputationDataWithRehireOptions & {
    readonly seed: string;
    readonly numberToMake: number;
  },
): readonly ReputationDataWithRehire[] {
  return generateEmployerReputationDataObjectsHelper({
    ...options,
    calculateRehireRate: true,
  });
}

function generateFreelancerReputationDataObjectsHelper({
  seed,
  numberToMake,
  total = { min: 0, max: 1000 },
  communication = { min: 4, max: 5 },
  expertise = { min: 4, max: 5 },
  hireAgain = { min: 4, max: 5 },
  quality = { min: 4, max: 5 },
  professionalism = { min: 4, max: 5 },
  completePercentage = { min: 0.9, max: 1 },
  onBudgetPercentage = { min: 0.8, max: 1 },
  onTimePercentage = { min: 0.8, max: 1 },
  rehirePercentage = { min: 0.75, max: 1 },
  reviewsPercentage = { min: 0.75, max: 1 },
  incompleteReviewsPercentage = { min: 0.25, max: 0.5 },
  calculateRehireRate,
}: GenerateFreelancerReputationDataWithRehireOptions & {
  readonly seed: string;
  readonly numberToMake: number;
  readonly calculateRehireRate: boolean;
}): readonly ReputationData[] | readonly ReputationDataWithRehire[] {
  // Generate a third of the reviews as blank ones.
  const blankReviews = Math.floor(numberToMake / 3);

  // The total number of projects done, a combo of random numbers and blank ones.
  const totalCounts = randomiseList(
    [
      ...generateIntegersInRange(
        total.min,
        total.max,
        numberToMake - blankReviews,
      ),
      ...generateArrayWithValues(blankReviews, 0),
    ],
    'totalCounts',
  );

  const genRange = (
    { min, max }: Range,
    extraSeed: string,
  ): readonly number[] =>
    randomiseList(
      generateNumbersInRange(min, max, numberToMake),
      seed + extraSeed,
    );

  const communications = genRange(communication, 'communication');
  const expertises = genRange(expertise, 'expertise');
  const hireAgains = genRange(hireAgain, 'hireAgain');
  const qualities = genRange(quality, 'quality');
  const professionalisms = genRange(professionalism, 'professionalism');
  const completePercentages = genRange(
    completePercentage,
    'completePercentage',
  );
  const onBudgetPercentages = genRange(
    onBudgetPercentage,
    'onBudgetPercentage',
  );
  const onTimePercentages = genRange(onTimePercentage, 'onTimePercentage');
  const rehirePercentages = genRange(rehirePercentage, 'rehirePercentage');
  const reviewsPercentages = genRange(reviewsPercentage, 'reviewsPercentage');
  const incompleteReviewsPercentages = genRange(
    incompleteReviewsPercentage,
    'incompleteReviewsPercentage',
  );

  return totalCounts.map((totalCount, i) => {
    const options: GenerateFreelancerReputationDataOption = {
      total: totalCount,
      completePercentage: completePercentages[i],
      reviewsPercentage: reviewsPercentages[i],
      incompleteReviewsPercentage: incompleteReviewsPercentages[i],
      communication: communications[i],
      expertise: expertises[i],
      hireAgain: hireAgains[i],
      quality: qualities[i],
      professionalism: professionalisms[i],
      onBudgetPercentage: onBudgetPercentages[i],
      onTimePercentage: onTimePercentages[i],
    };

    return calculateRehireRate
      ? generateReputationDataWithRehireObject({
          ...options,
          rehirePercentage: rehirePercentages[i],
        })
      : generateReputationDataObject(options);
  });
}

function generateEmployerReputationDataObjectsHelper({
  seed,
  numberToMake,
  total = { min: 0, max: 1000 },
  communication = { min: 4, max: 5 },
  expertise = { min: 4, max: 5 },
  hireAgain = { min: 4, max: 5 },
  quality = { min: 4, max: 5 },
  professionalism = { min: 4, max: 5 },
  completePercentage = { min: 0.9, max: 1 },
  onBudgetPercentage = { min: 0.8, max: 1 },
  onTimePercentage = { min: 0.8, max: 1 },
  rehirePercentage = { min: 0.75, max: 1 },
  reviewsPercentage = { min: 0.75, max: 1 },
  incompleteReviewsPercentage = { min: 0.25, max: 0.5 },
  calculateRehireRate,
}: GenerateFreelancerReputationDataWithRehireOptions & {
  readonly seed: string;
  readonly numberToMake: number;
  readonly calculateRehireRate: boolean;
}): readonly ReputationData[] | readonly ReputationDataWithRehire[] {
  // Generate a third of the reviews as blank ones.
  const blankReviews = Math.floor(numberToMake / 3);

  // The total number of projects done, a combo of random numbers and blank ones.
  const totalCounts = randomiseList(
    [
      ...generateIntegersInRange(
        total.min,
        total.max,
        numberToMake - blankReviews,
      ),
      ...generateArrayWithValues(blankReviews, 0),
    ],
    'totalCounts',
  );

  const genRange = (
    { min, max }: Range,
    extraSeed: string,
  ): readonly number[] =>
    randomiseList(
      generateNumbersInRange(min, max, numberToMake),
      seed + extraSeed,
    );

  const communications = genRange(communication, 'communication');
  const expertises = genRange(expertise, 'expertise');
  const hireAgains = genRange(hireAgain, 'hireAgain');
  const qualities = genRange(quality, 'quality');
  const professionalisms = genRange(professionalism, 'professionalism');
  const completePercentages = genRange(
    completePercentage,
    'completePercentage',
  );
  const onBudgetPercentages = genRange(
    onBudgetPercentage,
    'onBudgetPercentage',
  );
  const onTimePercentages = genRange(onTimePercentage, 'onTimePercentage');
  const rehirePercentages = genRange(rehirePercentage, 'rehirePercentage');
  const reviewsPercentages = genRange(reviewsPercentage, 'reviewsPercentage');
  const incompleteReviewsPercentages = genRange(
    incompleteReviewsPercentage,
    'incompleteReviewsPercentage',
  );

  return totalCounts.map((totalCount, i) => {
    const options: GenerateFreelancerReputationDataOption = {
      total: totalCount,
      completePercentage: completePercentages[i],
      reviewsPercentage: reviewsPercentages[i],
      incompleteReviewsPercentage: incompleteReviewsPercentages[i],
      communication: communications[i],
      expertise: expertises[i],
      hireAgain: hireAgains[i],
      quality: qualities[i],
      professionalism: professionalisms[i],
      onBudgetPercentage: onBudgetPercentages[i],
      onTimePercentage: onTimePercentages[i],
    };

    return calculateRehireRate
      ? generateReputationDataWithRehireObject({
          ...options,
          rehirePercentage: rehirePercentages[i],
        })
      : generateReputationDataObject(options);
  });
}

export function generateReputationDataObject({
  total,
  completePercentage,
  reviewsPercentage,
  incompleteReviewsPercentage,
  communication,
  expertise,
  hireAgain,
  quality,
  professionalism,
  onBudgetPercentage,
  onTimePercentage,
}: GenerateFreelancerReputationDataOption): ReputationData {
  const complete = Math.floor(total * completePercentage);
  const incomplete = total - complete;

  const reviews = Math.floor(total * reviewsPercentage);
  const incompleteReviews = Math.floor(
    (total - reviews) * incompleteReviewsPercentage,
  );

  const categoryRatings = {
    communication: blankValue(total, communication),
    expertise: blankValue(total, expertise),
    hire_again: blankValue(total, hireAgain),
    quality: blankValue(total, quality),
    professionalism: blankValue(total, professionalism),
  };

  return {
    overall: average(Object.values(categoryRatings)) || 0,
    all: total,

    complete,
    incomplete,
    reviews,
    incompleteReviews,

    onBudget: blankValue(total, onBudgetPercentage),
    onTimeInMs: blankValue(total, onTimePercentage),
    completionRate: blankValue(total, Math.round((complete / total) * 100)),

    positive: 0, // This doesn't seem to be set for new reviews?
    categoryRatings,
  };
}

export function generateReputationDataWithRehireObject(
  options: GenerateFreelancerReputationDataWithRehireOption,
): ReputationDataWithRehire {
  return {
    ...generateReputationDataObject(options),
    rehireRate: blankValue(
      options.total,
      options.rehirePercentage ?? { min: 0.6, max: 1 },
    ),
  };
}

// Set value to 0 if you've not done any work.
function blankValue(totalCount: number, value: number): number {
  return totalCount === 0 ? 0 : value;
}
