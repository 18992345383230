import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import type { SearchItem } from '@freelancer/ui/search';
import { TextSize } from '@freelancer/ui/text';
import { componentStoryMap } from '../../generated/component-story-map';
import { directiveStoryMap } from '../../generated/directive-story-map';
import { patternStoryMap } from '../../generated/patterns-story-map';
import { pipeStoryMap } from '../../generated/pipe-story-map';
import { prettyPrint } from '../../helpers';

interface StoryMap {
  [name: string]: {
    path: string;
    tags: readonly string[];
    internalOnly: boolean;
    stories: readonly string[];
  };
}

@Component({
  selector: 'app-search',
  template: `
    <fl-search
      [itemTypeTitles]="{
        info: 'Info',
        foundations: 'Foundations',
        components: 'Components',
        directives: 'Directives',
        pipes: 'Pipes',
        patterns: 'Patterns',
        motion: 'Motion System',
        brand: 'Brand Style Guide'
      }"
      [itemTemplates]="{
        info: searchItemTemplateRef,
        foundations: searchItemTemplateRef,
        components: searchItemTemplateRef,
        directives: searchItemTemplateRef,
        pipes: searchItemTemplateRef,
        patterns: searchItemTemplateRef,
        motion: searchItemTemplateRef,
        brand: searchItemTemplateRef
      }"
      [scrollableSearchResults]="true"
      [searchResults]="searchResults"
      [displayResults]="displaySearchResults"
      [isExpandable]="isExpandable"
      [expandableIconLight]="expandableIconLight"
      (query)="handleQuery($event)"
      (select)="handleSelection($event)"
    >
      <ng-template
        #searchItemTemplateRef
        let-item
      >
        <fl-text [size]="TextSize.XXSMALL">{{ item.name }}</fl-text>
      </ng-template>
    </fl-search>
  `,
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  TextSize = TextSize;

  displaySearchResults = true;
  searchResults: readonly SearchItem[] = [];
  searchItems: readonly SearchItem[];

  @Input() isExpandable = false;
  @Input() expandableIconLight = false;
  @Input() hideInternalPages = true;

  readonly internalOnlyStaticPages = ['how-to-contribute', 'report-bugs'];
  readonly infoPages: readonly string[] = [
    'introduction',
    'getting-started',
    'faq',
    'how-to-contribute',
    'report-bugs',
  ];
  readonly foundationPages: readonly string[] = [
    'accessibility',
    'colors',
    'grid',
    'spacing',
    'typography',
    'illustrations',
    'copywriting',
    'layouts',
    'forms',
    'error-handling',
  ];
  readonly motionPages: readonly string[] = [
    'getting-started',
    'attention-seeker',
    'fade',
    'slide',
  ];
  readonly brandPages: readonly string[] = ['brand-style-guide'];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.searchItems = [
      ...this.generateSearchItems(
        'info',
        this.getStaticPageList(this.infoPages),
      ),
      ...this.generateSearchItems(
        'foundations',
        this.getStaticPageList(this.foundationPages),
      ),
      ...this.generateSearchItems(
        'components',
        this.getStoryPageList(componentStoryMap, 'components'),
      ),
      ...this.generateSearchItems(
        'directives',
        this.getStoryPageList(directiveStoryMap, 'directives'),
      ),
      ...this.generateSearchItems(
        'pipes',
        this.getStoryPageList(pipeStoryMap, 'pipes'),
      ),
      ...this.generateSearchItems(
        'patterns',
        this.getStoryPageList(patternStoryMap, 'patterns'),
      ),
      ...this.generateSearchItems(
        'motion',
        this.getStaticPageList(this.motionPages),
      ),
      ...this.generateSearchItems(
        'brand',
        this.getStaticPageList(this.brandPages),
      ),
    ];
  }

  getStaticPageList(items: readonly string[]): readonly string[] {
    if (!this.hideInternalPages) {
      return items;
    }

    return items.filter(item => !this.internalOnlyStaticPages.includes(item));
  }

  getStoryPageList(items: StoryMap, type: string): readonly string[] {
    return Object.entries(items)
      .flatMap(([key, value]) => {
        // Add the component name itself
        const pages: string[] = [key];

        // Add each story with the format "ComponentName/StoryName"
        if (value.stories) {
          pages.push(...value.stories.map(story => `${key}/${story}`));
        }

        return pages;
      })
      .filter(page => {
        // Filter out internal-only pages if hideInternalPages is true
        if (this.hideInternalPages) {
          const componentName = page.split('/')[0];
          return !items[componentName]?.internalOnly;
        }
        return true;
      });
  }

  generateSearchItems(
    type: string,
    storyMap: readonly string[],
  ): readonly SearchItem[] {
    return storyMap
      .map(item => {
        const [component, story] = item.split('/');

        return {
          type,
          context: {
            name: story
              ? `${prettyPrint(component)}/${prettyPrint(story)}`
              : prettyPrint(item),
            link: `/${type}/${item}`,
          },
          displayValue: story
            ? `${prettyPrint(component)}/${prettyPrint(story)}`
            : prettyPrint(item),
        };
      })
      .sort((a, b) => (a.context.name < b.context.name ? -1 : 1));
  }

  handleQuery(query: string): void {
    this.displaySearchResults = true;
    const queryLower = query.toLowerCase();

    // Normalize the query by replacing spaces with slashes
    const normalizedQuery = queryLower.replace(/\s+/g, '/');

    // Check if any component matches the exact query or normalized query
    const matchingComponent = this.searchItems.find(
      item =>
        item.type === 'components' &&
        (item.context.name.toLowerCase() === queryLower ||
          item.context.name.toLowerCase() === normalizedQuery),
    );

    this.searchResults = this.searchItems.filter(item => {
      const itemNameLower = item.displayValue?.toLowerCase();

      // Exclude stories of the exact matching component
      if (
        matchingComponent &&
        item.context.link.startsWith(
          `/components/${matchingComponent.context.name.toLowerCase()}/`,
        )
      ) {
        return false;
      }

      // Include items whose displayValue matches the query or normalized query
      return (
        itemNameLower?.includes(queryLower) ||
        itemNameLower?.includes(normalizedQuery)
      );
    });
  }

  handleSelection(selection: SearchItem): void {
    this.displaySearchResults = false;
    this.router.navigate([selection.context.link]);
  }
}
