import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {
  ColumnNumber,
  ColumnNumberTablet,
  ColumnNumberDesktopSmall,
  ColumnNumberDesktopLarge,
  ColumnNumberDesktopXLarge,
  ColumnNumberDesktopXXLarge,
} from './column.types';

type ColumnOrder = 1 | 2 | 3 | 4 | 5 | 6;
type TabletColumnOrder = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
type DesktopSmallColumnOrder = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type DesktopLargeColumnOrder = 1 | 2 | 3;
type DesktopXLargeColumnOrder = 1 | 2 | 3;
type DesktopXXLargeColumnOrder = 1 | 2 | 3;
type ColumnPull = 'left' | 'right';

@Component({
  selector: 'fl-col',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnComponent {
  @HostBinding('attr.data-col')
  @Input()
  col?: ColumnNumber;
  @HostBinding('attr.data-order')
  @Input()
  colOrder?: ColumnOrder;

  @HostBinding('attr.data-col-tablet')
  @Input()
  colTablet?: ColumnNumberTablet;
  @HostBinding('attr.data-tablet-order')
  @Input()
  colTabletOrder?: TabletColumnOrder;

  @HostBinding('attr.data-col-desktop-small')
  @Input()
  colDesktopSmall?: ColumnNumberDesktopSmall;
  @HostBinding('attr.data-desktop-small-order')
  @Input()
  colDesktopSmallOrder?: DesktopSmallColumnOrder;

  @HostBinding('attr.data-col-desktop-large')
  @Input()
  colDesktopLarge?: ColumnNumberDesktopLarge;
  @HostBinding('attr.data-desktop-large-order')
  @Input()
  colDesktopLargeOrder?: DesktopLargeColumnOrder;

  @HostBinding('attr.data-col-desktop-xlarge')
  @Input()
  colDesktopXLarge?: ColumnNumberDesktopXLarge;
  @HostBinding('attr.data-desktop-xlarge-order')
  @Input()
  colDesktopXLargeOrder?: DesktopXLargeColumnOrder;

  @HostBinding('attr.data-col-desktop-xxlarge')
  @Input()
  colDesktopXXLarge?: ColumnNumberDesktopXXLarge;
  @HostBinding('attr.data-desktop-xxlarge-order')
  @Input()
  colDesktopXXLargeOrder?: DesktopXXLargeColumnOrder;

  @HostBinding('attr.data-pull')
  @Input()
  pull?: ColumnPull;

  @HostBinding('attr.data-flex-container')
  @Input()
  flexContainer = false;
}
