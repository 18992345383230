import type {
  ProjectExperiments,
  SessionExperiments,
  UserExperiments,
} from '../experiments';

export const sessionExperiments: Partial<SessionExperiments> = {
  'T301279-pjp-omni-update-test': 'control',
  'T298055-google-one-tap': 'control',
  'T306009-username-prefill': 'control',
};

export const userExperiments: Partial<UserExperiments> = {
  'T111865-logged-in-pjp-live-chat-just-enrollment': 'control',
  'T90189-image-thumbnail-service': 'control',
  webapp_playground_test: 'control',
  'T195472-web-push-notifications': 'control',
  'T281208-group-search-by-name': 'control',
  'T296533-freelancer-onboarding-remove-payment-verification-step': 'control',
  'T299134-activation-remarketing-release-milestone-noop-v2': 'control',
  'T301219-chain-funding': 'control',
  'T286144-share-revamp-v2': 'control',
  'T303953-chatbox-on-profile-v2': 'control',
  'T302575-project-workspace-page-v2': 'control',
  'T304311-shortlist-bids': 'control',
  'T302868-enforce-dark-mode': 'control',
  'T302944-enforce-dark-mode-noop': 'control',
  'T305849-applepay-as-default-option-v3': 'control',
  'T304568-hourly-upfront-fee': 'control',
  'T307941-milestone-cancellation-confirmation': 'control',
  'T298196-dynamic-bid-tags-noop': 'control',
  'T307404-logged-in-menus-noop': 'control',
  'T308216-newsfeed-project-bids-noop': 'control',
  'T301037-rehire-on-dashboard': 'control',
  'T307671-unrolling-payment-methods-noop': 'control',
};
export const projectExperiments: Partial<ProjectExperiments> = {};

export const isWhitelistUser = false;

export const shouldEnrol = true;
