import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Assets } from '../assets';
import { FlagSize } from './flag.types';

@Component({
  selector: 'fl-flag',
  template: `
    <img
      alt="Flag of {{ country | uppercase }}"
      class="FlagImage"
      i18n-alt="Flag alt text"
      role="presentation"
      title="{{ country }}"
      [attr.data-size]="size"
      [attr.data-size-tablet]="sizeTablet"
      [attr.data-size-desktop]="sizeDesktop"
      [attr.data-size-desktop-large]="sizeDesktopLarge"
      [src]="imagePath"
      (error)="onImageFetchError()"
    />
  `,
  styleUrls: ['./flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagComponent implements OnChanges {
  private forceUnknown = false;

  imagePath = '';

  @Input() countryCode = 'unknown';
  @Input() size = FlagSize.MID;
  @Input() sizeTablet?: FlagSize;
  @Input() sizeDesktop?: FlagSize;
  @Input() sizeDesktopLarge?: FlagSize;
  @Input() country: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private assets: Assets,
  ) {}

  onImageFetchError(): void {
    if (!this.forceUnknown) {
      // Well this sucks. Show the unknown flag for now.
      this.forceUnknown = true;
      this.imagePath = this.getFlagImage();
      this.changeDetectorRef.markForCheck();
    }
  }

  private getFlagImage(): string {
    if (this.forceUnknown || !this.countryCode) {
      return this.assets.getUrl('flags/unknown.png');
    }
    return this.assets.getUrl(`flags/${this.countryCode.toLowerCase()}.png`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('countryCode' in changes) {
      // There's a possibility that the new `countryCode` can be a countryCode with an existing flag. Flip
      // forceUnknown to false to check.
      this.forceUnknown = false;
      this.imagePath = this.getFlagImage();
    }
  }
}
