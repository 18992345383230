import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { Routes } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { IconSize } from '@freelancer/ui/icon';
import type { Tab } from '@freelancer/ui/tabs';
import { TabsDirection, TabsSize } from '@freelancer/ui/tabs';
import { TextSize } from '@freelancer/ui/text';
import { isDefined } from '@freelancer/utils';
import { appRoutes } from 'bits/app-routing.module';
import { HomeComponent } from 'bits/home/home.component';
import { componentStoryMap } from '../../generated/component-story-map';
import { prettyPrint } from '../../helpers';

interface SidebarCategoryMap {
  [k: string]: readonly string[];
}

interface SidebarStoryMap {
  [name: string]: {
    path: string;
    internalOnly: boolean;
    tags: readonly string[];
    stories: readonly string[];
  };
}

interface SidebarItem {
  title: string;
  tabs: readonly Tab[];
}

@Component({
  selector: 'app-sidebar',
  template: `
    <div
      class="SidebarHeader"
      [flMarginBottom]="'small'"
    >
      <fl-heading
        [headingType]="HeadingType.H2"
        [weight]="HeadingWeight.NORMAL"
        [size]="TextSize.MARKETING_SMALL"
      >
        {{ title }}
      </fl-heading>
      <fl-icon
        class="SidebarHeader-action"
        [name]="'ui-sidebar-left'"
        [size]="IconSize.SMALL"
        [flShowMobile]="true"
        [clickable]="true"
        (click)="handleCloseSidebar()"
      ></fl-icon>
    </div>
    <fl-scrollable-content class="SidebarContent">
      <ng-container *ngFor="let item of sidebarItems; trackBy: trackByTabTitle">
        <ng-container *ngIf="isComponentsPage; else tabs">
          <div
            class="Header"
            [flMarginBottom]="'xxsmall'"
            [flMarginRight]="'xxsmall'"
            (click)="toggleSection(item.title, content)"
          >
            <fl-heading
              class="CategoryTitle"
              [headingType]="HeadingType.H3"
              [weight]="HeadingWeight.MEDIUM"
              [size]="TextSize.XSMALL"
            >
              {{ item.title }}
            </fl-heading>

            <fl-icon
              [name]="
                expandedSections[item.title] ? 'ui-minus-thin' : 'ui-plus-thin'
              "
              [size]="IconSize.XSMALL"
            ></fl-icon>
          </div>

          <div
            #content
            class="Content"
            [class.Expanded]="expandedSections[item.title]"
            [class.Collapsed]="!expandedSections[item.title]"
          >
            <ng-container
              *ngIf="isComponentsPage"
              [ngTemplateOutlet]="tabs"
            ></ng-container>
          </div>
        </ng-container>
        <ng-template #tabs>
          <fl-tabs
            [direction]="TabsDirection.COLUMN_RIGHT"
            [size]="TabsSize.XSMALL"
          >
            <fl-tab-item
              *ngFor="let tab of item.tabs; trackBy: trackByTabTitle"
              [titleText]="tab.title"
              [link]="tab.link"
              [routeHasChildren]="true"
            ></fl-tab-item>
          </fl-tabs>
        </ng-template>
      </ng-container>
    </fl-scrollable-content>
  `,
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  IconSize = IconSize;
  TabsDirection = TabsDirection;
  TabsSize = TabsSize;
  TextSize = TextSize;
  bitsRoutes = appRoutes;

  sidebarItems: readonly SidebarItem[];
  title: string;
  isComponentsPage = false;
  expandedSections: { [key: string]: boolean } = {};
  sectionHeights: { [key: string]: number } = {}; // Store the height of each section

  @Output() closeSidebar = new EventEmitter<void>();
  @Input() hideInternalPages = true;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.generateSidebarItems();
    this.initializeExpandedSections();
    this.checkIfComponentsPage(); // Check if we are on the /components page
  }

  private initializeExpandedSections(): void {
    this.expandedSections = Object.fromEntries(
      this.sidebarItems.map(item => [item.title, true]),
    );
  }

  toggleSection(title: string, content: HTMLElement): void {
    // Toggle the section's expanded/collapsed state
    this.expandedSections[title] = !this.expandedSections[title];

    // Set the max-height dynamically
    if (this.expandedSections[title]) {
      this.sectionHeights[title] = content.scrollHeight + 8;
    } else {
      // Collapse the section
      this.sectionHeights[title] = 0;
    }
  }

  generateSidebarItems(): void {
    const { routeConfig } = this.activatedRoute.snapshot;

    if (!routeConfig) {
      return;
    }

    const { path } = routeConfig;
    let { children } = routeConfig;

    if (!isDefined(path)) {
      return;
    }

    this.title = prettyPrint(path);

    if (routeConfig.component === HomeComponent) {
      this.title = 'Bits Homepage';
      children = appRoutes[1].children;
    }

    /** For now, we're only categorizing the components */
    if (path === 'components') {
      this.sidebarItems = this.getCategorizedTabs(path, componentStoryMap);
      return;
    }

    if (isDefined(children)) {
      this.sidebarItems = this.getUnCategorizedTabs(path, children);
    }
  }

  private checkIfComponentsPage(): void {
    this.isComponentsPage = this.activatedRoute.snapshot.url
      .map(segment => segment.path)
      .includes('components');
  }

  /**
   * Categorized stories based on their tag
   * defined in their respective readme.md
   */
  private getCategorizedTabs(
    route: string,
    storyMap: SidebarStoryMap,
  ): readonly SidebarItem[] {
    const groupedStories = Object.keys(storyMap).reduce(
      (all: SidebarCategoryMap, category) => {
        if (!storyMap[category]) {
          return all;
        }

        // use a first tag as its category
        const tag = storyMap[category].tags[0];

        return {
          ...all,
          [tag]: all[tag] ? [...all[tag], category] : [category],
        };
      },
      {},
    );

    return Object.entries(groupedStories)
      .sort()
      .map(([category, children]) => ({
        title: prettyPrint(category || ''),
        tabs: children
          .filter(item => {
            if (!this.hideInternalPages) {
              return true;
            }

            return !storyMap[item].internalOnly;
          })
          .map(item => ({
            link: `/${route}/${item}`,
            title: prettyPrint(item || ''),
          })),
      }))
      .filter(story => story.tabs.length > 0);
  }

  /**
   * Uncategorized version of the tabs
   */
  private getUnCategorizedTabs(
    path: string,
    items: Routes,
  ): readonly SidebarItem[] {
    return [
      {
        title: '',
        tabs: items
          .filter(item => isDefined(item.path) && item.path !== '')
          .filter(item => {
            if (!this.hideInternalPages || !isDefined(item.data)) {
              return true;
            }

            return !item.data.internalOnly;
          })
          .map(item => ({
            link: `/${path}/${item.path}`,
            title: prettyPrint(item.path || ''),
          })),
      },
    ];
  }

  handleCloseSidebar(): void {
    this.closeSidebar.emit();
  }

  trackByTabTitle(_: number, tab: Tab | SidebarItem): string {
    return tab.title;
  }
}
