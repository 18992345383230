export enum ListItemType {
  CHECKBOX = 'checkbox',
  DEFAULT = 'default',
  DISMISS = 'dismiss',
  RADIO = 'radio',
  TOGGLE = 'toggle',
  ORDERED = 'ordered',
  UNORDERED = 'unordered',
}

export enum ListItemPadding {
  XXXSMALL = 'xxxsmall',
  XXSMALL = 'xxsmall',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MID = 'mid',
  LARGE = 'large',
  NONE = 'none',
}

export enum ListItemInputAlignment {
  TOP = 'top',
  CENTER = 'middle',
}

export enum ListItemSelectedType {
  LEFT_BORDER = 'left_border',
  FILLED = 'filled',
}

export enum ListItemOrderedCounterType {
  DECIMAL = 'decimal',
  LOWER_ALPHA = 'lower-alpha',
  UPPER_ALPHA = 'upper-alpha',
  LOWER_ROMAN = 'lower-roman',
}
