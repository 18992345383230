import { RoleApi } from 'api-typings/common/common';
import type {
  GenerateEmployerReputationDataOption,
  GenerateEmployerReputationDataWithRehireOptions,
} from '../reputation/reputation-data.seed';
import { generateEmployerReputationDataWithRehireObjects } from '../reputation/reputation-data.seed';
import type {
  EmployerReputation,
  ProjectStats,
} from './employer-reputations-rehire-rates.model';

export const defaultEmployerReputations: GenerateEmployerReputationDataOption =
  {
    total: 75,
    completePercentage: 0.9,
    reviewsPercentage: 0.9,
    incompleteReviewsPercentage: 0.9,
    communication: 4.5,
    expertise: 4.5,
    hireAgain: 4.5,
    quality: 4.5,
    professionalism: 4.5,
    onBudgetPercentage: 0.9,
    onTimePercentage: 0.9,
  };

export interface GenerateEmployerReputationsWithRehireOptions {
  readonly employerIds: readonly number[];
  readonly reputationOptions?: GenerateEmployerReputationDataWithRehireOptions;
  readonly projectStats?: ProjectStats;
}

/** Keep in sync with generateEmployerReputationsObjects */
export function generateEmployerReputationsRehireRatesObjects({
  employerIds,
  reputationOptions = {},
  projectStats = {
    open: 0,
    work_in_progress: 0,
    complete: 0,
    pending: 0,
    draft: 0,
  },
}: GenerateEmployerReputationsWithRehireOptions): readonly EmployerReputation[] {
  const entireHistories = generateEmployerReputationDataWithRehireObjects({
    ...reputationOptions,
    seed: 'entireHistory',
    numberToMake: employerIds.length,
  });

  return employerIds.map((employerId, i) => ({
    id: employerId,
    role: RoleApi.EMPLOYER,
    earningsScore: 0,
    entireHistory: entireHistories[i],
    projectStats,
  }));
}
