import { DepositMethodApi } from 'api-typings/payments/payments';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { UserDepositMethod } from './user-deposit-methods.model';

export function generateUserDepositMethodsObjects(
  user: GenerateUserDepositMethodsOptions,
): readonly UserDepositMethod[] {
  return [
    {
      userId: user.userId,
      id: 'fln_billing',
      methodId: DepositMethodApi.FLN_BILLING,
      name: 'Debit or Credit Card',
      nativeCharge: true,
      instantPayment: true,
      renderingPriority: 0,
      defaultCurrency: generateCurrencyObject(CurrencyCode.USD),
      depositFees: [
        {
          id: 1,
          depositMethod: DepositMethodApi.FLN_BILLING,
          feeFixedAmount: 0.3,
          feeRate: 0.023,
          maxAmount: 100_000,
          minAmount: 1,
        },
        {
          id: 3,
          depositMethod: DepositMethodApi.FLN_BILLING,
          feeFixedAmount: 0.3,
          feeRate: 0.023,
          maxAmount: 100_000,
          minAmount: 1,
        },
        {
          id: 11,
          depositMethod: DepositMethodApi.FLN_BILLING,
          feeFixedAmount: 15,
          feeRate: 0.023,
          maxAmount: 6_160_000,
          minAmount: 100,
        },
      ],
    },
    {
      userId: user.userId,
      id: 'wire',
      methodId: DepositMethodApi.WIRE,
      name: 'Bank Deposit',
      nativeCharge: true,
      instantPayment: false,
      renderingPriority: 2,
      defaultCurrency: generateCurrencyObject(CurrencyCode.USD),
      depositFees: [
        {
          id: 1,
          depositMethod: DepositMethodApi.FLN_BILLING,
          feeFixedAmount: 0.3,
          feeRate: 0.023,
          maxAmount: 100_000,
          minAmount: 1,
        },
        {
          id: 3,
          depositMethod: DepositMethodApi.FLN_BILLING,
          feeFixedAmount: 0.3,
          feeRate: 0.023,
          maxAmount: 100_000,
          minAmount: 1,
        },
        {
          id: 11,
          depositMethod: DepositMethodApi.FLN_BILLING,
          feeFixedAmount: 15,
          feeRate: 0.023,
          maxAmount: 6_160_000,
          minAmount: 100,
        },
      ],
      bankConfigs: [
        {
          id: 1,
          bankInfo:
            'A/C Name: Freelancer International Pty Ltd\r\nAccount#: 3300655342\r\nBank Name: SILICON VALLEY BANK\r\nBranch Address: 3003 TASMAN DRIVE, SANTA CLARA, CA 95054\r\nABA/Routing Number: 121140399\r\nSwift Code: SVBKUS6S',
        },
        {
          id: 3,
          bankInfo:
            'A/c Name: Freelancer International Pty Ltd\r\nBSB: 082057\r\nAccount #: 941995281\r\nBank: National Australia Bank\r\nSWIFT: NATAAU3303M (Only needed for international transfers)\r\nBranch: National Aust Bank House\r\nAddress: 255 George Street, Sydney NSW 2000, Australia',
        },
        {
          id: 11,
          bankInfo:
            'A/C Name: FREELANCER ONLINE INDIA PVT LIMITED\nAccount#: 04822320000812\nBank Name: HDFC\nBranch: ANANDLOK\nBranch Address: 14 ANANDLOK NEW DELHI-110049\nSWIFT: HDFCINBB\n',
        },
      ],
    },
    {
      userId: user.userId,
      id: 'team_billing',
      methodId: DepositMethodApi.TEAM_BILLING,
      name: 'Team billing',
      nativeCharge: true,
      renderingPriority: 0,
      instantPayment: true,
      defaultCurrency: generateCurrencyObject(CurrencyCode.USD),
      depositFees: [
        {
          id: 1,
          minAmount: 1,
          maxAmount: 100_000,
          depositMethod: DepositMethodApi.TEAM_BILLING,
          feeFixedAmount: 0,
          feeRate: 0,
        },
      ],
    },
    {
      userId: user.userId,
      id: 'paypal',
      methodId: DepositMethodApi.PAYPAL,
      name: 'PayPal',
      nativeCharge: false,
      renderingPriority: 0,
      instantPayment: true,
      defaultCurrency: generateCurrencyObject(CurrencyCode.USD),
      depositFees: [
        {
          id: 1,
          minAmount: 1,
          maxAmount: 100_000,
          depositMethod: DepositMethodApi.PAYPAL,
          feeFixedAmount: 0.3,
          feeRate: 0.023,
        },
      ],
    },
    {
      userId: user.userId,
      id: 'applepay',
      methodId: DepositMethodApi.APPLEPAY,
      name: 'Apple Pay',
      nativeCharge: false,
      renderingPriority: 0,
      instantPayment: true,
      defaultCurrency: generateCurrencyObject(CurrencyCode.USD),
      depositFees: [
        {
          id: 1,
          minAmount: 1,
          maxAmount: 100_000,
          depositMethod: DepositMethodApi.APPLEPAY,
          feeFixedAmount: 0.3,
          feeRate: 0.023,
        },
      ],
    },
    {
      userId: user.userId,
      id: 'applepay_reference',
      methodId: DepositMethodApi.APPLEPAY_REFERENCE,
      name: 'Apple Pay',
      nativeCharge: true,
      renderingPriority: 26,
      instantPayment: true,
      defaultCurrency: generateCurrencyObject(CurrencyCode.USD),
      depositFees: [
        {
          id: 1,
          minAmount: 1,
          maxAmount: 100_000,
        },
      ],
    },
    // Setting up a mock user deposit method for testing purposes (T307671)
    {
      userId: user.userId,
      id: 'paytm',
      methodId: DepositMethodApi.PAYTM,
      name: 'Paytm',
      nativeCharge: false,
      renderingPriority: 5,
      instantPayment: false,
      defaultCurrency: generateCurrencyObject(CurrencyCode.INR),
      depositFees: [
        {
          id: 1,
          minAmount: 1,
          maxAmount: 100_000,
        },
      ],
    },
    {
      userId: user.userId,
      id: 'ideal',
      methodId: DepositMethodApi.IDEAL,
      name: 'iDEAL',
      nativeCharge: false,
      renderingPriority: 28,
      instantPayment: false,
      defaultCurrency: generateCurrencyObject(CurrencyCode.EUR),
      depositFees: [
        {
          id: 1,
          minAmount: 1,
          maxAmount: 100_000,
        },
      ],
    },
    {
      userId: user.userId,
      id: 'skrill',
      methodId: DepositMethodApi.SKRILL,
      name: 'Skrill',
      nativeCharge: false,
      renderingPriority: 30,
      instantPayment: false,
      defaultCurrency: generateCurrencyObject(CurrencyCode.EUR),
      depositFees: [
        {
          id: 1,
          minAmount: 1,
          maxAmount: 100_000,
        },
      ],
    },
    {
      userId: user.userId,
      id: 'bancontact',
      methodId: DepositMethodApi.BANCONTACT,
      name: 'Bancontact',
      nativeCharge: false,
      renderingPriority: 31,
      instantPayment: false,
      defaultCurrency: generateCurrencyObject(CurrencyCode.EUR),
      depositFees: [
        {
          id: 1,
          minAmount: 1,
          maxAmount: 100_000,
        },
      ],
    },
  ];
}

export interface GenerateUserDepositMethodsOptions {
  readonly userId: number;
}
