import type {
  AdminChargeUserFeeCollection,
  AdminChargeUserFeeModel,
  AdminEnterpriseModel,
  AdminEnterprisesCollection,
  AdminIrisOffsitingCollection,
  AdminIrisOffsitingModel,
  AdminPcbBanManagementCollection,
  AdminPcbBanManagementModel,
  AdminUserDocumentSubmissionsCollection,
  AdminUserEnterprisesCollection,
  AgentSession,
  AgentSessionsCollection,
  AiChangeTone,
  AiChangeToneCollection,
  AiConsultantChatHistory,
  AiConsultantCollection,
  AiContinueWriting,
  AiContinueWritingCollection,
  AiImproveWriting,
  AiImproveWritingCollection,
  AiProofread,
  AiProofreadCollection,
  BidDescription,
  BidDescriptionCollection,
  BidPitchCollection,
  BidsCollection,
  CartApproval,
  CartApprovalsCollection,
  CartItemsCollection,
  CartsCollection,
  Certification,
  CertificationsCollection,
  CommentFeedsCollection,
  CommentsCollection,
  ContactUs,
  ContactUsCollection,
  ContestComment,
  ContestCommentsCollection,
  ContestEntriesCollection,
  ContestEntry,
  ContestEntryFile,
  ContestFile,
  ContestFilesCollection,
  ContestRubricsCollection,
  ContestsCollection,
  ContractSigningUrl,
  ContractSigningUrlsCollection,
  CopilotEnquiriesCollection,
  CopilotEnquiry,
  CountryCode,
  CustomBanner,
  CustomBannersCollection,
  DeleteCommentsByUserCollection,
  DeleteFeedsByUserCollection,
  DeloitteSubscriberEmailsCollection,
  DiscoverCollectionItem,
  DiscoverCollectionItemsCollection,
  Education,
  EducationsCollection,
  EnterpriseModel,
  EnterprisesCollection,
  Experience,
  ExperiencesCollection,
  ExternalReference,
  ExternalReferenceCollection,
  ExternalReferenceContextTypeEnum,
  FeedPostsCollection,
  FieldServicesTalentCollection,
  FinancialReportingRecord,
  FinancialReportingRecordCollection,
  FlareActionsCollection,
  FlareRulesCollection,
  FlareRulesetsCollection,
  FreeRecruiterUpgrade,
  FreeRecruiterUpgradeCollection,
  Group,
  GroupMember,
  GroupMembersCollection,
  GroupsCollection,
  HarvardContact,
  HarvardContactsCollection,
  Invitation,
  InvitationBlock,
  InvitationBlocksCollection,
  InvitationsCollection,
  LoadPriceEstimate,
  LoadPriceEstimateCollection,
  MembershipBenefitsCollection,
  MembershipRenewal,
  MembershipRenewalsCollection,
  MembershipSubscriptionCollection,
  MessagesCollection,
  MilestoneDraftsCollection,
  MilestoneRequestsCollection,
  MygigsExpressionOfInterestCollection,
  NextProjectRecommendations,
  NextProjectRecommendationsCollection,
  OnBehalfProjectsCollection,
  OnlineOfflineCollection,
  ParentContestComment,
  PaymentShareInterest,
  PaymentShareInterestsCollection,
  PaymentShareMember,
  PaymentShareMembersCollection,
  PaymentShareTeamsCollection,
  PayoutAccount,
  PayoutAccountsCollection,
  PayoutLimitExemption,
  PayoutLimitExemptionsCollection,
  PostJobPageDescription,
  PostJobPageDescriptionCollection,
  PostJobPageQuestion,
  PostJobPageQuestionsCollection,
  PreferredSupportAgentAssignment,
  PreferredSupportAgentCollection,
  ProfileCategoriesCollection,
  ProfileCategory,
  ProfilesCollection,
  ProjectCollaboration,
  ProjectCollaborationsCollection,
  ProjectDescriptionEntropyCompletion,
  ProjectDescriptionEntropyCompletionCollection,
  ProjectDescriptionEntropyQuestion,
  ProjectDescriptionEntropyQuestionAnswer,
  ProjectDescriptionEntropyQuestionCollection,
  ProjectDescriptionInsertions,
  ProjectDescriptionInsertionsCollection,
  ProjectDescriptionInsertionsItem,
  ProjectGroup,
  ProjectGroupsCollection,
  ProjectInviteCollection,
  ProjectOffer,
  ProjectOfferCollection,
  ProjectTitleEditRequest,
  ProjectTitleEditRequestCollection,
  ProjectViewBidsCollection,
  ProjectViewProjectsCollection,
  ProjectsCollection,
  Publication,
  PublicationsCollection,
  QuotationItemRevision,
  QuotationItemRevisionsCollection,
  QuotationLogo,
  QuotationLogosCollection,
  QuotationRevision,
  QuotationRevisionsCollection,
  ReactionsTypeCountCollection,
  RecommendedUpgrade,
  RecommendedUpgradeCollection,
  RecurringBillingInformation,
  RecurringBillingInformationCollection,
  ReferralInvitationsCollection,
  ReplyContestComment,
  Review,
  ReviewsCollection,
  SalesTax,
  SalesTaxCollection,
  SavedSearchContext,
  SavedSearchContextsCollection,
  SearchSavedFilters,
  SearchSavedFiltersCollection,
  ServiceOffering,
  ServiceOfferingAttachment,
  ServiceOfferingAttachmentsCollection,
  ServiceOfferingShopCategoriesCollection,
  ServiceOfferingShopCategory,
  ServiceOfferingShopCategoryService,
  ServiceOfferingShopCategoryServicesCollection,
  ServiceOfferingsCollection,
  Showcase,
  ShowcaseCollection,
  SuperuserBidsCollection,
  SuperuserGroupsCollection,
  SuperuserProjectNotesCollection,
  SupportAnswer,
  SupportAnswersCollection,
  SupportFeedbackCollection,
  TaxStatement,
  TaxStatementCollection,
  ThreadsCollection,
  TimeTrackingSessionCollection,
  UnconfirmedAwards,
  UnconfirmedAwardsCollection,
  UserAvailabilitiesCollection,
  UserAvailability,
  UserDocumentSubmission,
  UserDocumentSubmissionsCollection,
  UserEnterpriseModel,
  UserInteractionsCollection,
  UserLastOnlineCollection,
  UserRecommend,
  UserSkillsCollection,
  UserTaxInfo,
  UserTaxInfoCollection,
  UserTaxOptOutReasonsCollection,
  UserTypeInfoCollection,
  UserVerificationProcess,
  UserVerificationProcessesCollection,
  UsersFollowCollection,
  UsersRecommendCollection,
  VerificationRequest,
  VerificationRequestCollection,
  ViolationReportsCollection,
} from '@freelancer/datastore/collections';
import {
  AiConsultantMessageRole,
  ContestCommentType,
  ProjectCategory,
  ProjectUpgrade,
  QuotationItemType,
  ServiceOfferingShopCategoryServicePriceType,
  countries,
  generateUid,
  transformReactionTypeCount,
  universities,
  transformFinancialReportingRecord,
} from '@freelancer/datastore/collections';
import type { PushDocumentType } from '@freelancer/datastore/core';
import { generateId } from '@freelancer/datastore/testing/helpers';
import type { PartialBy } from '@freelancer/types';
import { toNumber } from '@freelancer/utils';
import { AiProofreadLanguageApi, ToneTypeApi } from 'api-typings/ai/ai';
import {
  SearchSavedFiltersContextTypeApi,
  TaxTypeApi,
  TimeUnitApi,
} from 'api-typings/common/common';
import {
  ContestStatusApi,
  EntryStatusApi,
} from 'api-typings/contests/contests';
import { GroupMemberRoleApi, GroupTypeApi } from 'api-typings/groups/groups';
import {
  CartApprovalStatusApi,
  PayoutAccountStatusEnumApi,
} from 'api-typings/payments/payments';
import {
  ProjectCollaborationStatusApi,
  ProjectStatusApi,
  ProjectTitleEditRequestStatusApi,
  ReviewStatusApi,
} from 'api-typings/projects/projects';
import {
  QuotationItemStatusApi,
  QuotationStatusApi,
} from 'api-typings/quotations/quotations';
import { ServiceOfferingStatusApi } from 'api-typings/service_offerings/service_offerings';
import { SupportTypeApi } from 'api-typings/support/support';
import {
  InvitationBlockStatusApi,
  InvitationEntityTypeApi,
  InvitationStatusApi,
  UserAvailabilityStatusApi,
  UserDocumentSubmissionStatusApi,
  UserVerificationProcessStatusApi,
} from 'api-typings/users/users';
import { v4 as uuid } from 'uuid';
import { addPushTransformer } from './document-creators';

export function addPushTransformers(): void {
  addPushTransformer<AgentSessionsCollection>(
    'agentSessions',
    addAgentSessionsComputedFields,
  );
  addPushTransformer<AdminChargeUserFeeCollection>(
    'adminChargeUserFee',
    addAdminChargeUserFeeComputedFields,
  );
  addPushTransformer<AdminEnterprisesCollection>(
    'adminEnterprises',
    addAdminEnterprisesComputedFields,
  );
  addPushTransformer<AdminPcbBanManagementCollection>(
    'adminPcbBanManagement',
    addAdminPcbBanManagementComputedFields,
  );
  addPushTransformer<AdminUserEnterprisesCollection>(
    'adminUserEnterprises',
    addAdminUserEnterprisesComputedFields,
  );
  addPushTransformer<BidsCollection>('bids', addGeneratedNumericalId);
  addPushTransformer<BidDescriptionCollection>(
    'bidDescription',
    addBidDescriptionGenerationComputedFields,
  );
  addPushTransformer<CartApprovalsCollection>(
    'cartApprovals',
    addCartApprovalComputedFields,
  );
  addPushTransformer<CartItemsCollection>('cartItems', addGeneratedNumericalId);
  addPushTransformer<CartsCollection>('carts', addGeneratedNumericalId);
  addPushTransformer<CertificationsCollection>(
    'certifications',
    addCertificationComputedFields,
  );
  addPushTransformer<CommentFeedsCollection>(
    'commentFeeds',
    addGeneratedNumericalId,
  );
  addPushTransformer<CommentsCollection>('comments', addGeneratedNumericalId);
  addPushTransformer<ContestCommentsCollection>(
    'contestComments',
    addContestCommentComputedFields,
  );
  addPushTransformer<ContestRubricsCollection>(
    'contestRubrics',
    addGeneratedNumericalId,
  );
  addPushTransformer<ContestsCollection>('contests', addContestComputedFields);
  addPushTransformer<ContractSigningUrlsCollection>(
    'contractSigningUrls',
    addContractSigningUrlComputedFields,
  );
  addPushTransformer<DeleteCommentsByUserCollection>(
    'deleteCommentsByUser',
    addGeneratedNumericalId,
  );
  addPushTransformer<DeleteFeedsByUserCollection>(
    'deleteFeedsByUser',
    addGeneratedNumericalId,
  );
  addPushTransformer<EducationsCollection>(
    'educations',
    addEducationComputedFields,
  );
  addPushTransformer<ExperiencesCollection>(
    'experiences',
    addExperienceComputedFields,
  );
  addPushTransformer<FieldServicesTalentCollection>(
    'fieldServicesTalent',
    addGeneratedNumericalId,
  );
  addPushTransformer<FlareActionsCollection>(
    'flareActions',
    addGeneratedNumericalId,
  );
  addPushTransformer<FlareRulesCollection>(
    'flareRules',
    addGeneratedNumericalId,
  );
  addPushTransformer<FlareRulesetsCollection>(
    'flareRulesets',
    addGeneratedNumericalId,
  );
  addPushTransformer<FreeRecruiterUpgradeCollection>(
    'freeRecruiterUpgrade',
    addFreeRecruiterUpgradeComputedFields,
  );
  addPushTransformer<GroupMembersCollection>('groupMembers', addGroupMember);
  addPushTransformer<InvitationBlocksCollection>(
    'invitationBlocks',
    addInvitationBlockComputedFields,
  );
  addPushTransformer<FeedPostsCollection>('feedPosts', addGeneratedNumericalId);
  addPushTransformer<FinancialReportingRecordCollection>(
    'financialReportingRecord',
    addFinancialReportingRecordComputedFields,
  );

  addPushTransformer<InvitationsCollection>(
    'invitations',
    addInvitationComputedFields,
  );
  addPushTransformer<LoadPriceEstimateCollection>(
    'loadPriceEstimate',
    addLoadPriceEstimateComputedFields,
  );
  // id is the benefit name, which should be provided
  addPushTransformer<MembershipBenefitsCollection>('membershipBenefits', noop);
  addPushTransformer<MembershipRenewalsCollection>(
    'membershipRenewals',
    addMembershipRenewalsComputedFields,
  );
  addPushTransformer<MembershipSubscriptionCollection>(
    'membershipSubscription',
    addGeneratedNumericalId,
  );
  addPushTransformer<MessagesCollection>('messages', addGeneratedNumericalId);
  addPushTransformer<MilestoneDraftsCollection>(
    'milestoneDrafts',
    addGeneratedNumericalId,
  );
  addPushTransformer<MilestoneRequestsCollection>(
    'milestoneRequests',
    addGeneratedNumericalId,
  );
  addPushTransformer<MygigsExpressionOfInterestCollection>(
    'mygigsExpressionOfInterest',
    addGeneratedNumericalId,
  );
  addPushTransformer<NextProjectRecommendationsCollection>(
    'nextProjectRecommendations',
    addNextProjectRecommendationsComputedFields,
  );
  addPushTransformer<OnBehalfProjectsCollection>(
    'onBehalfProjects',
    addGeneratedNumericalId,
  );
  addPushTransformer<OnlineOfflineCollection>('onlineOffline', noop); // the onlineOffline "id" is a user id which should be provided
  addPushTransformer<PaymentShareInterestsCollection>(
    'paymentShareInterests',
    addPaymentShareInterestsComputedFields,
  );
  addPushTransformer<PaymentShareMembersCollection>(
    'paymentShareMembers',
    addPaymentShareMemberComputedFields,
  );
  addPushTransformer<PaymentShareTeamsCollection>(
    'paymentShareTeams',
    addGeneratedNumericalId,
  );
  addPushTransformer<ProjectsCollection>('projects', addGeneratedNumericalId);
  addPushTransformer<ProjectTitleEditRequestCollection>(
    'projectTitleEditRequest',
    addProjectTitleEditRequestComputedFields,
  );
  addPushTransformer<ProjectViewBidsCollection>(
    'projectViewBids',
    addGeneratedNumericalId,
  );
  addPushTransformer<ProjectViewProjectsCollection>(
    'projectViewProjects',
    addProjectViewProjectComputedFields,
  );
  addPushTransformer<ProfilesCollection>('profiles', addProfilesComputedFields);
  addPushTransformer<PublicationsCollection>(
    'publications',
    addPublicationComputedFields,
  );
  addPushTransformer<QuotationItemRevisionsCollection>(
    'quotationItemRevisions',
    addQuotationItemRevision,
  );
  addPushTransformer<QuotationLogosCollection>(
    'quotationLogos',
    addQuotationLogoComputedFields,
  );
  addPushTransformer<QuotationRevisionsCollection>(
    'quotationRevisions',
    addQuotationRevision,
  );
  addPushTransformer<ReactionsTypeCountCollection>(
    'reactionsTypeCount',
    (authId, document) => transformReactionTypeCount(document),
  );
  addPushTransformer<ReferralInvitationsCollection>(
    'referralInvitations',
    addGeneratedStringId,
  );
  addPushTransformer<ReviewsCollection>('reviews', addReviewComputedFields);
  addPushTransformer<SearchSavedFiltersCollection>(
    'searchSavedFilters',
    addSearchSavedFiltersComputedFields,
  );
  addPushTransformer<ServiceOfferingsCollection>(
    'serviceOfferings',
    addServiceOfferingComputedFields,
  );
  addPushTransformer<ServiceOfferingAttachmentsCollection>(
    'serviceOfferingAttachments',
    addServiceOfferingAttachmentComputedFields,
  );
  addPushTransformer<ServiceOfferingShopCategoriesCollection>(
    'serviceOfferingShopCategories',
    addServiceOfferingShopCategoryComputedFields,
  );
  addPushTransformer<ShowcaseCollection>('showcase', addShowCaseComputedFields);
  addPushTransformer<SuperuserBidsCollection>(
    'superuserBids',
    addGeneratedNumericalId,
  );
  addPushTransformer<SuperuserGroupsCollection>(
    'superuserGroups',
    addSuperuserGroupsComputedFields,
  );
  addPushTransformer<ThreadsCollection>('threads', addGeneratedNumericalId);
  addPushTransformer<TimeTrackingSessionCollection>(
    'timeTrackingSession',
    addGeneratedNumericalId,
  );
  addPushTransformer<UserAvailabilitiesCollection>(
    'userAvailabilities',
    addUserAvailabilityComputedFields,
  );
  addPushTransformer<UserDocumentSubmissionsCollection>(
    'userDocumentSubmissions',
    addUserDocumentSubmissions,
  );
  addPushTransformer<UserInteractionsCollection>(
    'userInteractions',
    addGeneratedNumericalId,
  );
  addPushTransformer<UserLastOnlineCollection>(
    'userLastOnline',
    addGeneratedNumericalId,
  );
  addPushTransformer<UserSkillsCollection>(
    'userSkills',
    addGeneratedNumericalId,
  );
  addPushTransformer<UserTaxInfoCollection>(
    'userTaxInfo',
    addUserTaxInfoComputedFields,
  );
  addPushTransformer<UserTaxOptOutReasonsCollection>(
    'userTaxOptOutReasons',
    addGeneratedNumericalId,
  );
  addPushTransformer<UserTypeInfoCollection>(
    'userTypeInfo',
    addGeneratedNumericalId,
  );
  addPushTransformer<UserVerificationProcessesCollection>(
    'userVerificationProcesses',
    addUserVerificationProcesses,
  );
  addPushTransformer<GroupsCollection>('groups', addGroupsComputedFields);
  addPushTransformer<VerificationRequestCollection>(
    'verificationRequest',
    addVerificationRequestComputedFields,
  );
  addPushTransformer<ViolationReportsCollection>(
    'violationReports',
    addGeneratedNumericalId,
  );
  addPushTransformer<AdminUserDocumentSubmissionsCollection>(
    'adminUserDocumentSubmissions',
    noop,
  );
  addPushTransformer<BidPitchCollection>('bidPitch', addGeneratedNumericalId);
  addPushTransformer<PayoutLimitExemptionsCollection>(
    'payoutLimitExemptions',
    addPayoutLimitExemptionComputedFields,
  );
  addPushTransformer<ProjectGroupsCollection>(
    'projectGroups',
    addProjectGroupComputedFields,
  );
  addPushTransformer<ProjectOfferCollection>(
    'projectOffer',
    addProjectOfferComputedFields,
  );
  addPushTransformer<ProjectInviteCollection>(
    'projectInvite',
    addGeneratedNumericalId,
  );
  addPushTransformer<EnterprisesCollection>(
    'enterprises',
    addEnterprisesComputedFields,
  );
  addPushTransformer<HarvardContactsCollection>(
    'harvardContacts',
    addHarvardContactFields,
  );
  addPushTransformer<UnconfirmedAwardsCollection>(
    'unconfirmedAwards',
    addUnconfirmedAwardsFields,
  );
  addPushTransformer<CustomBannersCollection>(
    'customBanners',
    addCustomBannerComputedFields,
  );
  addPushTransformer<ContestFilesCollection>(
    'contestFiles',
    addContestFilesComputedFields,
  );
  addPushTransformer<RecommendedUpgradeCollection>(
    'recommendedUpgrade',
    addRecommendedUpgradeComputedFields,
  );
  addPushTransformer<MembershipSubscriptionCollection>(
    'membershipSubscription',
    addGeneratedNumericalId,
  );

  addPushTransformer<SuperuserProjectNotesCollection>(
    'superuserProjectNotes',
    addGeneratedNumericalId,
  );
  addPushTransformer<SupportFeedbackCollection>(
    'supportFeedback',
    addGeneratedNumericalId,
  );
  addPushTransformer<SavedSearchContextsCollection>(
    'savedSearchContexts',
    addSavedSearchContextComputedFields,
  );
  addPushTransformer<ProfileCategoriesCollection>(
    'profileCategories',
    addCategoryComputedFields,
  );
  addPushTransformer<UsersFollowCollection>(
    'usersFollow',
    addGeneratedNumericalId,
  );
  addPushTransformer<RecurringBillingInformationCollection>(
    'recurringBillingInformation',
    addRecurringBillingInformationComputedFields,
  );
  addPushTransformer<SalesTaxCollection>('salesTax', addSalesTaxComputedFields);
  addPushTransformer<AiContinueWritingCollection>(
    'aiContinueWriting',
    addContinueWritingFields,
  );
  addPushTransformer<AiProofreadCollection>('aiProofread', addProofreadFields);
  addPushTransformer<AiImproveWritingCollection>(
    'aiImproveWriting',
    addImproveWritingFields,
  );
  addPushTransformer<AiChangeToneCollection>(
    'aiChangeTone',
    addAiChangeToneFields,
  );
  addPushTransformer<CopilotEnquiriesCollection>(
    'copilotEnquiries',
    addCopilotEnquiryFields,
  );
  addPushTransformer<DeloitteSubscriberEmailsCollection>(
    'deloitteSubscriberEmails',
    addGeneratedStringId,
  );
  addPushTransformer<SupportAnswersCollection>(
    'supportAnswers',
    addSupportAnswerFields,
  );
}

export function noop<T extends { readonly id: number | string }>(
  authUid: number,
  document: T,
): T {
  return document;
}

export function addGeneratedNumericalId<T extends { readonly id: number }>(
  authUid: number,
  document: PartialBy<T, 'id'>,
): T {
  return { ...document, id: toNumber(document.id) ?? generateId() } as any;
}

export function addGeneratedStringId<T extends { readonly id: string }>(
  authUid: number,
  document: PartialBy<T, 'id'>,
): T {
  return {
    ...document,
    id: document.id ? document.id.toString() : generateId().toString(),
  } as any;
}

export function addContestComputedFields(
  authUid: number,
  document: PushDocumentType<ContestsCollection> &
    Partial<Pick<ContestsCollection['DocumentType'], 'id'>>,
): ContestsCollection['DocumentType'] {
  const id = toNumber(document.id) ?? generateId();

  return {
    ...document,
    id,
    seoUrl: `contest/${id}`,
    status: ContestStatusApi.ACTIVE,
  };
}

export function addProjectViewProjectComputedFields(
  authUid: number,
  document: PushDocumentType<ProjectViewProjectsCollection> &
    Partial<Pick<ProjectViewProjectsCollection['DocumentType'], 'id'>>,
): ProjectViewProjectsCollection['DocumentType'] {
  const id = toNumber(document.id) ?? generateId();

  return {
    ...document,
    id,
    attachments: [],
    bidStats: { bidCount: 0 },
    location: {},
    deleted: false,
    hideBids: false,
    ownerId: authUid,
    previewDescription: '',
    projectCollaborations: [],
    qualifications: [],
    seoUrl: `project-${id}`,
    status: ProjectStatusApi.ACTIVE,
    timeSubmitted: Date.now(),
    selectedBids: [],
    canPostReview: {},
    isEscrowProject: false,
    isSellerKycRequired: false,
    isBuyerKycRequired: false,
    isDeloitteProject: true,
    isTokenProject: true,
    isInsourceProject: false,
    isHpProject: false,

    hireme: document.hireme ?? false,
    local: document.local ?? false,
    language: document.language ?? 'en',
    ndaDetails: document.ndaDetails ?? { signatures: [] },
  };
}

export function addProfilesComputedFields(
  authUid: number,
  document: PushDocumentType<ProfilesCollection> &
    Partial<Pick<ProfilesCollection['DocumentType'], 'id'>>,
): ProfilesCollection['DocumentType'] {
  const id = toNumber(document.id) ?? generateId();

  return {
    ...document,
    id,
    userId: authUid,
    seoUrl: document.profileName,
    createDate: Date.now(),
    lastUpdatedDate: Date.now(),
    isDefault: false,
  };
}

export function addProjectCollaborationComputedFields(
  authUid: number,
  document: PushDocumentType<ProjectCollaborationsCollection>,
  collaboratorId?: number,
  status?: ProjectCollaborationStatusApi,
): ProjectCollaboration {
  return {
    ...document,
    id: generateId(),
    userId: collaboratorId,
    contextOwnerId: authUid,
    timeCreated: Date.now(),
    status: status ?? ProjectCollaborationStatusApi.ACTIVE,
  };
}

export function addInvitationComputedFields(
  authUid: number,
  document: PushDocumentType<InvitationsCollection>,
): Invitation {
  return {
    ...document,
    id: generateId(),
    inviterUserId: authUid,
    status: InvitationStatusApi.PENDING,
    timeCreated: Date.now(),
    token: 'test_token',
  };
}

export function addLoadPriceEstimateComputedFields(
  authUid: number,
  document: PushDocumentType<LoadPriceEstimateCollection>,
): LoadPriceEstimate {
  return {
    ...document,
    id: generateId().toString(),
  };
}

export function addGroupsComputedFields(
  authUid: number,
  document: PushDocumentType<GroupsCollection>,
): Group {
  const id = generateId();

  return {
    ...document,
    id,
    created: Date.now(),
    orderByFeedReadLatestTimeInMs: Date.now(),
    updated: Date.now(),
    seoUrl: `group-${id}`,
    isDeleted: false,
    isPublic: document.groupType === GroupTypeApi.COMMUNITY,
  };
}

export function addSuperuserGroupsComputedFields(
  authUid: number,
  document: PushDocumentType<SuperuserGroupsCollection>,
): Group {
  const id = generateId();

  return {
    ...document,
    id,
    created: Date.now(),
    orderByFeedReadLatestTimeInMs: Date.now(),
    updated: Date.now(),
    seoUrl: `group-${id}`,
    isDeleted: false,
    isPublic: document.groupType === GroupTypeApi.COMMUNITY,
  };
}

export function addInvitationBlockComputedFields(
  authUid: number,
  document: PushDocumentType<InvitationBlocksCollection>,
): InvitationBlock {
  return {
    ...document,
    id: generateId(),
    inviteeEntityId: authUid,
    inviteeEntityType: InvitationEntityTypeApi.USER,
    status: InvitationBlockStatusApi.ACTIVE,
  };
}

export function addExperienceComputedFields(
  authUid: number,
  document: PushDocumentType<ExperiencesCollection>,
): Experience {
  return {
    ...document,
    id: generateId(),
    userId: authUid,
    ordering: 1,
    country: document.countryCode
      ? countries[document.countryCode as CountryCode].name
      : undefined,
  };
}

export function addEducationComputedFields(
  authUid: number,
  document: PushDocumentType<EducationsCollection>,
): Education {
  const { countryCode, schoolId, startDate, endDate } = document;
  return {
    ...document,
    id: generateId(),
    userId: authUid,
    schoolName:
      countryCode && schoolId
        ? universities[countryCode as CountryCode]?.find(
            uni => uni.id === schoolId,
          )?.name
        : undefined,
    country: countryCode
      ? countries[countryCode as CountryCode].name
      : undefined,
    ordering: 1,
    duration: startDate && endDate ? endDate - startDate : undefined,
  };
}

export function addPublicationComputedFields(
  authUid: number,
  document: PushDocumentType<PublicationsCollection>,
): Publication {
  return {
    ...document,
    id: generateId(),
    userId: authUid,
  };
}

export function addCertificationComputedFields(
  authUid: number,
  document: PushDocumentType<CertificationsCollection>,
): Certification {
  return {
    ...document,
    id: generateId(),
    userId: authUid,
  };
}

// Only use this if your test assumes the other party has already left a review.
// It causes Freelancer/EmployerReviewAreaComponent#reviewGiven$ to emit after a review is submitted.
export function addReviewComputedFields(
  authUid: number,
  document: PushDocumentType<ReviewsCollection> &
    Partial<Pick<ReviewsCollection['DocumentType'], 'id' | 'status'>>,
): Review {
  return {
    ...document,
    id: document.id ? document.id.toString() : generateId().toString(),
    status: ReviewStatusApi.ACTIVE,
    // This is the review context.seoUrl, which differs from the project.seoUrl
    // Matches the reviews datastore call, see FreelancerReviewArea#reviewContext$
    context: {
      ...document.context,
      seoUrl: `/projects/${document.context.seoUrl}/`,
    },
  } as Review;
}

export function addContestCommentComputedFields(
  authUid: number,
  document: PushDocumentType<ContestCommentsCollection>,
): ContestComment {
  if (!document.parentId) {
    return {
      ...document,
      id: generateId(),
      type: ContestCommentType.PARENT,
      parentId: undefined,
      repliesCount: 0,
      timestamp: Date.now(),
      dateLastComment: Date.now(),
    } as ParentContestComment;
  }

  return {
    ...document,
    id: generateId(),
    timestamp: Date.now(),
    dateLastComment: Date.now(),
    type: ContestCommentType.REPLY,
  } as ReplyContestComment;
}

export function addPaymentShareMemberComputedFields(
  authUid: number,
  document: PushDocumentType<PaymentShareMembersCollection>,
): PaymentShareMember {
  return {
    ...document,
    id: generateId(),
    billingAgreementId: generateId(),
  } as PaymentShareMember;
}

export function addPaymentShareInterestsComputedFields(
  authUid: number,
  document: PushDocumentType<PaymentShareInterestsCollection>,
): PaymentShareInterest {
  return {
    ...document,
    id: generateId(),
    dateAdded: Date.now(),
  } as PaymentShareInterest;
}

export function addGroupMember(
  authUid: number,
  document: PushDocumentType<GroupMembersCollection>,
): GroupMember {
  const id = generateId();
  return {
    ...document,
    id,
    memberId: id,
    role: GroupMemberRoleApi.GUEST,
    created: Date.now(),
    isRemoved: false,
    userId: authUid,
  };
}

export function addAdminChargeUserFeeComputedFields(
  authUid: number,
  document: PushDocumentType<AdminChargeUserFeeCollection>,
): AdminChargeUserFeeModel {
  return {
    ...document,
    id: generateId(),
  };
}

export function addAdminEnterprisesComputedFields(
  authUid: number,
  document: PushDocumentType<AdminEnterprisesCollection>,
): AdminEnterpriseModel {
  return {
    ...document,
    id: generateId(),
    membersCount: 0,
    dateCreated: Date.now(),
  };
}

export function addAdminPcbBanManagementComputedFields(
  authUid: number,
  document: PushDocumentType<AdminPcbBanManagementCollection>,
): AdminPcbBanManagementModel {
  return {
    ...document,
    id: generateId(),
    isActive: true,
    dateAdded: Date.now(),
  };
}

export function addAdminUserEnterprisesComputedFields(
  authUid: number,
  document: PushDocumentType<AdminUserEnterprisesCollection>,
): UserEnterpriseModel {
  return {
    ...document,
    id: generateId(),
    dateAdded: Date.now(),
  };
}

export function addProjectTitleEditRequestComputedFields(
  authUid: number,
  document: PushDocumentType<ProjectTitleEditRequestCollection>,
): ProjectTitleEditRequest {
  return {
    ...document,
    id: generateId(),
    requestedBy: authUid,
    status: ProjectTitleEditRequestStatusApi.PENDING,
  };
}

export function addSearchSavedFiltersComputedFields(
  authUid: number,
  document: PushDocumentType<SearchSavedFiltersCollection>,
): SearchSavedFilters {
  return {
    ...document,
    id: generateId(),
    userId: generateId(),
  };
}

export function addMembershipRenewalsComputedFields(
  authUid: number,
  { period }: PushDocumentType<MembershipRenewalsCollection>,
): MembershipRenewal {
  return {
    id: generateId(),
    timeOptedIn: Date.now(),
    period,
    duration: {
      id: 2,
      type: TimeUnitApi.MONTH,
      cycle: 1,
    },
    failureCount: 0,
  };
}

// User taxation push transformers
export function addUserTaxInfoComputedFields(
  authUid: number,
  document: PushDocumentType<UserTaxInfoCollection>,
): UserTaxInfo {
  // Retrieved from `src/Taxes/UserTaxHandler.php`
  const NON_LAST_4_DIGITS_REGEX =
    /[A-Za-z0-9](?=(?:[^A-Za-z0-9]*[A-Za-z0-9]){4})/g;
  const maskableTaxTypes = [
    TaxTypeApi.VAT,
    TaxTypeApi.US1099K,
    TaxTypeApi.MXIT,
  ];

  const tin = maskableTaxTypes.includes(document.type)
    ? document.number.replace(NON_LAST_4_DIGITS_REGEX, 'X')
    : document.number;

  return {
    ...document,
    id: generateId(),
    number: tin,
  };
}

export function addQuotationRevision(
  authUid: number,
  document: PushDocumentType<QuotationRevisionsCollection>,
): QuotationRevision {
  const currencyId = document.defaultCurrencyId;
  return {
    ...document,
    id: generateId(),
    quotationId: generateId(),
    quotationUid: generateUid(10),
    versionUid: generateUid(10),
    createDate: Date.now(),
    status: QuotationStatusApi.DRAFT,
    paidAmount: { [currencyId]: 0 },
    fundedAmount: { [currencyId]: 0 },
    quotePrice: { [currencyId]: 0 },
  };
}

export function addQuotationItemRevision(
  authUid: number,
  document: PushDocumentType<QuotationItemRevisionsCollection>,
): QuotationItemRevision {
  return {
    ...document,
    id: generateId(),
    quotationItemId: generateId(),
    totalPrice: document.quantity * document.unitPrice,
    quantity: 1,
    status: QuotationItemStatusApi.PENDING,
    quotationItemType: document.billingFrequency
      ? QuotationItemType.SUBSCRIPTION
      : document.unitType
      ? QuotationItemType.HOURLY
      : QuotationItemType.FIXED,
  };
}

export function addQuotationLogoComputedFields(
  authUid: number,
  document: PushDocumentType<QuotationLogosCollection>,
): QuotationLogo {
  return {
    ...document,
    createDate: Date.now(),
    creatorId: authUid,
    id: generateId(),
  };
}

export function addVerificationRequestComputedFields(
  authUid: number,
  document: PushDocumentType<VerificationRequestCollection>,
): VerificationRequest {
  return { ...document, id: generateId(), userId: authUid };
}

export function addDiscoverCollectionItem(
  authUid: number,
  document: PushDocumentType<DiscoverCollectionItemsCollection>,
  order: number,
  name: string,
  description: string,
  url = '',
  thumbnail = '',
): DiscoverCollectionItem {
  return {
    ...document,
    id: generateId(),
    url,
    thumbnail,
    name,
    description,
    order,
  };
}

export function addCartApprovalComputedFields(
  authUid: number,
  document: PushDocumentType<CartApprovalsCollection>,
): CartApproval {
  return {
    ...document,
    id: generateId(),
    status: CartApprovalStatusApi.PENDING,
    timeModified: Date.now(),
  };
}

export function addUserAvailabilityComputedFields(
  authUid: number,
  document: PushDocumentType<UserAvailabilitiesCollection>,
): UserAvailability {
  return {
    ...document,
    id: generateId(),
    userId: authUid,
    seoUrl: document.title.split(' ').join('-'),
    isDeleted: false,
    submitDate: Date.now(),
    status: UserAvailabilityStatusApi.ACTIVE,
    equipment: document?.equipmentGroups?.[0].items ?? [],
  };
}

export function addServiceOfferingComputedFields(
  authUid: number,
  document: PushDocumentType<ServiceOfferingsCollection>,
): ServiceOffering {
  return {
    ...document,
    id: generateId(),
    creatorId: authUid,
    status: ServiceOfferingStatusApi.DRAFT,
  };
}

export function addServiceOfferingAttachmentComputedFields(
  authUid: number,
  document: PushDocumentType<ServiceOfferingAttachmentsCollection>,
): ServiceOfferingAttachment {
  return {
    ...document,
    id: generateId(),
    createDate: Date.now(),
    position: 99_999,
  };
}

export function addServiceOfferingShopCategoryComputedFields(
  authUid: number,
  document: PushDocumentType<ServiceOfferingShopCategoriesCollection>,
): ServiceOfferingShopCategory {
  return {
    ...document,
    id: generateId(),
  };
}

export function addServiceOfferingShopCategoryServiceFields(
  authUid: number,
  document: PushDocumentType<ServiceOfferingShopCategoryServicesCollection>,
  serviceOfferingId: number,
  categoryId = generateId(),
  shopId = generateId(),
  title = 'Test Service Offering',
  seoUrl = 'test-service-offering',
  heroImageUuid = uuid(),
  heroImageUrl = 'test-hero-image-url',
  duration = 60 * 60 * 24 * 7, // SECONDS_IN_A_WEEK
  price = 100,
  priceType = ServiceOfferingShopCategoryServicePriceType.HAS_FIXED_ITEMS,
): ServiceOfferingShopCategoryService {
  return {
    ...document,
    id: generateId(),
    serviceOfferingId,
    categoryId,
    shopId,
    title,
    seoUrl,
    heroImageUuid,
    heroImageUrl,
    duration,
    price,
    priceType,
  };
}

export function addPostJobPageQuestionsComputedFields(
  authUid: number,
  document: PushDocumentType<PostJobPageQuestionsCollection>,
  type: string | undefined = undefined,
): PostJobPageQuestion {
  return {
    ...document,
    question: 'What type of website do you need?',
    choices: ['Brochure', 'E-commerce', 'Portfolio'],
    type,
  };
}

export function addPostJobPageDescriptionComputedFields(
  authUid: number,
  document: PushDocumentType<PostJobPageDescriptionCollection>,
  description:
    | string
    | undefined = 'I am looking for someone to build me a website for personal use.',
  generatedTitle: string | undefined = `GENERATED:${document.projectTitle}`,
): PostJobPageDescription {
  return {
    ...document,
    generatedTitle,
    description,
  };
}

export function addTaxStatementFields(
  authId: number,
  document: PushDocumentType<TaxStatementCollection>,
): TaxStatement {
  return {
    ...document,
    id: generateId(),
  };
}

export function addPayoutAccount(
  authId: number,
  document: PushDocumentType<PayoutAccountsCollection>,
): PayoutAccount {
  return {
    ...document,
    id: generateId(),
    status: PayoutAccountStatusEnumApi.APPROVED,
  };
}

export function addUsersRecommend(
  authId: number,
  document: PushDocumentType<UsersRecommendCollection>,
): UserRecommend {
  return {
    ...document,
    id: generateId(),
    date: Date.now(),
  };
}

export function addPayoutLimitExemptionComputedFields(
  authUid: number,
  document: PushDocumentType<PayoutLimitExemptionsCollection>,
): PayoutLimitExemption {
  return {
    ...document,
    id: generateId(),
    adminId: authUid,
    expirationDate: document.expirationDate * 1000,
    creationDate: Date.now(),
    isActive: true,
  };
}

export function addProjectOfferComputedFields(
  authUid: number,
  document: PushDocumentType<ProjectOfferCollection>,
): ProjectOffer {
  return {
    ...document,
    id: generateId(),
    freelancerId: authUid,
  };
}

export function addBidDescriptionGenerationComputedFields(
  authUid: number,
  document: PushDocumentType<BidDescriptionCollection>,
): BidDescription {
  return {
    ...document,
    id: generateUid(10),
    description:
      'hi! I am Suchithra, a software Engineer with two years of experience in PHP and I can help you with your project',
  };
}

export function addShowCaseComputedFields(
  authUid: number,
  document: PushDocumentType<ShowcaseCollection>,
): Showcase {
  return {
    ...document,
    id: generateId(),
    userId: authUid,
  };
}

export function addFreeRecruiterUpgradeComputedFields(
  authUid: number,
  document: PushDocumentType<FreeRecruiterUpgradeCollection>,
): FreeRecruiterUpgrade {
  return {
    ...document,
    id: generateId(),
  };
}

export function addNextProjectRecommendationsComputedFields(
  authUid: number,
  document: PushDocumentType<NextProjectRecommendationsCollection>,
): NextProjectRecommendations {
  return {
    ...document,
    id: document.projectId,
    projectId: document.projectId,
    recommendations: [
      {
        projectTitle: 'Create a mobile app',
        projectDescription: 'Implement a mobile app',
        categoryId: 7,
        categorySeoUrl: ProjectCategory.DEFAULT,
      },
      {
        projectTitle: 'Develop a customer service bot',
        projectDescription: 'Write a chat bot to help my business',
        categoryId: 7,
        categorySeoUrl: ProjectCategory.DEFAULT,
      },
      {
        projectTitle: 'Visualize data insights',
        projectDescription:
          'Create graphs based from existing data and insights',
        categoryId: 7,
        categorySeoUrl: ProjectCategory.DEFAULT,
      },
    ],
  };
}

export function addAgentSessionsComputedFields(
  authUid: number,
  document: PushDocumentType<AgentSessionsCollection>,
): AgentSession {
  return {
    ...document,
    id: generateId(),
  };
}

export function addEnterprisesComputedFields(
  authUid: number,
  document: PushDocumentType<EnterprisesCollection>,
): EnterpriseModel {
  return {
    ...document,
    id: generateId(),
    membersCount: 0,
    dateCreated: Date.now(),
    internalName: document.name.split(' ').join('_'),
    isTestEnterprise: false,
    seoUrl: document.name.split(' ').join('-'),
    kycVerified: false,
  };
}

export function addContactUsFields(
  authId: number,
  document: PushDocumentType<ContactUsCollection>,
): ContactUs {
  return {
    ...document,
    id: document.senderEmail,
  };
}

export function addUserDocumentSubmissions(
  authUid: number,
  document: PushDocumentType<UserDocumentSubmissionsCollection>,
): UserDocumentSubmission {
  return {
    ...document,
    id: generateId(),
    documentTypeId: generateId(),
    ownerId: authUid,
    status: UserDocumentSubmissionStatusApi.IN_REVIEW,
    userDocumentFiles: [],
    userDocumentFields: [],
    dateCreated: Date.now(),
  };
}

export function addUserVerificationProcesses(
  authUid: number,
  document: PushDocumentType<UserVerificationProcessesCollection>,
): UserVerificationProcess {
  return {
    ...document,
    id: generateId(),
    verificationProcessTypeId: generateId(),
    ownerId: authUid,
    status: UserVerificationProcessStatusApi.PENDING_REVIEW,
    dateCreated: Date.now(),
  };
}

export function addHarvardContactFields(
  authId: number,
  document: PushDocumentType<HarvardContactsCollection>,
): HarvardContact {
  return {
    ...document,
    id: generateId(),
    name: 'Full Name',
    emailAddress: 'email@example.com',
    companyName: 'Company Name',
    enquiry: 'Enquiry',
    captchaResponse: 'CAPTCHA',
  };
}

export function addUnconfirmedAwardsFields(
  authId: number,
  document: PushDocumentType<UnconfirmedAwardsCollection>,
): UnconfirmedAwards {
  return {
    ...document,
    id: generateId(),
    projectId: document.projectId,
    freelancerUsername: 'Username',
  };
}

export function addCustomBannerComputedFields(
  authId: number,
  document: PushDocumentType<CustomBannersCollection>,
): CustomBanner {
  return {
    ...document,
    id: generateId(),
    dateCreated: Date.now(),
  };
}

export function addContestEntriesFields(
  authId: number,
  document: PushDocumentType<ContestEntriesCollection>,
  id: number,
  files: readonly ContestEntryFile[],
  status: EntryStatusApi = EntryStatusApi.ACTIVE,
  statusNumber: number = 4,
  redirect: string | undefined = undefined,
): ContestEntry {
  return {
    ...document,
    id,
    deleted: false,
    files,
    likeCount: 0,
    rating: 0,
    redirect,
    status,
    statusNumber,
    timeEliminated: undefined,
    timeEntered: Date.now(),
    timeWon: undefined,
  };
}

export function addContestFilesComputedFields(
  authUid: number,
  document: PushDocumentType<ContestFilesCollection>,
): ContestFile {
  return {
    ...document,
    id: generateId(),
  };
}

export function addContractSigningUrlComputedFields(
  authUid: number,
  document: PushDocumentType<ContractSigningUrlsCollection>,
): ContractSigningUrl {
  return {
    ...document,
    // from transformer
    id: [
      authUid,
      document.customContractId,
      document.contractContextType,
      document.contextId,
    ].join('-'),
    // in prod this is a contracts service URL, i.e. contracts.freelancer.com
    signingUrl: `/contract-potential/access/${document.customContractId}`,
  };
}

export function addRecommendedUpgradeComputedFields(
  authUid: number,
  document: PushDocumentType<RecommendedUpgradeCollection>,
): RecommendedUpgrade {
  return {
    ...document,
    reason:
      "By upgrading your project to 'Featured', you will attract more experienced freelancers. Increase your chances of finding a great freelancer with this upgrade.",
    upgrade: ProjectUpgrade.FEATURED,
    type: 'Attract more experienced freelancers',
  };
}

export function addSavedSearchContextComputedFields(
  authUid: number,
  document: PushDocumentType<SavedSearchContextsCollection>,
): SavedSearchContext {
  return {
    ...document,
    id: generateId(),
    savedSearchId: generateId(),
    contextId: generateId(),
    contextType: SearchSavedFiltersContextTypeApi.USER_AVAILABILITY,
  };
}

export function addProjectGroupComputedFields(
  authUid: number,
  document: PushDocumentType<ProjectGroupsCollection>,
): ProjectGroup {
  return {
    ...document,
    id: generateId(),
    userId: authUid,
    created: Date.now(),
  };
}

export function addCategoryComputedFields(
  authUid: number,
  document: PushDocumentType<ProfileCategoriesCollection>,
): ProfileCategory {
  return {
    ...document,
    id: generateId(),
    // map portfolio item id to an object using generatePortfolioObject({id, userid})
    lastUpdated: Date.now(),
  };
}

export function addRecurringBillingInformationComputedFields(
  authUid: number,
  document: PushDocumentType<RecurringBillingInformationCollection>,
): RecurringBillingInformation {
  return {
    ...document,
    id: generateId(),
    isActive: true,
    createDate: Date.now(),
  };
}

export function addAiConsultantComputedFields(
  authUid: number,
  document: PushDocumentType<AiConsultantCollection>,
  assistantReply: string | undefined = undefined,
): AiConsultantChatHistory {
  return {
    id: generateId().toString(),
    history: [
      {
        role: AiConsultantMessageRole.ASSISTANT,
        content:
          "Hi I'm Ava, your AI guide to supercharging your business! Whether you're already running a business or dreaming of starting one, I'm here to help turn your vision into reality using AI powered freelancers. Share your business goals, and together, we'll create a project that our talented freelancers can bid on. Let's make your vision a reality!",
      },
      {
        role: AiConsultantMessageRole.USER,
        content: document.history.slice(-1)[0].content,
      },
      {
        role: AiConsultantMessageRole.ASSISTANT,
        content:
          assistantReply ??
          'Fantastic! What kind of business do you have, and what are you looking to improve or achieve?',
      },
    ],
  };
}

export function addSalesTaxComputedFields(
  authUid: number,
  document: PushDocumentType<SalesTaxCollection>,
): SalesTax {
  return {
    ...document,
    id: generateId(),
  };
}

export function addCopilotEnquiryFields(
  authId: number,
  document: PushDocumentType<CopilotEnquiriesCollection>,
): CopilotEnquiry {
  return {
    ...document,
    id: generateId(),
    firstName: 'Firstname',
    lastName: 'Lastname',
    email: 'test@example.com',
    phone: '123456789',
    enquiry: 'Enquiry',
    captchaResponse: 'CAPTCHA',
  };
}

export function addPreferredSupportAgentFields(
  authId: number,
  document: PushDocumentType<PreferredSupportAgentCollection>,
): PreferredSupportAgentAssignment {
  return {
    ...document,
    id: generateId(),
    userId: generateId(),
    agentId: generateId(),
    supportType: SupportTypeApi.RECRUITER,
    username: 'userPublicName',
    agentUsername: 'agentPublicName',
  };
}

export function addContinueWritingFields(
  authId: number,
  document: PushDocumentType<AiContinueWritingCollection>,
): AiContinueWriting {
  return {
    ...document,
    id: generateId().toString(),
    text: 'Wow this is some really cool text',
    contextId: generateId(),
  };
}

export function addImproveWritingFields(
  authId: number,
  document: PushDocumentType<AiImproveWritingCollection>,
): AiImproveWriting {
  return {
    ...document,
    id: generateId().toString(),
    text: 'This text has been enhanced, trust me',
  };
}

export function addProofreadFields(
  authId: number,
  document: PushDocumentType<AiProofreadCollection>,
): AiProofread {
  return {
    ...document,
    id: generateId().toString(),
    text: 'This text has been proofread',
    language: AiProofreadLanguageApi.UK_ENGLISH,
  };
}

export function addAiChangeToneFields(
  authId: number,
  document: PushDocumentType<AiChangeToneCollection>,
): AiChangeTone {
  return {
    ...document,
    id: generateId().toString(),
    text: 'Consider this texts tone to have been changed',
    tone: ToneTypeApi.FRIENDLY,
  };
}

export function addIrisOffsitingMessageFields(
  authId: number,
  document: PushDocumentType<AdminIrisOffsitingCollection>,
): AdminIrisOffsitingModel {
  const offendingUserId = generateId();
  const threadMember = generateId();
  return {
    ...document,
    id: generateId(),
    usersId: offendingUserId,
    threadId: generateId(),
    contextId: generateId(),
    offenceType: 'sample_offence_type',
    offenceValue: 'sample_offence_value',
    minMessageId: 1,
    maxMessageId: 10,
    messageCount: 10,
    threadMembers: `${offendingUserId},${threadMember}`,
    contextType: 'sample_context_type',
    createdTime: Date.now(),
  };
}

export function addExternalReferenceFields(
  authId: number,
  document: PushDocumentType<ExternalReferenceCollection>,
  contextId: number,
  contextType: ExternalReferenceContextTypeEnum,
): ExternalReference {
  return {
    ...document,
    id: generateId(),
    contextId,
    contextType,
    authorUserId: authId,
    relationship: 'Former Manager',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin blandit porta tempus. Nunc consectetur pulvinar accumsan.',
  };
}

export function addProjectDescriptionInsertionsFields(
  authId: number,
  document: PushDocumentType<ProjectDescriptionInsertionsCollection>,
  {
    taggedDescription,
    suggestions,
  }: {
    readonly taggedDescription?: string;
    readonly suggestions?: readonly ProjectDescriptionInsertionsItem[];
  } = {},
): ProjectDescriptionInsertions {
  return {
    ...document,
    description:
      taggedDescription ??
      `I'm seeking a skilled developer to create a robust web-based veterinary system for my shop. This platform will primarily cater to veterinarians, receptionists, and pet owners, and should encompass the following key functionalities:[LIST-1]\n- Appointment Scheduling: A user-friendly interface that allows easy booking and managing of appointments.\n- Patient Records Management: A secure and efficient system for storing and retrieving pet medical histories.\n- Inventory Tracking: A reliable mechanism for monitoring stock levels of veterinary supplies.\nIdeal candidates should have prior experience in developing similar systems, with a strong emphasis on web-based applications. They should also be adept in creating intuitive interfaces for varied user groups. Knowledge of inventory management systems would be a significant advantage. Please provide examples of similar projects you've completed.`,
    title: 'Web Based Veterinary System',
    listName: 'Add more functionalities',
    listId: 'LIST-1',
    suggestions: suggestions ?? [
      {
        order: 1,
        shortName: 'Interactive Avengers Map',
        bulletPoint:
          'Include an interactive Avengers map guiding customers to various themed products.',
      },
      {
        order: 2,
        shortName: 'Hero-Themed Recipes',
        bulletPoint:
          "Display recipes for hero-themed bakery items like 'Iron Man Cake' or 'Thor Cookies.'",
      },
      {
        order: 3,
        shortName: 'User Reviews Section',
        bulletPoint:
          'Enable users to leave reviews and ratings for the bakery items they order.',
      },
    ],
  };
}

export function addProjectDescriptionEntropyQuestionFields(
  authId: number,
  document: PushDocumentType<ProjectDescriptionEntropyQuestionCollection>,
  title: string,
  description: string,
  question?: string,
  choices?: readonly ProjectDescriptionEntropyQuestionAnswer[],
): ProjectDescriptionEntropyQuestion {
  return {
    ...document,
    title,
    description,
    question:
      question ??
      'What additional requirement do you want to add to your project?',
    choices: choices ?? [
      {
        order: 1,
        suggestion: 'Automated Testing',
        result: 'The system should have automated testing with 100% coverage.',
      },
      {
        order: 2,
        suggestion: 'User Authentication',
        result: 'Users should be able to authenticate themselves.',
      },
      {
        order: 3,
        suggestion: 'Other',
        result: 'The system should also include ______ .',
      },
    ],
  };
}

export function addProjectDescriptionEntropyCompletionFields(
  authId: number,
  document: PushDocumentType<ProjectDescriptionEntropyCompletionCollection>,
  completion: string,
): ProjectDescriptionEntropyCompletion {
  return {
    ...document,
    completion,
  };
}

export function addSupportAnswerFields(
  authId: number,
  document: PushDocumentType<SupportAnswersCollection>,
): SupportAnswer {
  return {
    ...document,
    id: generateId(),
    createTime: Date.now(),
  };
}

export function addFinancialReportingRecordComputedFields(
  authId: number,
  document: PushDocumentType<FinancialReportingRecordCollection> &
    Partial<Pick<FinancialReportingRecordCollection['DocumentType'], 'id'>>,
): FinancialReportingRecord {
  return transformFinancialReportingRecord({
    ...document,
    userId: authId,
    hasVerifiedWithdrawMethod: false,
    isPhoneVerified: false,
    isKycVerified: false,
  });
}
