import { generateId } from '@freelancer/datastore/testing/helpers';
import type { SupportAnswer } from './support-answers.model';

export interface GenerateSupportAnswerObject {
  readonly sourceId?: number;
  readonly supportAgentSessionId?: number;
  readonly supportSessionId?: number;
  readonly supportTaskId?: number;
  readonly answer?: string;
}

export function generateSupportAnswerObject({
  sourceId = generateId(),
  supportAgentSessionId = generateId(),
  supportSessionId = generateId(),
  supportTaskId = generateId(),
  answer = 'username1',
}: GenerateSupportAnswerObject): SupportAnswer {
  return {
    id: generateId(),
    createTime: Date.now(),
    sourceId,
    supportAgentSessionId,
    supportSessionId,
    supportTaskId,
    answer,
  };
}
